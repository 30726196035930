import React, { useState, useEffect, useCallback } from 'react';
import styles from '../create_listing_flow.module.css';
import AcquiredOffer from '../../../../apis/offer_api/acquired_offer';
import CryptoCurrencyInfo from '../../../../apis/offer_api/crypto_currency_info';
import Decimal from 'decimal.js';
import ContainerHelper from '../../../../component_system/component_container/utilities/container_helper';
import useFetchComponent from '../../../../component_system/component_container/utilities/use_fetch_hook';
import formatNumber from '../../../../utils/currency_formatter';

// Import step components
import Step1SelectItem from './steps/step1_select_item';
import Step2CryptoAmount from './steps/step2_crypto_amount';
import Step3ListingType from './steps/step3_listing_type';
import Step4PriceDetails from './steps/step4_price_details';
import Step5Duration from './steps/step5_duration';
import Step6Review from './steps/step6_review';

interface CreateListingFlowProps {
    onClose: () => void;
    onCreateListing: (
        acquiredOfferId: string,
        coinPrice: number,
        expiryDate: Date,
        cryptoAmount?: number,
        isFeatured?: boolean
    ) => void;
    onCreateAuctionListing: (
        acquiredOfferId: string,
        startingBid: number,
        bidIncrement: number,
        auctionEndDate: Date,
        reservePrice?: number,
        cryptoAmount?: number,
        isFeatured?: boolean
    ) => void;
    acquiredOffers: AcquiredOffer[];
    cryptoCurrencies?: CryptoCurrencyInfo[];
}

const CreateListingFlow: React.FC<CreateListingFlowProps> = ({
    onClose,
    onCreateListing,
    onCreateAuctionListing,
    acquiredOffers,
    cryptoCurrencies = [],
}) => {
    // Flow state
    const [step, setStep] = useState<number>(1);
    const [prevStep, setPrevStep] = useState<number>(1);
    const [listingType, setListingType] = useState<'fixed' | 'auction'>('fixed');

    // Form data
    const [selectedOfferId, setSelectedOfferId] = useState<string>('');
    const [price, setPrice] = useState<number>(0);
    const [expiryDays, setExpiryDays] = useState<number>(7);
    const [expiryHours, setExpiryHours] = useState<number>(0);
    const [expiryMinutes, setExpiryMinutes] = useState<number>(0);
    const [cryptoAmount, setCryptoAmount] = useState<number>(0);
    const [isCryptoSelected, setIsCryptoSelected] = useState<boolean>(false);
    const [selectedCrypto, setSelectedCrypto] =
        useState<CryptoCurrencyInfo | null>(null);
    const [cryptoUsdValue, setCryptoUsdValue] = useState<Decimal | null>(null);
    const [isFeatured, setIsFeatured] = useState<boolean>(false);
    const [transactionFee, setTransactionFee] = useState<number | undefined>(undefined);
    
    const settingsComponent = useFetchComponent(
        ContainerHelper.getSettingsComponent
    );
    
    // Auction specific fields
    const [startingBid, setStartingBid] = useState<number>(0);
    const [bidIncrement, setBidIncrement] = useState<number>(0);
    const [reservePrice, setReservePrice] = useState<number | undefined>(undefined);
    const [hasReservePrice, setHasReservePrice] = useState<boolean>(false);

    // Validation errors
    const [errors, setErrors] = useState<{
        offer?: string;
        price?: string;
        expiry?: string;
        cryptoAmount?: string;
        startingBid?: string;
        bidIncrement?: string;
        reservePrice?: string;
    }>({});

    // Filter available offers
    const availableOffers = acquiredOffers.filter(
        (offer) => !offer.isUsed && !offer.isExpired() && !offer.marketListingId
    );

    // Handle selection change
    useEffect(() => {
        // Check if the selected item is a crypto currency
        const isCrypto = selectedOfferId.startsWith('crypto-');
        setIsCryptoSelected(isCrypto);

        if (isCrypto) {
            const cryptoId = selectedOfferId.replace('crypto-', '');
            const crypto = cryptoCurrencies.find(
                (c) => c.cryptoCurrency === cryptoId
            );
            setSelectedCrypto(crypto || null);
            setCryptoAmount(0); // Reset amount when changing selection
            setCryptoUsdValue(null); // Reset USD value
        } else {
            setSelectedCrypto(null);
            setCryptoUsdValue(null);
        }
    }, [selectedOfferId, cryptoCurrencies]);

    // Calculate USD value when crypto amount changes
    useEffect(() => {
        if (isCryptoSelected && selectedCrypto && cryptoAmount > 0) {
            // Calculate USD value based on quote price if available
            if (selectedCrypto.quotePriceUSD) {
                const usdValue = new Decimal(selectedCrypto.quotePriceUSD);
                setCryptoUsdValue(usdValue);
            } else {
                setCryptoUsdValue(null);
            }
            
            // Calculate transaction fee
            calculateTransactionFee(cryptoAmount);
        } else {
            setCryptoUsdValue(null);
            setTransactionFee(undefined);
        }
    }, [cryptoAmount, selectedCrypto, isCryptoSelected]);
    
    // Transaction fee calculation
    const DEFAULT_TRANSACTION_FEE_RATE = 0.03; // 3% default fee rate
    
    const calculateTransactionFee = useCallback(
        (amount: number) => {
            if (!isCryptoSelected || amount <= 0) {
                setTransactionFee(undefined);
                return;
            }
            
            const feeRate =
                settingsComponent?.getDoubleFromClientSettings(
                    'MarketCryptoListingTransactionFee',
                    DEFAULT_TRANSACTION_FEE_RATE
                ) || DEFAULT_TRANSACTION_FEE_RATE;
            
            const fee = amount * feeRate;
            setTransactionFee(fee);
        },
        [settingsComponent, isCryptoSelected]
    );

    // Get selected item details
    const getSelectedItemDetails = () => {
        if (isCryptoSelected && selectedCrypto) {
            return {
                title: selectedCrypto.cryptoCurrency || 'Cryptocurrency',
                description: `Available: ${selectedCrypto.cryptoCurrencyAmount} ${selectedCrypto.cryptoCurrency}`,
                imageUrl:
                    selectedCrypto.coverUrl ||
                    'https://via.placeholder.com/100',
            };
        } else if (!isCryptoSelected && selectedOfferId) {
            const offer = availableOffers.find(
                (o) => o.uniqueId === selectedOfferId
            );
            return {
                title: offer?.offerTitle || 'Selected Item',
                description: offer?.description || 'No description available',
                imageUrl: offer?.coverUrl || 'https://via.placeholder.com/100',
            };
        }
        return {
            title: 'No item selected',
            description: 'Please select an item to list',
            imageUrl: 'https://via.placeholder.com/100',
        };
    };

    // Validate current step
    const validateCurrentStep = (): boolean => {
        const newErrors: {
            offer?: string;
            price?: string;
            expiry?: string;
            cryptoAmount?: string;
            startingBid?: string;
            bidIncrement?: string;
            reservePrice?: string;
        } = {};

        if (step === 1) {
            if (!selectedOfferId) {
                newErrors.offer = 'Please select an item to list';
                setErrors(newErrors);
                return false;
            }
        } else if (step === 2) {
            if (isCryptoSelected) {
                if (!cryptoAmount || cryptoAmount <= 0) {
                    newErrors.cryptoAmount = 'Please enter a valid amount';
                } else if (selectedCrypto) {
                    // Calculate total amount needed (amount + fee)
                    const feeRate =
                        settingsComponent?.getDoubleFromClientSettings(
                            'MarketCryptoListingTransactionFee',
                            DEFAULT_TRANSACTION_FEE_RATE
                        ) || DEFAULT_TRANSACTION_FEE_RATE;
                    
                    const totalNeeded = cryptoAmount + (cryptoAmount * feeRate);
                    
                    if (totalNeeded > (selectedCrypto.cryptoCurrencyAmount || 0)) {
                        newErrors.cryptoAmount = `Insufficient funds. You need ${formatNumber(totalNeeded)} ${selectedCrypto.cryptoCurrency} (including ${formatNumber(cryptoAmount * feeRate)} fee) but only have ${selectedCrypto.cryptoCurrencyAmount}`;
                    }
                }
            }
        } else if (step === 3) {
            // Listing type selection - no validation needed
        } else if (step === 4) {
            if (listingType === 'fixed') {
                if (!price || price <= 0) {
                    newErrors.price = 'Please enter a valid price';
                }
            } else { // auction
                if (!startingBid || startingBid <= 0) {
                    newErrors.startingBid = 'Please enter a valid starting bid';
                }
                if (!bidIncrement || bidIncrement <= 0) {
                    newErrors.bidIncrement = 'Please enter a valid bid increment';
                }
                if (hasReservePrice && (!reservePrice || reservePrice <= startingBid)) {
                    newErrors.reservePrice = 'Reserve price must be greater than starting bid';
                }
            }
        } else if (step === 5) {
            if ((expiryDays === 0 && expiryHours < 1) || expiryDays > 30) {
                newErrors.expiry = 'Expiry must be between 1 hour and 30 days';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle next step
    const handleNextStep = () => {
        if (validateCurrentStep()) {
            setPrevStep(step);
            setStep(step + 1);
        }
    };

    // Handle previous step
    const handlePrevStep = () => {
        setPrevStep(step);
        setStep(step - 1);
    };

    // Handle final submission
    const handleSubmit = () => {
        if (!validateCurrentStep()) return;

        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + expiryDays);
        expiryDate.setHours(expiryDate.getHours() + expiryHours);
        expiryDate.setMinutes(expiryDate.getMinutes() + expiryMinutes);

        if (listingType === 'fixed') {
            if (isCryptoSelected) {
                // For crypto listings, we pass the crypto amount and featured flag
                onCreateListing(selectedOfferId, price, expiryDate, cryptoAmount, isFeatured);
            } else {
                // For regular item listings
                onCreateListing(selectedOfferId, price, expiryDate, undefined, isFeatured);
            }
        } else { // auction
            if (isCryptoSelected) {
                // For crypto auction listings
                onCreateAuctionListing(
                    selectedOfferId,
                    startingBid,
                    bidIncrement,
                    expiryDate,
                    hasReservePrice ? reservePrice : undefined,
                    cryptoAmount,
                    isFeatured
                );
            } else {
                // For regular item auction listings
                onCreateAuctionListing(
                    selectedOfferId,
                    startingBid,
                    bidIncrement,
                    expiryDate,
                    hasReservePrice ? reservePrice : undefined,
                    undefined,
                    isFeatured
                );
            }
        }
    };

    // Render step content
    const renderStepContent = () => {
        switch (step) {
            case 1: // Select Item
                return (
                    <Step1SelectItem
                        selectedOfferId={selectedOfferId}
                        setSelectedOfferId={setSelectedOfferId}
                        availableOffers={availableOffers}
                        cryptoCurrencies={cryptoCurrencies}
                        errors={errors}
                        getSelectedItemDetails={getSelectedItemDetails}
                    />
                );

            case 2: // Crypto Amount (only for crypto)
                if (!isCryptoSelected) {
                    // For non-crypto items, we need to check if we're going forward or backward
                    if (step > prevStep) {
                        // Going forward, skip to step 3
                        setTimeout(() => setStep(3), 0);
                        return (
                            <div className={styles.stepContent}>Loading...</div>
                        );
                    } else {
                        // Going backward, go back to step 1
                        setTimeout(() => setStep(1), 0);
                        return (
                            <div className={styles.stepContent}>Loading...</div>
                        );
                    }
                }

                // For crypto items, show the amount selection screen
                return (
                    <Step2CryptoAmount
                        cryptoAmount={cryptoAmount}
                        setCryptoAmount={setCryptoAmount}
                        selectedCrypto={selectedCrypto}
                        cryptoUsdValue={cryptoUsdValue}
                        transactionFee={transactionFee}
                        errors={errors}
                        getSelectedItemDetails={getSelectedItemDetails}
                    />
                );

            case 3: // Choose Listing Type
                return (
                    <Step3ListingType
                        listingType={listingType}
                        setListingType={setListingType}
                        isCryptoSelected={isCryptoSelected}
                        cryptoAmount={cryptoAmount}
                        selectedCrypto={selectedCrypto}
                        cryptoUsdValue={cryptoUsdValue}
                        getSelectedItemDetails={getSelectedItemDetails}
                    />
                );

            case 4: // Set Price or Auction Details
                return (
                    <Step4PriceDetails
                        listingType={listingType}
                        price={price}
                        setPrice={setPrice}
                        startingBid={startingBid}
                        setStartingBid={setStartingBid}
                        bidIncrement={bidIncrement}
                        setBidIncrement={setBidIncrement}
                        reservePrice={reservePrice}
                        setReservePrice={setReservePrice}
                        hasReservePrice={hasReservePrice}
                        setHasReservePrice={setHasReservePrice}
                        isCryptoSelected={isCryptoSelected}
                        cryptoAmount={cryptoAmount}
                        selectedCrypto={selectedCrypto}
                        cryptoUsdValue={cryptoUsdValue}
                        errors={errors}
                        getSelectedItemDetails={getSelectedItemDetails}
                        isFeatured={isFeatured}
                        setIsFeatured={setIsFeatured}
                    />
                );

            case 5: // Set Duration
                return (
                    <Step5Duration
                        listingType={listingType}
                        expiryDays={expiryDays}
                        setExpiryDays={setExpiryDays}
                        expiryHours={expiryHours}
                        setExpiryHours={setExpiryHours}
                        expiryMinutes={expiryMinutes}
                        setExpiryMinutes={setExpiryMinutes}
                        isCryptoSelected={isCryptoSelected}
                        cryptoAmount={cryptoAmount}
                        selectedCrypto={selectedCrypto}
                        cryptoUsdValue={cryptoUsdValue}
                        price={price}
                        startingBid={startingBid}
                        errors={errors}
                        getSelectedItemDetails={getSelectedItemDetails}
                    />
                );

            case 6: // Review & Confirm
                return (
                    <Step6Review
                        listingType={listingType}
                        isCryptoSelected={isCryptoSelected}
                        cryptoAmount={cryptoAmount}
                        selectedCrypto={selectedCrypto}
                        cryptoUsdValue={cryptoUsdValue}
                        price={price}
                        startingBid={startingBid}
                        bidIncrement={bidIncrement}
                        hasReservePrice={hasReservePrice}
                        reservePrice={reservePrice}
                        expiryDays={expiryDays}
                        expiryHours={expiryHours}
                        expiryMinutes={expiryMinutes}
                        isFeatured={isFeatured}
                        transactionFee={transactionFee}
                        getSelectedItemDetails={getSelectedItemDetails}
                    />
                );

            default:
                return null;
        }
    };

    // Render navigation buttons
    const renderNavButtons = () => {
        return (
            <div className={styles.flowActions}>
                {step > 1 && (
                    <button
                        className={styles.backButton}
                        onClick={handlePrevStep}
                    >
                        Back
                    </button>
                )}

                <button className={styles.cancelButton} onClick={onClose}>
                    Cancel
                </button>

                {step < 6 ? (
                    <button
                        className={styles.nextButton}
                        onClick={handleNextStep}
                    >
                        Next
                    </button>
                ) : (
                    <button
                        className={styles.createButton}
                        onClick={handleSubmit}
                    >
                        Create Listing
                    </button>
                )}
            </div>
        );
    };

    // Progress indicator
    const renderProgressIndicator = () => {
        const totalSteps = isCryptoSelected ? 6 : 5;
        const adjustedStep = isCryptoSelected
            ? step
            : step === 2
              ? 2
              : step - 1;

        return (
            <div className={styles.progressContainer}>
                {Array.from({ length: totalSteps }).map((_, index) => (
                    <div
                        key={index}
                        className={`${styles.progressStep} ${
                            index < adjustedStep || (index === 0 && step > 1)
                                ? styles.completed
                                : ''
                        } ${
                            (index === adjustedStep - 1 && index !== 0) ||
                            (index === 0 && step === 1)
                                ? styles.active
                                : ''
                        }`}
                    >
                        {index + 1}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.flowContainer}>
            <div className={styles.flowHeader}>
                <h2>Create Market Listing</h2>
                <button className={styles.closeButton} onClick={onClose}>
                    ×
                </button>
            </div>

            <div className={styles.flowContent}>{renderStepContent()}</div>

            {renderProgressIndicator()}

            {renderNavButtons()}
        </div>
    );
};

export default CreateListingFlow;
