export const constants = {
    API_URL: process.env.REACT_APP_API_URL,
    AVATAR_URL_STRUCTURE: `${process.env.REACT_APP_API_URL}files/avatar/%USERNAME%`,
    ITEM_IMAGE_URL_STRUCTURE: `${process.env.REACT_APP_API_URL}files/item-image/%ITEM_ID%`,
    ADD_FRIEND_URL_STRUCTURE: `${process.env.REACT_APP_BOT_URL}?start=f%TID%`,
    REFER_URL_STRUCTURE: `${process.env.REACT_APP_BOT_URL}?start=r%TID%`,
    INVITE_URL: process.env.REACT_APP_BOT_URL,
    CLAIM_URL: process.env.REACT_APP_CLAIM_URL,
    CONNECT_WALLET_URL_STRUCTURE: `${process.env.REACT_APP_CONNECT_WALLET_URL}%URL_CODE%`,
};

export const search_params_keys = {
    SEARCH_PARAMS_KEY_REFERER: 'referer',
    SEARCH_PARAMS_KEY_FRIEND_REFERER: 'friend',
};

export const secure_preferences_keys = {
    SECURE_PREFERENCES_KEY_COINS: 'coins',
    SECURE_PREFERENCES_KEY_GEMS: 'gems',
    SECURE_PREFERENCES_KEY_USERNAME: 'username',
    SECURE_PREFERENCES_KEY_TELEGRAM_ID: 'telegramId',
    SECURE_PREFERENCES_KEY_TOKEN: 'token',
    SECURE_PREFERENCES_KEY_E_RADIUS: 'expectedRadius',
    SECURE_PREFERENCES_KEY_TOTAL_STEPS: 'totalSteps',
    SECURE_PREFERENCES_KEY_TOTAL_PURCHASED_OFFERS: 'totalPurchasedOffers',
    SECURE_PREFERENCES_KEY_PHONE_NUMBER: 'phoneNumber',
    SECURE_PREFERENCES_KEY_EXPERIENCE: 'experience',
    SECURE_PREFERENCES_KEY_CURRENT_LEVEL: 'currentLevel',
    SECURE_PREFERENCES_KEY_EXPERIENCE_UNTIL_NEXT_LEVEL:
        'experienceUntilNextLevel',
    SECURE_PREFERENCES_KEY_CURRENT_LEVEL_EXPERIENCE: 'currentLevelExperience',
    SECURE_PREFERENCES_KEY_USER_TYPE: 'userType',
    SECURE_PREFERENCES_KEY_EVM_WALLET: 'evmWalletAddress',
    SECURE_PREFERENCES_KEY_TON_WALLET: 'tonWalletAddress',
    SECURE_PREFERENCES_KEY_SOLANA_WALLET: 'solanaWalletAddress',
};

export const local_storage_keys = {
    LOCAL_STORAGE_KEY_CACHED_LOCALE_NAME: 'cachedLocaleName',
    LOCAL_STORAGE_KEY_CACHED_LOCALE: 'cachedLocale',
    LOCAL_STORAGE_KEY_CACHED_LOCALE_UPDATE_TIME: 'cachedLocaleUpdateTime',
};

export const component_error_codes = {
    TELEGRAM_REGISTRATION_INCOMPLETE: 'TELEGRAM_REGISTRATION_INCOMPLETE',
    CONTAINER_ALREADY_INITIALIZED: 'CONTAINER_ALREADY_INITIALIZED',
    LOCATION_PERMISSION_DENIED: 'LOCATION_PERMISSION_DENIED',
};

export const rarity_text_shadow = {
    Common: '0px 3px 11px #00800080', // Based on the green gradient
    Rare: '0px 3px 11px #0028ff80', // Based on the blue gradient
    Epic: '0px 3px 11px #80008080', // Based on the purple gradient
    Legendary: '0px 3px 11px #b8860b80', // Based on the gold gradient
};

export const rarity_gradient = {
    Common: 'linear-gradient(0deg, #008000, #00ff00)',
    Rare: 'linear-gradient(0deg, #0028ff, #00abff)',
    Epic: 'linear-gradient(0deg, #800080, #ff00ff)',
    Legendary: 'linear-gradient(0deg, #b8860b, #ffd700)',
};

export const rarity_shadow = {
    Common: `
        0px 4px 6px rgba(0, 128, 0, 0.5),
        0px 8px 10px rgba(0, 255, 0, 0.3),
        0px 12px 20px rgba(0, 255, 0, 0.2)
    `,
    Rare: `
        0px 4px 6px rgba(0, 40, 255, 0.5),
        0px 8px 10px rgba(0, 171, 255, 0.3),
        0px 12px 20px rgba(0, 171, 255, 0.2)
    `,
    Epic: `
        0px 4px 6px rgba(128, 0, 128, 0.5),
        0px 8px 10px rgba(255, 0, 255, 0.3),
        0px 12px 20px rgba(255, 0, 255, 0.2)
    `,
    Legendary: `
        0px 4px 6px rgba(184, 134, 11, 0.5),
        0px 8px 10px rgba(255, 215, 0, 0.3),
        0px 12px 20px rgba(255, 215, 0, 0.2)
    `,
};

export const subdomains = [
    'https://app.diggersgo.com',
    'https://app1.diggersgo.com',
    'https://app2.diggersgo.com',
];
