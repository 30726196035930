import CryptoSummary from './cryptoSummary';

class ReferralSummary {
    validatedCount: number;
    notValidatedCount: number;
    cryptoSummaries: CryptoSummary[];

    constructor({
        validatedCount,
        notValidatedCount,
        cryptoSummaries,
    }: {
        validatedCount: number;
        notValidatedCount: number;
        cryptoSummaries: CryptoSummary[];
    }) {
        this.validatedCount = validatedCount;
        this.notValidatedCount = notValidatedCount;
        this.cryptoSummaries = cryptoSummaries;
    }

    static fromJsonLowerCamelCase(json: any): ReferralSummary {
        return new ReferralSummary({
            validatedCount: json.validatedCount,
            notValidatedCount: json.notValidatedCount,
            cryptoSummaries: json.cryptoSummaries.map((cs: any) =>
                CryptoSummary.fromJsonLowerCamelCase(cs)
            ),
        });
    }

    static fromJsonUpperCase(json: any): ReferralSummary {
        return new ReferralSummary({
            validatedCount: json.ValidatedCount,
            notValidatedCount: json.NotValidatedCount,
            cryptoSummaries: json.CryptoSummaries.map((cs: any) =>
                CryptoSummary.fromJsonUpperCase(cs)
            ),
        });
    }
}

export default ReferralSummary;
