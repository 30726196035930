class CryptoSummary {
    cryptoCurrency: string;
    coverUrl: string;
    totalTokensEarned: number;

    constructor({
        cryptoCurrency,
        coverUrl,
        totalTokensEarned,
    }: {
        cryptoCurrency: string;
        coverUrl: string;
        totalTokensEarned: number;
    }) {
        this.cryptoCurrency = cryptoCurrency;
        this.coverUrl = coverUrl;
        this.totalTokensEarned = totalTokensEarned;
    }

    static fromJsonLowerCamelCase(json: any): CryptoSummary {
        return new CryptoSummary({
            cryptoCurrency: json.cryptoCurrency,
            coverUrl: json.coverUrl,
            totalTokensEarned: json.totalTokensEarned,
        });
    }

    static fromJsonUpperCase(json: any): CryptoSummary {
        return new CryptoSummary({
            cryptoCurrency: json.CryptoCurrency,
            coverUrl: json.CoverUrl,
            totalTokensEarned: json.TotalTokensEarned,
        });
    }
}


export default CryptoSummary;