import React, { useState } from 'react';
import styles from './market_search_filters.module.css';
import {
    LocalMarketListingSearchOptions,
    MarketListingSearchModel,
    MarketListingTypeFilter,
    MarketProfileType,
} from '../../../generated/data-contracts';
import MarketListingSort from './enums/market_listing_sort';
import MarketListingSearchIn from './enums/market_listing_search_in';

interface MarketSearchFiltersProps {
    searchModel: MarketListingSearchModel;
    onSearchChange: (searchModel: MarketListingSearchModel) => void;
    showLocalOptions?: boolean;
}

const MarketSearchFilters: React.FC<MarketSearchFiltersProps> = ({
    searchModel,
    onSearchChange,
    showLocalOptions = false,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [localSearchModel, setLocalSearchModel] =
        useState<MarketListingSearchModel>(searchModel);

    const handleSearchInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const updatedModel = { ...localSearchModel, search: e.target.value };
        setLocalSearchModel(updatedModel);
    };

    const handleSelectChange = (
        field: keyof MarketListingSearchModel,
        value: any
    ) => {
        const updatedModel = { ...localSearchModel, [field]: value };
        setLocalSearchModel(updatedModel);
    };

    const handleApplyFilters = () => {
        onSearchChange(localSearchModel);
    };

    const handleResetFilters = () => {
        const resetModel: MarketListingSearchModel = {
            ...searchModel,
            search: '',
            searchIn: MarketListingSearchIn.Value0,
            profileType: MarketProfileType.Value0,
            type: MarketListingTypeFilter.Value0,
            sort: MarketListingSort.Value0,
        };

        // Preserve local options if they exist in the original model
        if (showLocalOptions && searchModel.localMarketListingSearchOptions) {
            resetModel.localMarketListingSearchOptions = {
                includeExpired: true,
                includePurchased: true,
                includeRemoved: false,
            };
        }

        setLocalSearchModel(resetModel);
        onSearchChange(resetModel);
    };

    return (
        <div className={styles.filtersContainer}>
            <div className={styles.searchBar}>
                <input
                    type="text"
                    placeholder="Search listings..."
                    value={localSearchModel.search || ''}
                    onChange={handleSearchInputChange}
                    className={styles.searchInput}
                />
                <button
                    className={styles.searchButton}
                    onClick={handleApplyFilters}
                >
                    Search
                </button>
            </div>

            <button
                className={styles.expandButton}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {isExpanded ? 'Hide Filters' : 'Show Filters'}
            </button>

            {isExpanded && (
                <div className={styles.expandedFilters}>
                    <div className={styles.filterRow}>
                        <div className={styles.filterGroup}>
                            <label className={styles.filterLabel}>
                                Search In
                            </label>
                            <select
                                className={styles.filterSelect}
                                value={localSearchModel.searchIn}
                                onChange={(e) =>
                                    handleSelectChange(
                                        'searchIn',
                                        parseInt(e.target.value)
                                    )
                                }
                            >
                                <option value={MarketListingSearchIn.Value0}>
                                    Title
                                </option>
                                <option value={MarketListingSearchIn.Value1}>
                                    Description
                                </option>
                                <option value={MarketListingSearchIn.Value2}>
                                    Both
                                </option>
                            </select>
                        </div>

                        <div className={styles.filterGroup}>
                            <label className={styles.filterLabel}>
                                Profile Type
                            </label>
                            <select
                                className={styles.filterSelect}
                                value={localSearchModel.profileType}
                                onChange={(e) =>
                                    handleSelectChange(
                                        'profileType',
                                        parseInt(e.target.value)
                                    )
                                }
                            >
                                <option value={MarketProfileType.Value0}>
                                    All
                                </option>
                                <option value={MarketProfileType.Value1}>
                                    Local
                                </option>
                                <option value={MarketProfileType.Value2}>
                                    National
                                </option>
                                <option value={MarketProfileType.Value3}>
                                    Top
                                </option>
                            </select>
                        </div>
                    </div>

                    <div className={styles.filterRow}>
                        <div className={styles.filterGroup}>
                            <label className={styles.filterLabel}>Type</label>
                            <select
                                className={styles.filterSelect}
                                value={localSearchModel.type}
                                onChange={(e) =>
                                    handleSelectChange(
                                        'type',
                                        parseInt(e.target.value)
                                    )
                                }
                            >
                                <option value={MarketListingTypeFilter.Value0}>
                                    All
                                </option>
                                <option value={MarketListingTypeFilter.Value1}>
                                    Crypto
                                </option>
                                <option value={MarketListingTypeFilter.Value2}>
                                    Lootbox
                                </option>
                            </select>
                        </div>

                        <div className={styles.filterGroup}>
                            <label className={styles.filterLabel}>
                                Sort By
                            </label>
                            <select
                                className={styles.filterSelect}
                                value={localSearchModel.sort}
                                onChange={(e) =>
                                    handleSelectChange(
                                        'sort',
                                        parseInt(e.target.value)
                                    )
                                }
                            >
                                <option value={MarketListingSort.Value0}>
                                    Newest
                                </option>
                                <option value={MarketListingSort.Value1}>
                                    Oldest
                                </option>
                                <option value={MarketListingSort.Value2}>
                                    Price (Low to High)
                                </option>
                                <option value={MarketListingSort.Value3}>
                                    Price (High to Low)
                                </option>
                                <option value={MarketListingSort.Value4}>
                                    Expiring Soon
                                </option>
                                <option value={MarketListingSort.Value5}>
                                    Recently Added
                                </option>
                            </select>
                        </div>
                    </div>

                    {showLocalOptions && (
                        <div className={styles.filterRow}>
                            <div className={styles.filterGroup}>
                                <label className={styles.filterLabel}>
                                    Include Options
                                </label>
                                <div className={styles.checkboxGroup}>
                                    <label className={styles.checkboxLabel}>
                                        <input
                                            type="checkbox"
                                            checked={
                                                localSearchModel
                                                    .localMarketListingSearchOptions
                                                    ?.includeExpired || false
                                            }
                                            onChange={(e) => {
                                                const options: LocalMarketListingSearchOptions =
                                                    {
                                                        ...(localSearchModel.localMarketListingSearchOptions ||
                                                            {}),
                                                        includeExpired:
                                                            e.target.checked,
                                                    };
                                                handleSelectChange(
                                                    'localMarketListingSearchOptions',
                                                    options
                                                );
                                            }}
                                        />
                                        Include Expired
                                    </label>
                                    <label className={styles.checkboxLabel}>
                                        <input
                                            type="checkbox"
                                            checked={
                                                localSearchModel
                                                    .localMarketListingSearchOptions
                                                    ?.includePurchased || false
                                            }
                                            onChange={(e) => {
                                                const options: LocalMarketListingSearchOptions =
                                                    {
                                                        ...(localSearchModel.localMarketListingSearchOptions ||
                                                            {}),
                                                        includePurchased:
                                                            e.target.checked,
                                                    };
                                                handleSelectChange(
                                                    'localMarketListingSearchOptions',
                                                    options
                                                );
                                            }}
                                        />
                                        Include Purchased
                                    </label>
                                    <label className={styles.checkboxLabel}>
                                        <input
                                            type="checkbox"
                                            checked={
                                                localSearchModel
                                                    .localMarketListingSearchOptions
                                                    ?.includeRemoved || false
                                            }
                                            onChange={(e) => {
                                                const options: LocalMarketListingSearchOptions =
                                                    {
                                                        ...(localSearchModel.localMarketListingSearchOptions ||
                                                            {}),
                                                        includeRemoved:
                                                            e.target.checked,
                                                    };
                                                handleSelectChange(
                                                    'localMarketListingSearchOptions',
                                                    options
                                                );
                                            }}
                                        />
                                        Include Removed
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className={styles.filterActions}>
                        <button
                            className={styles.resetButton}
                            onClick={handleResetFilters}
                        >
                            Reset Filters
                        </button>
                        <button
                            className={styles.applyButton}
                            onClick={handleApplyFilters}
                        >
                            Apply Filters
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MarketSearchFilters;
