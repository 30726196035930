import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration } from 'typed-duration';
import Images from './images';
import ComponentErrorType from '../../component_container/enums/component_error_type';

class PreloadComponent extends Component {
    private _cachedImages = new Set<string>();
    private _cachedSounds: Map<string, HTMLAudioElement> = new Map();

    public getCachedSound(sound: string): HTMLAudioElement {
        if (!this._cachedSounds.has(sound)) {
            const audio = new Audio(sound);
            this._cachedSounds.set(sound, audio);
        }
        return this._cachedSounds.get(sound)!;
    }

    private async _loadImageWithTimeout(
        src: string,
        timeout: number = 5000
    ): Promise<void> {
        if (this._cachedImages.has(src)) {
            return; // Image already loaded
        }

        return new Promise((resolve, reject) => {
            const image = new Image();
            const timer = setTimeout(() => {
                image.src = ''; // Cancel loading
                reject(
                    new ComponentError(
                        ComponentErrorType.LoadError,
                        `Timeout loading image: ${src}`
                    )
                );
            }, timeout);

            image.onload = () => {
                clearTimeout(timer);
                this._cachedImages.add(src);
                resolve();
            };
            image.onerror = (error) => {
                clearTimeout(timer);
                reject(
                    new ComponentError(
                        ComponentErrorType.LoadError,
                        `Error loading image: ${src}`
                    )
                );
            };
            image.src = src;
        });
    }

    async load(): Promise<Array<ComponentError>> {
        const errors: ComponentError[] = [];

        // Helper function to recursively load images from nested object
        const loadImages = async (imageObject: Record<string, any>) => {
            for (const key in imageObject) {
                if (typeof imageObject[key] === 'string') {
                    // If it's a string, assume it's an image URL
                    await this._loadImageWithTimeout(imageObject[key]).catch(
                        (error) => errors.push(error)
                    );
                } else if (typeof imageObject[key] === 'object') {
                    // If it's an object, recursively load images
                    await loadImages(imageObject[key]);
                }
            }
        };

        // Start loading images from the nested structure
        await loadImages(Images);

        return errors;
    }

    get name(): string {
        return 'Preload Component';
    }

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    async onUnload(): Promise<void> {}

    get type(): Function {
        return PreloadComponent;
    }

    update(sinceLastUpdate: TimeDuration): void {}
}

export default PreloadComponent;
