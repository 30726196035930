import React, { memo } from 'react';
import styles from '../../pages/profile_page.module.css';
import Images from '../../../component_system/components/preload/images';
import LevelProgressBar from '../level_progress_bar';
import SeasonPassScroll from '../season_pass/season_pass_scroll';

interface ProfileHeaderProps {
    username?: string;
    characterImageBase64?: string;
    currentLevel?: number;
    currentLevelExperience?: number;
    experienceGained: number;
    onCustomize: () => void;
}

const ProfileHeader = memo(({
    username,
    characterImageBase64,
    currentLevel,
    currentLevelExperience,
    experienceGained,
    onCustomize
}: ProfileHeaderProps) => {
    return (
        <div className={styles.profilePanel}>
            <div
                className={styles.profileImage}
                style={{
                    backgroundImage: `url(data:image/png;base64,${characterImageBase64})`,
                }}
                onClick={onCustomize}
            >
                <img
                    src={Images.Profile.ProfileOverlay}
                    className={styles.overlay}
                    alt="User avatar"
                />
                <div className={styles.backdrop}></div>

                <img
                    aria-label="Customize Button"
                    style={{
                        position: 'absolute',
                        top: '-1.0rem',
                        right: '-1.0rem',
                        width: '2.5rem',
                        height: '2.5rem',
                    }}
                    src={Images.Icons.CustomizeCharacter}
                    alt="Custom Icon"
                    className={styles.customIcon}
                />
            </div>
            <h3 className={styles.profileName}>
                {username ?? 'Username'}
            </h3>

            <LevelProgressBar
                width={100}
                height={25}
                borderRadius={6}
            />
            <div className={styles.levelInfo}>
                <p className={styles.left}>
                    {'level'.tr() + ` ${currentLevel ?? 1}`}
                </p>
                <p className={styles.right}>
                    {`${experienceGained.toFixed(0)} / ${(currentLevelExperience ?? 1).toFixed(0)} XP`}
                </p>
            </div>
            <SeasonPassScroll />
        </div>
    );
});

ProfileHeader.displayName = 'ProfileHeader';

export default ProfileHeader;
