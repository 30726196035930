import React, { memo, forwardRef } from 'react';
import styles from '../../pages/vault_page.module.css';
import HapticButton from '../button/haptic_button';
import formatNumber from '../../../utils/currency_formatter';
import { formatTokenValue } from '../../../utils/currency_formatter';
import CryptoCurrencyInfo from '../../../apis/offer_api/crypto_currency_info';

type CryptoAssetItemProps = {
    asset: CryptoCurrencyInfo;
    showDollarWorth: boolean;
    onClaim: (asset: CryptoCurrencyInfo) => void;
    canClaim: boolean;
    isNew?: boolean;
};

const CryptoAssetItem = forwardRef<HTMLDivElement, CryptoAssetItemProps>(
    ({ asset, showDollarWorth, onClaim, canClaim, isNew = false }, ref) => {
        return (
            <div
                ref={ref}
                className={`${styles.questPanel} ${styles.vault}`}
                data-is-new={isNew ? 'true' : 'false'}
            >
                <img
                    src={asset.coverUrl}
                    className={styles.icon}
                    alt={asset.cryptoCurrency}
                />
                <h5 className={styles.tokenTitle}>{asset.cryptoCurrency}</h5>
                <div className={styles.actions}>
                    <div className={styles.valueDisplay}>
                        <p>
                            {!showDollarWorth
                                ? `${formatNumber(asset.cryptoCurrencyAmount)}`
                                : `${formatTokenValue(asset.quotePriceUSD, asset.cryptoCurrencyAmount)}`}
                        </p>
                    </div>
                    <HapticButton
                        hapticFeedbackType="impact"
                        hapticFeedbackStyle="light"
                        type="button"
                        disabled={(asset.cryptoCurrencyAmount ?? 0) <= 0}
                        className={`${styles.claim} ${canClaim ? '' : styles.noCoins}`}
                        onClick={() => onClaim(asset)}
                    >
                        Claim
                    </HapticButton>
                </div>
                {isNew && <span className={styles.questPanelBadge}>!</span>}
            </div>
        );
    }
);

export default memo(CryptoAssetItem);
