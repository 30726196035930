import React, { useState } from 'react';
import { Item, ItemRarity, ItemType } from '../../apis/customization_api/item';
import { constants } from '../../utils/constants';
import { rarity_gradient } from '../../utils/constants';
import styles from './item_selection_popup.module.css';

const ITEM_IMAGE_URL_STRUCTURE = constants.ITEM_IMAGE_URL_STRUCTURE;

interface ExpandableDescriptionProps {
    description: string;
}

const ExpandableDescription: React.FC<ExpandableDescriptionProps> = ({ description }) => {
    const [expanded, setExpanded] = useState(false);
    const isLong = description.length > 60;
    
    return (
        <div className={styles.descriptionContainer}>
            <p className={`${styles.itemDescription} ${expanded ? styles.expanded : ''}`}>
                {expanded || !isLong ? description : `${description.substring(0, 60)}...`}
            </p>
            {isLong && (
                <button 
                    className={styles.expandButton}
                    onClick={(e) => {
                        e.stopPropagation();
                        setExpanded(!expanded);
                    }}
                >
                    {expanded ? 'Show less' : 'Show more'}
                </button>
            )}
        </div>
    );
};

interface ItemSelectionPopupProps {
    items: Item[];
    selectedItemId: string | undefined;
    onItemSelect: (item: Item) => void;
    onClose: () => void;
    bodyPartType: ItemType;
    loadingItem: string | null | undefined;
}

const ItemSelectionPopup: React.FC<ItemSelectionPopupProps> = ({
    items,
    selectedItemId,
    onItemSelect,
    onClose,
    bodyPartType,
    loadingItem
}) => {
    return (
        <div className={styles.popupOverlay} onClick={onClose}>
            <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.popupHeader}>
                    <h3 className={styles.popupTitle}>{ItemType[bodyPartType]} Items</h3>
                    <button className={styles.closeButton} onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <div className={styles.itemsGrid}>
                    {items.map((item) => {
                        const isSelected = item.id === selectedItemId || 
                            (item.id === 'none' && !selectedItemId);
                        
                        return (
                            <div 
                                key={item.id} 
                                className={`${styles.itemCard} ${isSelected ? styles.selectedCard : ''}`}
                                style={{
                                    // @ts-ignore
                                    background: item.id === 'none' ? '#808080' : rarity_gradient[ItemRarity[item.rarity]],
                                    cursor: isSelected ? 'default' : 'pointer'
                                }}
                                onClick={() => !isSelected && onItemSelect(item)}
                            >
                                {isSelected && (
                                    <div className={styles.selectedBadge}>Selected</div>
                                )}
                                {loadingItem === item.id && (
                                    <div className={styles.loadingOverlay}>
                                        <div className={styles.spinner}></div>
                                    </div>
                                )}
                                <div className={styles.itemImage}>
                                    {item.id !== 'none' && item.id ? (
                                        <img 
                                            src={ITEM_IMAGE_URL_STRUCTURE.replace(
                                                '%ITEM_ID%',
                                                item.id
                                            )}
                                            alt={item.name} 
                                        />
                                    ) : (
                                        <div className={styles.noneIcon}>
                                            <i className="fas fa-ban"></i>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.itemInfo}>
                                    <h4 className={styles.itemName}>{item.name}</h4>
                                    <ExpandableDescription description={item.description} />
                                    {item.id !== 'none' && (
                                        <p className={styles.itemRarity}>Rarity: {ItemRarity[item.rarity]}</p>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ItemSelectionPopup;
