import ReactCountdown from "react-countdown";
import styles from './countdown.module.css';

type CountdownProps = {
    date: Date|number;
};

export const Countdown = ({ date }: CountdownProps) => {
    return (
        <ReactCountdown
            date={date}
            renderer={({ days, hours, minutes, seconds }) => (
                <>
                    {CountdownItem({ value: days, label: "days" })}
                    {CountdownItem({ value: hours, label: "hrs" })}
                    {CountdownItem({ value: minutes, label: "min" })}
                    {CountdownItem({ value: seconds, label: "sec" })}
                </>
            )}
        />
    );
};

const CountdownItem = ({ value, label }: { value: number; label: string }) => (
    <span className={styles.countdown}>
           {value}
            <br />
            <small>{label}</small>
    </span>
);