import TopReferrer from "./top_referrer";

class ToplistHistory {
    startDate: Date;
    endDate: Date;
    topPlayers: TopReferrer[];


    constructor({
        startDate,
        endDate,
        topPlayers,
    }: {
        startDate: Date;
        endDate: Date;
        topPlayers: TopReferrer[];        
    }) {
        this.startDate = new Date(startDate);
        this.endDate = new Date(endDate);
        this.topPlayers = topPlayers;

    }

    static fromJsonLowerCamelCase(json: any): ToplistHistory {
        return new ToplistHistory({
            startDate: new Date(json.startDate),
            endDate: new Date(json.endDate),
            topPlayers: json.topPlayers.map((e: any) =>
                TopReferrer.fromJsonLowerCamelCase(e)
            ),
  
        });
    }

    static fromJsonUpperCase(json: any): ToplistHistory {
        return new ToplistHistory({
            startDate: new Date(json.StartDate),
            endDate: new Date(json.EndDate),
            topPlayers: json.TopPlayers.map((e: any) =>
                TopReferrer.fromJsonUpperCase(e)
            ),

        });
    }
}

export default ToplistHistory;