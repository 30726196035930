import StandardHttpAPI from '../standard_http_api';
import ValueContainer from '../../utils/value_container';
import { constants } from '../../utils/constants';
import Pet from '../models/friend/pet';
import StandardHttpResponse from '../models/standard_http_response';
import SeasonPassSettings from '../models/season_pass/season_pass_settings';
import LootBoxInformation from './loot_box_information';
import { Skin } from './skin';
import { Item } from './item';

const API_URL = constants.API_URL;

class CustomizationApi {
    static async getSelectedCharacter(): Promise<StandardHttpResponse> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetSelectedCharacter`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response;
    }

    static async selectCharacter(
        skinId: string
    ): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.post({
            uri: `${API_URL}customization/SelectCharacter/${skinId}`,
            bearer: ValueContainer.token,
            body: undefined,
        });
    }

    static async getPets(): Promise<Pet[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetPets`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.pets.map((json: any) =>
            Pet.fromJsonLowerCamelCase(json)
        );
    }

    static async deactivatePet(petId: string): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.post({
            uri: `${API_URL}customization/DeactivatePet/${petId}`,
            bearer: ValueContainer.token,
            body: undefined,
        });
    }

    static async activatePet(petId: string): Promise<Pet> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}customization/ActivatePet/${petId}`,
            bearer: ValueContainer.token,
            body: undefined,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return Pet.fromJsonLowerCamelCase(response.response.activatedPet);
    }

    static async namePet(
        petId: string,
        name: string
    ): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.post({
            uri: `${API_URL}customization/NamePet/${petId}`,
            bearer: ValueContainer.token,
            body: { Name: name },
        });
    }

    /**
     * Checks if the user avatar should be updated based on metadata comparison.
     * @returns A promise resolving to a boolean indicating if the avatar should be updated.
     */
    static async shouldUpdateUserAvatar(): Promise<boolean> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/ShouldUpdateUserAvatar`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.shouldUpdate;
    }

    /**
     * Uploads a new user avatar.
     * @param file The avatar file to upload.
     * @returns A promise resolving to a success message or rejecting on failure.
     */
    static async uploadUserAvatar(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        const response = await StandardHttpAPI.uploadFile({
            uri: `${API_URL}customization/UploadUserAvatar`,
            formData,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.message;
    }

    /**
     * Fetches SeasonPassSettings from the server.
     * @returns A promise resolving to the SeasonPassSettings or rejecting on failure.
     * @throws {StandardHttpResponse} If the request fails.
     */
    static async getSeasonPassSettings(): Promise<SeasonPassSettings> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetSeasonPassSettings`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return SeasonPassSettings.fromJsonLowerCamelCase(response.response);
    }

    /**
     * Fetches current unlocked characters from the server.
     * @returns A promise resolving to a list of character build strings or rejecting on failure.
     * @throws {StandardHttpResponse} If the request fails.
     */
    static async getUnlockedCharacters(): Promise<string[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetUnlockedCharacters`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.unlockedCharacters;
    }

    /**
     * Fetches current unlocked skins from the server.
     * @returns A promise resolving to a list of skin IDs or rejecting on failure.
     * @throws {StandardHttpResponse} If the request fails.
     */
    static async getUnlockedSkins(): Promise<Skin[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetUnlockedSkins`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.unlockedSkins.map((json: any) => 
            Skin.fromJsonLowerCamelCase(json)
        );
    }

    static async getUnlockedItems(): Promise<Item[]> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetUnlockedItems`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response.unlockedItems.map((json: any) => 
            Item.fromJsonLowerCamelCase(json)
        );
    }

    /**
     * Updates an item for a specific body part on a skin.
     * @param skinId The ID of the skin to modify.
     * @param itemType The type of body part to set the item for.
     * @param itemId The ID of the item to set, or empty string to remove the item.
     * @returns A promise resolving to the API response containing the updated skin.
     * @throws {StandardHttpResponse} If the request fails.
     */
    static async setItemForBodyPart(
        skinId: string,
        itemType: number,
        itemId: string | null | undefined
    ): Promise<StandardHttpResponse> {
        return await StandardHttpAPI.post({
            uri: `${API_URL}customization/UpdateSkinItem/${skinId}/${itemType}`,
            bearer: ValueContainer.token,
            body: {
                itemId: itemId || ""
            },
        });
    }

    static async getLootBoxInfo(
        lootBoxId: string
    ): Promise<LootBoxInformation> {
        const response = await StandardHttpAPI.get({
            uri: `${API_URL}customization/GetLootBoxInfo/${lootBoxId}`,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return LootBoxInformation.fromJsonLowerCamelCase(response.response);
    }
}

export default CustomizationApi;
