import React from 'react';
import styles from '../../create_listing_flow.module.css';
import CryptoCurrencyInfo from '../../../../../apis/offer_api/crypto_currency_info';
import formatNumber, { formatTokenValue } from '../../../../../utils/currency_formatter';
import Decimal from 'decimal.js';

interface Step3Props {
    listingType: 'fixed' | 'auction';
    setListingType: (type: 'fixed' | 'auction') => void;
    isCryptoSelected: boolean;
    cryptoAmount: number;
    selectedCrypto: CryptoCurrencyInfo | null;
    cryptoUsdValue: Decimal | null;
    getSelectedItemDetails: () => {
        title: string;
        description: string;
        imageUrl: string;
    };
}

const Step3ListingType: React.FC<Step3Props> = ({
    listingType,
    setListingType,
    isCryptoSelected,
    cryptoAmount,
    selectedCrypto,
    cryptoUsdValue,
    getSelectedItemDetails,
}) => {
    return (
        <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>Choose Listing Type</h3>
            
            <div className={styles.listingTypeSelection}>
                <div 
                    className={`${styles.listingTypeOption} ${listingType === 'fixed' ? styles.selected : ''}`}
                    onClick={() => setListingType('fixed')}
                >
                    <div className={styles.listingTypeIcon}>
                        <span>₿</span>
                    </div>
                    <div className={styles.listingTypeDetails}>
                        <h4>Fixed Price</h4>
                        <p>Set a specific price for your item</p>
                    </div>
                </div>
                
                <div 
                    className={`${styles.listingTypeOption} ${listingType === 'auction' ? styles.selected : ''}`}
                    onClick={() => setListingType('auction')}
                >
                    <div className={styles.listingTypeIcon}>
                        <span>🔨</span>
                    </div>
                    <div className={styles.listingTypeDetails}>
                        <h4>Auction</h4>
                        <p>Let buyers bid on your item</p>
                    </div>
                </div>
            </div>

            <div className={styles.selectedItemPreview}>
                <div className={styles.itemImage}>
                    <img
                        src={getSelectedItemDetails().imageUrl}
                        alt="Selected item"
                    />
                </div>
                <div className={styles.itemDetails}>
                    <h4>{getSelectedItemDetails().title}</h4>
                    {isCryptoSelected && (
                        <div className={styles.valueDisplay}>
                            <p>
                                {formatNumber(cryptoAmount)}{' '}
                                {selectedCrypto?.cryptoCurrency}
                                {cryptoUsdValue !== null && (
                                    <span>
                                        {' '}
                                        {formatTokenValue(
                                            cryptoUsdValue,
                                            cryptoAmount
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Step3ListingType;
