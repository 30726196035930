import React, { useEffect } from 'react';
import './App.css';
import ComponentContainer from './component_system/component_container/component_container';
import LocationComponent from './component_system/components/location/location_component';
import HomePage from './ui/pages/home_page';
import TelegramComponent from './component_system/components/telegram/telegram_component';
import AuthenticationComponent from './component_system/components/authentication/authentication_component';
import UnityComponent from './component_system/components/unity/unity_component';
import SignalRComponent from './component_system/components/signalr/signalr_component';
import PopulationComponent from './component_system/components/population/population_component';
import SettingsComponent from './component_system/components/settings/settings_component';
import FriendComponent from './component_system/components/friend/friend_component';
import './utils/string_extensions';
import MultiplayerComponent from './component_system/components/multiplayer/multiplayer_component';
import CustomizationComponent from './component_system/components/customization/customization_component';
import NavigationComponent from './component_system/components/navigation/navigation_component';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VaultPage from './ui/pages/vault_page';
import PlayabilityComponent from './component_system/components/playability/playability_component';
import OfferComponent from './component_system/components/offer/offer_component';
import QuestsPage from './ui/pages/quests_page';
import ProfilePage from './ui/pages/profile_page';
import PreloadComponent from './component_system/components/preload/preload_component';
import OverlayComponent from './component_system/components/overlay/overlay_component';
import FriendsPage from './ui/pages/friends_page';
import ReactModal from 'react-modal';
import QuestComponent from './component_system/components/quest/quest_component';
import { initializeAnalytics } from './utils/analytics';
import AnalyticsTracker from './utils/analytics_tracker';
import LeaderboardPage from './ui/pages/leaderboard_page';
import CustomizationPage from './ui/pages/customization_page';
import MarketPage from './ui/pages/market_page';
import MarketComponent from './component_system/components/market/market_component';

new ComponentContainer([
    new LocationComponent(),
    new TelegramComponent(),
    new AuthenticationComponent(),
    new UnityComponent(),
    new SignalRComponent(),
    new PopulationComponent(),
    new SettingsComponent(),
    new FriendComponent(),
    new MultiplayerComponent(),
    new CustomizationComponent(),
    new NavigationComponent(),
    new PlayabilityComponent(),
    new OfferComponent(),
    new PreloadComponent(),
    new OverlayComponent(),
    new QuestComponent(),
    new MarketComponent(),
]);

ReactModal.setAppElement('#root');

function App() {
    useEffect(() => {
        initializeAnalytics();
    }, []);

    return (
        <Router>
            <AnalyticsTracker />
            <Routes>
                <Route path="/" element={<HomePage />}>
                    <Route path="rewards" element={<VaultPage />} />
                    <Route path="friends" element={<FriendsPage />} />
                    <Route path="quests" element={<QuestsPage />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="leaderboard" element={<LeaderboardPage />} />
                    <Route
                        path="customization"
                        element={<CustomizationPage />}
                    />
                    <Route path="market" element={<MarketPage />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
