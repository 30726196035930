import TopReferrer from "./top_referrer";
import ToplistHistory from "./toplist_history";

class ToplistReferrals {
    startDate: Date;
    endDate: Date;
    topReferrers: TopReferrer[];
    previousPeriodHistory : ToplistHistory | undefined;


    constructor({
        startDate,
        endDate,
        topReferrers,
        previousPeriodHistory
    }: {
        startDate: Date;
        endDate: Date;
        topReferrers: TopReferrer[];   
        previousPeriodHistory : ToplistHistory | undefined;     
    }) {
        this.startDate = new Date(startDate);
        this.endDate = new Date(endDate);
        this.topReferrers = topReferrers;
        this.previousPeriodHistory = previousPeriodHistory;

    }

    static fromJsonLowerCamelCase(json: any): ToplistReferrals {
        return new ToplistReferrals({
            startDate: new Date(json.startDate),
            endDate: new Date(json.endDate),
            topReferrers: json.topReferrers.map((e: any) =>
                TopReferrer.fromJsonLowerCamelCase(e)
            ),
            previousPeriodHistory: json.previousPeriodHistory ? ToplistHistory.fromJsonLowerCamelCase(json.previousPeriodHistory) : undefined
  
        });
    }

    static fromJsonUpperCase(json: any): ToplistReferrals {
        return new ToplistReferrals({
            startDate: new Date(json.StartDate),
            endDate: new Date(json.EndDate),
            topReferrers: json.TopReferrers.map((e: any) =>
                TopReferrer.fromJsonUpperCase(e)
            ),
            previousPeriodHistory: json.PreviousPeriodHistory ? ToplistHistory.fromJsonUpperCase(json.PreviousPeriodHistory) : undefined
        });
    }
}

export default ToplistReferrals;