class Item {
    uniqueIndex: number;
    itemIndex: number;
    x: number;
    y: number;

    constructor(uniqueIndex: number, itemIndex: number, x: number, y: number) {
        this.uniqueIndex = uniqueIndex;
        this.itemIndex = itemIndex;
        this.x = x;
        this.y = y;
    }
}

class SpinnerUtility {
    screenWidth: number;
    screenHeight: number;
    itemWidth: number;
    itemHeight: number;
    spacing: number;
    numberOfItems: number;
    winIndex: number;
    pixelsPerSecond: number;
    runTime: number; // Milliseconds
    items: Item[] = [];
    lastUpdate: number = 0;
    elapsedTime: number = 0; // Milliseconds
    totalWidth: number; // Width of one item + spacing
    startTimestamp: number | null = null;
    currentlySelectedUniqueIndex: number | undefined = undefined;
    onSpinComplete: () => void;
    onSwitchedItem?: () => void;

    constructor({
        screenWidth,
        screenHeight,
        itemWidth,
        itemHeight,
        spacing,
        numberOfItems,
        winIndex,
        pixelsPerSecond,
        runTime,
        onSpinComplete,
        onSwitchedItem,
    }: {
        screenWidth: number;
        screenHeight: number;
        itemWidth: number;
        itemHeight: number;
        spacing: number;
        numberOfItems: number;
        winIndex: number;
        pixelsPerSecond: number;
        runTime: number;
        onSpinComplete: () => void;
        onSwitchedItem?: () => void;
    }) {
        this.screenWidth = screenWidth;
        this.screenHeight = screenHeight;
        this.itemWidth = itemWidth;
        this.itemHeight = itemHeight;
        this.spacing = spacing;
        this.numberOfItems = numberOfItems;
        this.winIndex = winIndex;
        this.pixelsPerSecond = pixelsPerSecond;
        this.runTime = runTime;
        this.totalWidth = this.itemWidth + this.spacing;
        this.onSpinComplete = onSpinComplete;
        this.onSwitchedItem = onSwitchedItem;

        this.generateItems();
    }

    getDistanceAtFraction(fraction: number): number {
        return this.pixelsPerSecond * (1 - Math.pow(1 - fraction, 3));
    }

    getCurrentMiddleItemIndex() {
        const middle = this.screenWidth / 2;

        return this.items.find(
            (item) =>
                item.x - this.spacing / 2 <= middle &&
                item.x + this.itemWidth + this.spacing / 2 >= middle
        );
    }

    reset() {
        this.lastUpdate = 0;
        this.elapsedTime = 0;
        // reset item positions
        this.items.forEach((item, index) => {
            item.x = index * this.totalWidth;
        });
    }

    generateItems() {
        // Calculate number of visible items needed plus buffer
        // 2-10
        const randomExtraItems = Math.floor(Math.random() * 9) + 2;
        const itemsNeeded =
            Math.ceil(this.screenWidth / this.totalWidth) + randomExtraItems;

        const y = this.screenHeight / 2 - this.itemHeight / 2;
        let uniqueIndex = 0;

        // Generate initial items
        for (let i = 0; i < itemsNeeded; i++) {
            const x = i * this.totalWidth;
            const randomItemIndex =
                i > this.numberOfItems - 1
                    ? Math.floor(Math.random() * this.numberOfItems)
                    : i;

            this.items.push(new Item(uniqueIndex++, randomItemIndex, x, y));
        }

        // shuffle array
        this.items.sort(() => Math.random() - 0.5);
        // make sure the indexes are in order
        this.items.forEach((item, index) => {
            item.uniqueIndex = index;
        });

        const winningItemCopy = this.getMiddleItemAfterFullSimulation();

        if (!winningItemCopy) {
            this.items = [];
            this.generateItems();
            return;
        }

        const winningItem = this.items.find(
            (item) => item.uniqueIndex === winningItemCopy?.uniqueIndex
        );
        if (winningItem?.itemIndex !== this.winIndex) {
            winningItem!.itemIndex = this.winIndex;
        }
    }

    start() {
        this.startTimestamp = Date.now();
        this.lastUpdate = this.startTimestamp;
        console.error(this.getMiddleItemAfterFullSimulation());
    }

    update(time?: number) {
        time = time || Date.now();

        // Initialize lastUpdate on first call
        if (this.lastUpdate === 0) {
            return;
        }

        this.elapsedTime = time - this.startTimestamp!;
        this.lastUpdate = time;

        let stop = false;

        // Stop updating if animation is complete
        if (this.elapsedTime >= this.runTime) {
            this.elapsedTime = this.runTime;
            stop = true;
        }

        const interpolatedDistance = this.getDistanceAtFraction(
            this.elapsedTime / this.runTime
        );
        // How many items does it take to fill the screen?
        const totalCycleWidth =
            this.items.length * this.totalWidth - this.spacing / 2;
        const effectiveDistance = interpolatedDistance % totalCycleWidth;

        for (const item of this.items) {
            const originalPosition = item.uniqueIndex * this.totalWidth;
            item.x = Math.round(originalPosition - effectiveDistance);
        }

        // Wrap items that have moved off the left side of the screen
        for (const item of this.items) {
            if (item.x <= -this.totalWidth) {
                const rightmostItem = this.items.reduce((prev, curr) =>
                    curr.x > prev.x ? curr : prev
                );
                item.x = Math.round(rightmostItem.x + this.totalWidth);
            }
        }

        const oldCurrentlySelectedUniqueIndex =
            this.currentlySelectedUniqueIndex;
        this.currentlySelectedUniqueIndex =
            this.getCurrentMiddleItemIndex()?.uniqueIndex;

        if (
            oldCurrentlySelectedUniqueIndex !== undefined &&
            this.currentlySelectedUniqueIndex !== undefined
        ) {
            if (
                oldCurrentlySelectedUniqueIndex !==
                this.currentlySelectedUniqueIndex
            ) {
                this.onSwitchedItem?.();
            }
        }

        if (stop) {
            console.error(this.getCurrentMiddleItemIndex());
            // TODO: Call callback
            this.onSpinComplete();
            this.lastUpdate = 0;
            this.startTimestamp = null;
        }

        return stop;
    }

    // predict the middle item after the full simulation
    getMiddleItemAfterFullSimulation() {
        const totalDistance = this.getDistanceAtFraction(1);
        const totalCycleWidth =
            this.items.length * this.totalWidth - this.spacing / 2;
        const effectiveDistance = totalDistance % totalCycleWidth;

        const itemsCopy = this.items.map((item) => ({ ...item }));

        for (const item of itemsCopy) {
            const originalPosition = item.uniqueIndex * this.totalWidth;
            item.x = Math.round(originalPosition - effectiveDistance);
        }

        for (const item of this.items) {
            // Wrap items that have moved off the left side of the screen
            if (item.x <= -this.totalWidth) {
                const rightmostItem = this.items.reduce((prev, curr) =>
                    curr.x > prev.x ? curr : prev
                );
                item.x = Math.round(rightmostItem.x + this.totalWidth);
            }
        }

        const middle = this.screenWidth / 2;
        return itemsCopy.find(
            (item) =>
                item.x - this.spacing / 2 <= middle &&
                item.x + this.itemWidth + this.spacing / 2 >= middle
        );
    }
}

export default SpinnerUtility;
