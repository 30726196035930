// noinspection ExceptionCaughtLocallyJS

import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import SignalRComponent from '../signalr/signalr_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import SignalRConnected from '../signalr/signalr_connected';
import AuthenticationComponent from '../authentication/authentication_component';
import LocationComponent from '../location/location_component';
import OfferComponent from '../offer/offer_component';
import { MarketApiClient } from '../../../generated/MarketApiClient';
import {
    MarketListingSearchModel,
    MakeMarketListing,
    MakeAcquiredOffer,
} from '../../../generated/data-contracts';

type MarketSubscriber = () => void;

/**
 * Component for managing market listings and interactions
 */
class MarketComponent extends Component {
    private _subscribers: MarketSubscriber[] = [];
    private _subscribedTo: string[] = [];
    private _userListings: MakeMarketListing[] = [];
    private _globalListings: MakeMarketListing[] = [];

    private _offerComponent: OfferComponent | undefined = undefined;
    private _locationComponent: LocationComponent | undefined = undefined;
    private _signalRComponent: SignalRComponent | undefined = undefined;

    private _globalSearchModel: MarketListingSearchModel | undefined =
        undefined;
    private _localSearchModel: MarketListingSearchModel | undefined = undefined;

    private _fetching: boolean = false;
    private _userListingsCurrentPage: number = 1;
    private _userListingsHasNextPage: boolean = false;
    private _currentPage: number = 1;
    private _hasNextPage: boolean = false;
    private _pageSize: number = 8; // Could be made configurable

    private _marketApiClient: MarketApiClient | undefined = undefined;

    /**
     * Get user's own market listings
     */
    get userListings(): MakeMarketListing[] {
        return this._userListings;
    }

    /**
     * Get global market listings
     */
    get globalListings(): MakeMarketListing[] {
        return this._globalListings;
    }

    /**
     * Whether the component is currently fetching data
     */
    get fetching(): boolean {
        return this._fetching;
    }

    /**
     * Current page for user listings
     */
    get userListingsCurrentPage(): number {
        return this._userListingsCurrentPage;
    }

    /**
     * Whether there are more user listings to load
     */
    get userListingsHasNextPage(): boolean {
        return this._userListingsHasNextPage;
    }

    /**
     * Current page for global listings
     */
    get currentPage(): number {
        return this._currentPage;
    }

    /**
     * Whether there are more global listings to load
     */
    get hasNextPage(): boolean {
        return this._hasNextPage;
    }

    /**
     * Register a subscriber to be notified of changes
     * @param subscriber Function to call when state changes
     */
    registerSubscriber(subscriber: MarketSubscriber): void {
        this._subscribers.push(subscriber);
    }

    /**
     * Unregister a previously registered subscriber
     * @param subscriber Function to remove from subscribers
     */
    unregisterSubscriber(subscriber: MarketSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    /**
     * Notify all subscribers of state changes
     */
    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
        this._notifyObservers(undefined);
    }

    get type(): Function {
        return MarketComponent;
    }

    get name(): string {
        return 'Market Component';
    }

    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);

        try {
            const authComponent =
                await ContainerHelper.getAuthenticationComponent();
            this._marketApiClient = new MarketApiClient(
                authComponent.authToken!
            );

            // Initial load of user listings could go here
            // this._userListings = await this._fetchUserListings();
            // this._subscribedTo = this._userListings.map(
            //     (listing) => listing.id
            // );
        } catch (e) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'marketComponentFailedToLoad'.tr()
                ),
            ];
        }

        await this.setDependencyLocked([
            SignalRComponent,
            LocationComponent,
            OfferComponent,
        ]);

        this._offerComponent = await ContainerHelper.getOffersComponent();
        this._locationComponent = await ContainerHelper.getLocationComponent();
        this._signalRComponent = await ContainerHelper.getSignalRComponent();

        // Set up SignalR handlers
        this._signalRComponent.addConnectionStateChangedEventHandler(
            this._connectionStateChangedEventHandler.bind(this)
        );

        this._signalRComponent.registerMethodHandler(
            'MarketListingRemoved',
            this._marketListingRemovedHandler.bind(this)
        );

        this._signalRComponent.registerMethodHandler(
            'MarketListingPurchased',
            this._marketListingPurchasedHandler.bind(this)
        );

        this._signalRComponent.registerMethodHandler(
            'MarketListingExpired',
            this._marketListingExpiredHandler.bind(this)
        );

        this._signalRComponent.registerMethodHandler(
            'AuctionEnded',
            this._auctionEndedHandler.bind(this)
        );

        this._signalRComponent.registerMethodHandler(
            'BidPlaced',
            this._bidPlacedHandler.bind(this)
        );

        return [];
    }

    private _connectionStateChangedEventHandler(state: SignalRConnected): void {
        if (state === SignalRConnected.Connected) {
            if (this._globalListings.length > 0) {
                const listingIds = this._globalListings.map(
                    (listing) => listing.id!
                );
                void this._subscribeToMarketListings(listingIds);
            } else {
                void this._unsubscribeFromAllMarketListings();
            }
        }
    }

    private _marketListingRemovedHandler(...args: any[]): void {
        const listingId = args[0] as string;

        const globalListing = this._globalListings.find(
            (listing) => listing.id === listingId
        );
        if (globalListing) {
            globalListing.isRemoved = true;
            globalListing.removedAt = new Date().toISOString();
            this._notifySubscribers();
        }
    }

    private _marketListingPurchasedHandler(...args: any[]): void {
        const jsonStr = args[0] as string;
        const json = JSON.parse(jsonStr);

        const marketListingId = json.MarketListingId as string;
        const purchasedAt = new Date(json.PurchasedAt as string);
        const purchasedByUserId = json.PurchasedByUserId as string;

        // Update global listings
        const globalListing = this._globalListings.find(
            (listing) => listing.id === marketListingId
        );
        if (globalListing) {
            globalListing.isPurchased = true;
            globalListing.purchasedAt = purchasedAt.toISOString();
            globalListing.purchasedByUserId = purchasedByUserId;
        }

        // Update user listings
        const userListing = this._userListings.find(
            (listing) => listing.id === marketListingId
        );
        if (userListing) {
            userListing.isPurchased = true;
            userListing.purchasedAt = purchasedAt.toISOString();
            userListing.purchasedByUserId = purchasedByUserId;
        }

        if (globalListing || userListing) {
            this._notifySubscribers();
        }
    }

    private _marketListingExpiredHandler(...args: any[]): void {
        const listingId = args[0] as string;

        const globalListing = this._globalListings.find(
            (listing) => listing.id === listingId
        );
        if (globalListing) {
            globalListing.isExpired = true;
            this._notifySubscribers();
        }

        const userListing = this._userListings.find(
            (listing) => listing.id === listingId
        );
        if (userListing) {
            userListing.isExpired = true;
            this._notifySubscribers();
        }
    }

    private _auctionEndedHandler(...args: any[]): void {
        const jsonStr = args[0] as string;
        const json = JSON.parse(jsonStr);

        const marketListingId = json.MarketListingId as string;
        const outcome = json.Outcome as string;
        const winnerUsername = json.WinnerUsername as string | null;
        const winnerUserId = json.WinnerUserId as string | null;
        const winningBid = json.WinningBid as number;
        const purchasedAt = new Date(json.PurchasedAt as string);
        const hasWinner = json.HasWinner as boolean;

        // Update global listings
        const globalListing = this._globalListings.find(
            (listing) => listing.id === marketListingId
        );
        if (globalListing) {
            globalListing.isExpired = true;

            if (hasWinner) {
                globalListing.isPurchased = true;
                globalListing.purchasedAt = purchasedAt.toISOString();
                globalListing.purchasedByUserId = winnerUserId;
                globalListing.purchasedByUserUsername = winnerUsername;
                globalListing.currentBid = winningBid;
                globalListing.currentBidderUserId = winnerUserId;
                globalListing.currentBidderUsername = winnerUsername;
            }
        }

        // Update user listings
        const userListing = this._userListings.find(
            (listing) => listing.id === marketListingId
        );
        if (userListing) {
            userListing.isExpired = true;

            if (hasWinner) {
                userListing.isPurchased = true;
                userListing.purchasedAt = purchasedAt.toISOString();
                userListing.purchasedByUserId = winnerUserId;
                userListing.purchasedByUserUsername = winnerUsername;
                userListing.currentBid = winningBid;
                userListing.currentBidderUserId = winnerUserId;
                userListing.currentBidderUsername = winnerUsername;
            }
        }

        if (globalListing || userListing) {
            this._notifySubscribers();
        }
    }

    private _bidPlacedHandler(...args: any[]): void {
        const jsonStr = args[0] as string;
        const json = JSON.parse(jsonStr);

        const marketListingId = json.MarketListingId as string;
        const bidderUsername = json.BidderUsername as string;
        const bidderUserId = json.BidderUserId as string;
        const bidAmount = json.BidAmount as number;
        const bidTime = new Date(json.BidTime as string);
        const updatedExpiryTime = json.UpdatedExpiryTime
            ? new Date(json.UpdatedExpiryTime as string)
            : null;

        // Update global listings
        const globalListing = this._globalListings.find(
            (listing) => listing.id === marketListingId
        );
        if (globalListing) {
            globalListing.currentBid = bidAmount;
            globalListing.currentBidderUserId = bidderUserId;
            globalListing.currentBidderUsername = bidderUsername;

            // Add the bid to bid history if it exists
            if (globalListing.bidHistory) {
                globalListing.bidHistory.push({
                    id: null,
                    userId: bidderUserId,
                    username: bidderUsername,
                    amount: bidAmount,
                    bidTime: bidTime.toISOString(),
                });
            } else {
                console.error(
                    'Bid history not found for global listing:',
                    marketListingId
                );
            }

            // Update expiry time if provided
            if (updatedExpiryTime) {
                globalListing.listingExpiresAt =
                    updatedExpiryTime.toISOString();
            }
        }

        // Update user listings
        const userListing = this._userListings.find(
            (listing) => listing.id === marketListingId
        );
        if (userListing) {
            userListing.currentBid = bidAmount;
            userListing.currentBidderUserId = bidderUserId;
            userListing.currentBidderUsername = bidderUsername;

            // Add the bid to bid history if it exists
            if (userListing.bidHistory) {
                userListing.bidHistory.push({
                    id: null,
                    userId: bidderUserId,
                    username: bidderUsername,
                    amount: bidAmount,
                    bidTime: bidTime.toISOString(),
                });
            } else {
                console.error(
                    'Bid history not found for user listing:',
                    marketListingId
                );
            }

            // Update expiry time if provided
            if (updatedExpiryTime) {
                userListing.listingExpiresAt = updatedExpiryTime.toISOString();
            }
        }

        if (globalListing || userListing) {
            this._notifySubscribers();
        }
    }

    async onUnload(): Promise<void> {
        // Clean up any resources
    }

    async onPause(): Promise<void> {
        // Handle component pause
    }

    async onResume(): Promise<void> {
        // Handle component resume
    }

    update(_sinceLastUpdate: TimeDuration): void {
        // Periodic updates if needed
    }

    /**
     * Create a new market listing
     * @param acquiredOfferId ID of the acquired offer to list
     * @param coinPrice Price in coins
     * @param expiryDate Expiration date for the listing
     * @param isFeatured Whether the listing should be featured
     * @param consolidatedTransactionFeeAcquiredOfferId Optional ID of the acquired offer to use for transaction fee
     * @returns The created market listing
     */
    async createMarketListing(
        acquiredOfferId: string,
        coinPrice: number,
        expiryDate: Date,
        isFeatured?: boolean,
        consolidatedTransactionFeeAcquiredOfferId?: string | null
    ): Promise<MakeMarketListing> {
        if (!this._marketApiClient) {
            throw new Error('Market API client not initialized');
        }

        try {
            const response =
                await this._marketApiClient.api.createMarketListingCreate({
                    acquiredOfferId,
                    coinPrice,
                    expiryDate: expiryDate.toISOString(),
                    isFeatured,
                    consolidatedTransactionFeeAcquiredOfferId,
                });

            const marketListing: MakeMarketListing = response.data;

            // Add to user listings and subscribed listings
            // this._userListings.push(marketListing);
            this._subscribedTo.push(marketListing.id!);

            // Mark the offer as listed
            if (this._offerComponent) {
                await this._offerComponent.markOfferAsSeen(acquiredOfferId);
            }

            this._notifySubscribers();
            return marketListing;
        } catch (error) {
            console.error('Failed to create market listing:', error);
            throw error;
        }
    }

    /**
     * Create a new auction listing
     * @param acquiredOfferId ID of the acquired offer to auction
     * @param startingBid Starting bid amount
     * @param bidIncrement Minimum bid increment
     * @param auctionEndDate End date for the auction
     * @param reservePrice Optional reserve price (minimum acceptable price)
     * @param isFeatured Whether the listing should be featured
     * @param consolidatedTransactionFeeAcquiredOfferId Optional ID of the acquired offer to use for transaction fee
     * @returns The created auction listing
     */
    async createAuctionListing(
        acquiredOfferId: string,
        startingBid: number,
        bidIncrement: number,
        auctionEndDate: Date,
        reservePrice?: number,
        isFeatured?: boolean,
        consolidatedTransactionFeeAcquiredOfferId?: string | null
    ): Promise<MakeMarketListing> {
        if (!this._marketApiClient) {
            throw new Error('Market API client not initialized');
        }

        try {
            const response =
                await this._marketApiClient.api.createAuctionListingCreate({
                    acquiredOfferId,
                    startingBid,
                    bidIncrement,
                    auctionEndDate: auctionEndDate.toISOString(),
                    reservePrice,
                    isFeatured,
                    consolidatedTransactionFeeAcquiredOfferId,
                });

            const marketListing: MakeMarketListing = response.data;

            // Add to subscribed listings
            this._subscribedTo.push(marketListing.id!);

            // Mark the offer as listed
            if (this._offerComponent) {
                await this._offerComponent.markOfferAsSeen(acquiredOfferId);
            }

            this._notifySubscribers();
            return marketListing;
        } catch (error) {
            console.error('Failed to create auction listing:', error);
            throw error;
        }
    }

    /**
     * Remove a market listing
     * @param listingId ID of the listing to remove
     */
    async removeMarketListing(listingId: string): Promise<void> {
        if (!this._marketApiClient) {
            throw new Error('Market API client not initialized');
        }

        try {
            await this._marketApiClient.api.removeMarketListingCreate(
                listingId
            );

            // Find the listing and mark it as removed
            const listing = this._userListings.find((l) => l.id === listingId);
            if (listing) {
                const acquiredOfferId = listing.acquiredOffer?.acquiredOfferId;

                listing.isRemoved = true;
                listing.removedAt = new Date().toISOString();

                // Remove from subscribed listings
                this._subscribedTo = this._subscribedTo.filter(
                    (id) => id !== listingId
                );

                // Mark the offer as unlisted if we have the offer component
                if (this._offerComponent && acquiredOfferId) {
                    await this._offerComponent.markOfferAsSeen(acquiredOfferId);
                }
            }

            this._notifySubscribers();
        } catch (error) {
            console.error('Failed to remove market listing:', error);
            throw error;
        }
    }

    /**
     * Purchase a market listing
     * @param listingId ID of the listing to purchase
     */
    async purchaseMarketListing(listingId: string): Promise<void> {
        if (!this._marketApiClient) {
            throw new Error('Market API client not initialized');
        }

        try {
            await this._marketApiClient.api.purchaseMarketListingCreate(
                listingId
            );
            this._notifySubscribers();
        } catch (error) {
            console.error('Failed to purchase market listing:', error);
            throw error;
        }
    }

    /**
     * Set the search model for a specific tab
     * @param tabIndex Tab index (0 for global, 1 for local)
     * @param searchModel Search model to set
     * @param forceRefresh Whether to force a refresh even if the model hasn't changed
     */
    async setSearchModel(
        tabIndex: number,
        searchModel: MarketListingSearchModel,
        forceRefresh: boolean = false
    ): Promise<void> {
        searchModel.page = 1;
        searchModel.pageSize = this._pageSize;

        const isChanged = this._isSearchModelDifferent(tabIndex, searchModel);

        if (isChanged || forceRefresh) {
            await this._updateSearchModel(tabIndex, searchModel);
        }
    }

    /**
     * Check if a search model is different from the current one
     * @param tabIndex Tab index to check
     * @param searchModel Search model to compare
     * @returns Whether the search model is different
     */
    private _isSearchModelDifferent(
        tabIndex: number,
        searchModel: MarketListingSearchModel
    ): boolean {
        const currentModel =
            tabIndex === 0 ? this._globalSearchModel : this._localSearchModel;

        if (!currentModel) return true;

        // Compare relevant fields (excluding page, pageSize, and location)
        const currentJson = JSON.stringify({
            search: currentModel.search,
            searchIn: currentModel.searchIn,
            profileType: currentModel.profileType,
            type: currentModel.type,
            sort: currentModel.sort,
        });

        const newJson = JSON.stringify({
            search: searchModel.search,
            searchIn: searchModel.searchIn,
            profileType: searchModel.profileType,
            type: searchModel.type,
            sort: searchModel.sort,
        });

        return currentJson !== newJson;
    }

    /**
     * Update the search model and fetch results
     * @param tabIndex Tab index to update
     * @param searchModel Search model to use
     */
    private async _updateSearchModel(
        tabIndex: number,
        searchModel: MarketListingSearchModel
    ): Promise<void> {
        if (this._locationComponent) {
            const position = this._locationComponent.lastPosition!;
            searchModel.latitude = position.coords.latitude;
            searchModel.longitude = position.coords.longitude;
        }

        if (tabIndex === 0) {
            this._globalSearchModel = searchModel;
            await this._onGlobalSearchModelChanged(searchModel);
        } else {
            this._localSearchModel = searchModel;
            await this._onLocalSearchModelChanged(searchModel);
        }
    }

    /**
     * Handle changes to the local search model
     * @param searchModel Updated search model
     */
    private async _onLocalSearchModelChanged(
        searchModel: MarketListingSearchModel
    ): Promise<void> {
        this._fetching = true;
        this._notifySubscribers();

        try {
            if (!this._marketApiClient) {
                throw new Error('Market API client not initialized');
            }

            const response =
                await this._marketApiClient.api.getMarketListingsCreate(
                    searchModel
                );
            const result = response.data;

            this._userListings = result.listings || [];
            this._userListingsCurrentPage = searchModel.page || 1;
            this._userListingsHasNextPage = result.hasNextPage || false;

            this._fetching = false;
            this._notifySubscribers();
        } catch (error) {
            console.error('Failed to fetch local market listings:', error);
            this._fetching = false;
            this._notifySubscribers();
            throw error;
        }
    }

    /**
     * Handle changes to the global search model
     * @param searchModel Updated search model
     */
    private async _onGlobalSearchModelChanged(
        searchModel: MarketListingSearchModel
    ): Promise<void> {
        this._fetching = true;
        this._notifySubscribers();

        try {
            if (!this._marketApiClient) {
                throw new Error('Market API client not initialized');
            }

            const response =
                await this._marketApiClient.api.getMarketListingsCreate(
                    searchModel
                );
            const result = response.data;

            this._globalListings = result.listings || [];
            this._currentPage = searchModel.page || 1;
            this._hasNextPage = result.hasNextPage || false;

            // Update subscriptions
            if (this._globalListings.length > 0) {
                const listingIds = this._globalListings.map(
                    (listing) => listing.id!
                );
                await this._subscribeToMarketListings(listingIds);
            } else {
                await this._unsubscribeFromAllMarketListings();
            }

            this._fetching = false;
            this._notifySubscribers();
        } catch (error) {
            console.error('Failed to fetch global market listings:', error);
            this._fetching = false;
            this._notifySubscribers();
            throw error;
        }
    }

    /**
     * Subscribe to market listing updates
     * @param listingIds IDs of listings to subscribe to
     */
    private async _subscribeToMarketListings(
        listingIds: string[]
    ): Promise<void> {
        if (!this._marketApiClient) {
            throw new Error('Market API client not initialized');
        }

        try {
            await this._marketApiClient.api.subscribeToMarketListingsCreate({
                marketListingIds: listingIds,
            });
        } catch (error) {
            console.error('Failed to subscribe to market listings:', error);
        }
    }

    /**
     * Unsubscribe from all market listing updates
     */
    private async _unsubscribeFromAllMarketListings(): Promise<void> {
        if (!this._marketApiClient) {
            throw new Error('Market API client not initialized');
        }

        try {
            await this._marketApiClient.api.unsubscribeFromAllMarketListingsCreate();
        } catch (error) {
            console.error('Failed to unsubscribe from market listings:', error);
        }
    }

    /**
     * Load the next page of global listings
     */
    async loadNextPageGlobalListings(): Promise<void> {
        if (!this._hasNextPage || !this._globalSearchModel) {
            return;
        }

        this._globalSearchModel.page = this._currentPage + 1;
        this._globalSearchModel.pageSize = this._pageSize;

        this._fetching = true;
        this._notifySubscribers();

        try {
            if (!this._marketApiClient) {
                throw new Error('Market API client not initialized');
            }

            const response =
                await this._marketApiClient.api.getMarketListingsCreate(
                    this._globalSearchModel
                );
            const result = response.data;

            this._globalListings = result.listings || [];
            this._currentPage = this._globalSearchModel.page || 1;
            this._hasNextPage = result.hasNextPage || false;

            this._fetching = false;
            this._notifySubscribers();
        } catch (error) {
            console.error(
                'Failed to load next page of global listings:',
                error
            );
            this._fetching = false;
            this._notifySubscribers();
            throw error;
        }
    }

    /**
     * Load the previous page of global listings
     */
    async loadPreviousPageGlobalListings(): Promise<void> {
        if (this._currentPage <= 1 || !this._globalSearchModel) {
            return;
        }

        this._globalSearchModel.page = this._currentPage - 1;
        this._globalSearchModel.pageSize = this._pageSize;

        this._fetching = true;
        this._notifySubscribers();

        try {
            if (!this._marketApiClient) {
                throw new Error('Market API client not initialized');
            }

            const response =
                await this._marketApiClient.api.getMarketListingsCreate(
                    this._globalSearchModel
                );
            const result = response.data;

            this._globalListings = result.listings || [];
            this._currentPage = this._globalSearchModel.page || 1;
            this._hasNextPage = result.hasNextPage || false;

            this._fetching = false;
            this._notifySubscribers();
        } catch (error) {
            console.error(
                'Failed to load previous page of global listings:',
                error
            );
            this._fetching = false;
            this._notifySubscribers();
            throw error;
        }
    }

    /**
     * Load the next page of user listings
     */
    async loadNextPageUserListings(): Promise<void> {
        if (!this._userListingsHasNextPage || !this._localSearchModel) {
            return;
        }

        this._localSearchModel.page = this._userListingsCurrentPage + 1;
        this._localSearchModel.pageSize = this._pageSize;

        this._fetching = true;
        this._notifySubscribers();

        try {
            if (!this._marketApiClient) {
                throw new Error('Market API client not initialized');
            }

            const response =
                await this._marketApiClient.api.getMarketListingsCreate(
                    this._localSearchModel
                );
            const result = response.data;

            this._userListings = result.listings || [];
            this._userListingsCurrentPage = this._localSearchModel.page || 1;
            this._userListingsHasNextPage = result.hasNextPage || false;

            this._fetching = false;
            this._notifySubscribers();
        } catch (error) {
            console.error('Failed to load next page of user listings:', error);
            this._fetching = false;
            this._notifySubscribers();
            throw error;
        }
    }

    /**
     * Load the previous page of user listings
     */
    async loadPreviousPageUserListings(): Promise<void> {
        if (this._userListingsCurrentPage <= 1 || !this._localSearchModel) {
            return;
        }

        this._localSearchModel.page = this._userListingsCurrentPage - 1;
        this._localSearchModel.pageSize = this._pageSize;

        this._fetching = true;
        this._notifySubscribers();

        try {
            if (!this._marketApiClient) {
                throw new Error('Market API client not initialized');
            }

            const response =
                await this._marketApiClient.api.getMarketListingsCreate(
                    this._localSearchModel
                );
            const result = response.data;

            this._userListings = result.listings || [];
            this._userListingsCurrentPage = this._localSearchModel.page || 1;
            this._userListingsHasNextPage = result.hasNextPage || false;

            this._fetching = false;
            this._notifySubscribers();
        } catch (error) {
            console.error(
                'Failed to load previous page of user listings:',
                error
            );
            this._fetching = false;
            this._notifySubscribers();
            throw error;
        }
    }

    /**
     * Consolidate cryptocurrency holdings
     * @param cryptoCurrency The cryptocurrency to consolidate
     * @param amount The amount to consolidate
     * @param excludeOfferIds Optional array of offer IDs to exclude from consolidation
     * @returns The acquired offer created from consolidation
     */
    async consolidateCryptoCurrency(
        cryptoCurrency: string,
        amount: number,
        excludeOfferIds?: string[]
    ): Promise<MakeAcquiredOffer> {
        if (!this._marketApiClient) {
            throw new Error('Market API client not initialized');
        }

        try {
            const response =
                await this._marketApiClient.api.consolidateCryptoCurrencyCreate(
                    {
                        cryptoCurrency,
                        amount,
                        excludeOfferIds,
                    }
                );

            const acquiredOffer: MakeAcquiredOffer = response.data;
            this._notifySubscribers();
            return acquiredOffer;
        } catch (error) {
            console.error('Failed to consolidate cryptocurrency:', error);
            throw error;
        }
    }

    /**
     * Place a bid on an auction listing
     * @param marketListingId ID of the auction listing
     * @param bidAmount Amount to bid
     */
    async placeBid(marketListingId: string, bidAmount: number): Promise<void> {
        if (!this._marketApiClient) {
            throw new Error('Market API client not initialized');
        }

        try {
            await this._marketApiClient.api.placeBidCreate({
                marketListingId,
                bidAmount,
            });

            // The bid will be reflected through SignalR updates
            this._notifySubscribers();
        } catch (error) {
            console.error('Failed to place bid:', error);
            throw error;
        }
    }
}

export default MarketComponent;
