/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcquiredOfferData {
  id?: string | null;
  acquiredOfferId?: string | null;
  userId?: string | null;
  telegramId?: string | null;
  usernameAtAcquisition?: string | null;
  /** @format date-time */
  timestamp?: string;
  ipAddress?: string | null;
  forwardedFor?: string | null;
  previousPositions?: PositionModel[] | null;
  lastPlayablePositionsGroup?: PositionModel[] | null;
  mineClicks?: ClickData[] | null;
  userAgentInfo?: UserAgentInfo;
  /** @format int32 */
  innerScreenHeight?: number | null;
  /** @format int32 */
  innerScreenWidth?: number | null;
  bubble?: Bubble;
  reportStoragePath?: string | null;
  flags?: AcquiredOfferDataFlag[] | null;
  version?: AcquiredOfferDataVersion;
}

export interface AcquiredOfferDataFlag {
  type?: AcquiredOfferDataFlagType;
  data?: Record<string, any>;
}

/** @format int32 */
export enum AcquiredOfferDataFlagType {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum AcquiredOfferDataVersion {
  Value0 = 0,
  Value1 = 1,
}

export interface AddEventToGroupRequest {
  groupId?: string | null;
  eventId?: string | null;
}

export interface AddRuleToGroupRequest {
  groupId?: string | null;
  ruleName?: string | null;
  ruleValue?: string | null;
}

export interface AddressResult {
  country?: string | null;
  countryCode?: string | null;
  county?: string | null;
  houseNumber?: string | null;
  postCode?: string | null;
  road?: string | null;
  state?: string | null;
  town?: string | null;
  pedestrian?: string | null;
  neighborhood?: string | null;
  hamlet?: string | null;
  suburb?: string | null;
  village?: string | null;
  city?: string | null;
  region?: string | null;
  district?: string | null;
  name?: string | null;
}

export interface AggregatedStatistics {
  dailyUsers?: Record<string, number>;
  dailyOffers?: Record<string, number>;
  dailyPlaytime?: Record<string, number>;
  dailyLootboxOffers?: Record<string, number>;
  dailyRegistrations?: Record<string, number>;
  marketListingStats?: MarketListingStatsResult;
  /** @format int32 */
  totalPlayers?: number;
}

export interface AllowDiscoverabilityModel {
  allowDiscoverability?: boolean;
}

export interface Authentication {
  firstFactors?: FirstFactor[] | null;
  secondFactors?: SecondFactor[] | null;
}

export interface Authorization {
  roles?: string[] | null;
}

export interface BanModel {
  banReason?: string | null;
  /** @format date-time */
  banUntil?: string | null;
}

export type BoundingBox = object;

export interface Broadcast {
  id?: string | null;
  messageType?: BroadcastMessageType;
  telegramUserIds?: number[] | null;
  message?: string | null;
  /** @format date-time */
  scheduledTime?: string | null;
  status?: BroadcastStatus;
  /** @format int32 */
  retryCount?: number;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  imageUrl?: string | null;
  /** @format date-time */
  processingStartedAt?: string | null;
  /** @format date-time */
  completedAt?: string | null;
  /** @format int32 */
  successCount?: number;
  /** @format int32 */
  failureCount?: number;
  error?: string | null;
}

/** @format int32 */
export enum BroadcastFilter {
  Value0 = 0,
}

/** @format int32 */
export enum BroadcastMessageType {
  Value0 = 0,
  Value1 = 1,
}

export interface BroadcastPaginatedResponse {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalItems?: number;
  items?: Broadcast[] | null;
}

/** @format int32 */
export enum BroadcastSortBy {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
}

/** @format int32 */
export enum BroadcastStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface BsonBinaryData {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  bsonType?: BsonType;
  /** @format byte */
  bytes?: string | null;
  guidRepresentation?: GuidRepresentation;
  /** @deprecated */
  rawValue?: any;
  subType?: BsonBinarySubType;
}

/** @format int32 */
export enum BsonBinarySubType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value128 = 128,
}

export interface BsonDateTime {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  bsonType?: BsonType;
  isValidDateTime?: boolean;
  /** @format int64 */
  millisecondsSinceEpoch?: number;
  /** @deprecated */
  rawValue?: any;
  /**
   * @deprecated
   * @format date-time
   */
  value?: string;
}

export interface BsonElement {
  name?: string | null;
  value?: BsonValue;
}

export interface BsonJavaScript {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  bsonType?: BsonType;
  code?: string | null;
}

export interface BsonJavaScriptWithScope {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  code?: string | null;
  bsonType?: BsonType;
  scope?: BsonElement[] | null;
}

export interface BsonMaxKey {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  bsonType?: BsonType;
}

export interface BsonMinKey {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  bsonType?: BsonType;
}

export interface BsonNull {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  bsonType?: BsonType;
}

export interface BsonRegularExpression {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  bsonType?: BsonType;
  pattern?: string | null;
  options?: string | null;
}

export interface BsonSymbol {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  bsonType?: BsonType;
  name?: string | null;
}

export interface BsonTimestamp {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  bsonType?: BsonType;
  /** @format int64 */
  value?: number;
  /** @format int32 */
  increment?: number;
  /** @format int32 */
  timestamp?: number;
}

/** @format int32 */
export enum BsonType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value19 = 19,
  Value127 = 127,
  Value255 = 255,
}

export interface BsonUndefined {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
  bsonType?: BsonType;
}

export interface BsonValue {
  asBoolean?: boolean;
  asBsonArray?: BsonValue[] | null;
  asBsonBinaryData?: BsonBinaryData;
  asBsonDateTime?: BsonDateTime;
  asBsonDocument?: BsonElement[] | null;
  asBsonJavaScript?: BsonJavaScript;
  asBsonJavaScriptWithScope?: BsonJavaScriptWithScope;
  asBsonMaxKey?: BsonMaxKey;
  asBsonMinKey?: BsonMinKey;
  asBsonNull?: BsonNull;
  asBsonRegularExpression?: BsonRegularExpression;
  asBsonSymbol?: BsonSymbol;
  asBsonTimestamp?: BsonTimestamp;
  asBsonUndefined?: BsonUndefined;
  asBsonValue?: BsonValue;
  /** @format byte */
  asByteArray?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asDateTime?: string;
  /** @format double */
  asDecimal?: number;
  asDecimal128?: Decimal128;
  /** @format double */
  asDouble?: number;
  /** @format uuid */
  asGuid?: string;
  /** @format int32 */
  asInt32?: number;
  /**
   * @deprecated
   * @format date-time
   */
  asLocalTime?: string;
  /** @format int64 */
  asInt64?: number;
  asNullableBoolean?: boolean | null;
  /**
   * @deprecated
   * @format date-time
   */
  asNullableDateTime?: string | null;
  /** @format double */
  asNullableDecimal?: number | null;
  asNullableDecimal128?: Decimal128;
  /** @format double */
  asNullableDouble?: number | null;
  /** @format uuid */
  asNullableGuid?: string | null;
  /** @format int32 */
  asNullableInt32?: number | null;
  /** @format int64 */
  asNullableInt64?: number | null;
  asNullableObjectId?: ObjectId;
  asObjectId?: ObjectId;
  asRegex?: Regex;
  asString?: string | null;
  /**
   * @deprecated
   * @format date-time
   */
  asUniversalTime?: string;
  bsonType?: BsonType;
  isBoolean?: boolean;
  isBsonArray?: boolean;
  isBsonBinaryData?: boolean;
  isBsonDateTime?: boolean;
  isBsonDocument?: boolean;
  isBsonJavaScript?: boolean;
  isBsonJavaScriptWithScope?: boolean;
  isBsonMaxKey?: boolean;
  isBsonMinKey?: boolean;
  isBsonNull?: boolean;
  isBsonRegularExpression?: boolean;
  isBsonSymbol?: boolean;
  isBsonTimestamp?: boolean;
  isBsonUndefined?: boolean;
  /** @deprecated */
  isDateTime?: boolean;
  isDecimal128?: boolean;
  isDouble?: boolean;
  isGuid?: boolean;
  isInt32?: boolean;
  isInt64?: boolean;
  isNumeric?: boolean;
  isObjectId?: boolean;
  isString?: boolean;
  isValidDateTime?: boolean;
  /** @deprecated */
  rawValue?: any;
}

export interface Bubble {
  /** @format int64 */
  id?: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  metadata?: Record<string, any>;
}

export interface ClaimCallbackModel {
  claimId?: string | null;
  /** @format int32 */
  externalClaimId?: number;
  externalTransactionRef?: string | null;
  status?: ClaimStatus;
}

export interface ClaimModel {
  cryptoCurrency?: string | null;
}

/** @format int32 */
export enum ClaimStatus {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface ClickData {
  /** @format date-time */
  timestamp?: string;
  /** @format double */
  clientX?: number;
  /** @format double */
  clientY?: number;
  /** @format double */
  screenX?: number;
  /** @format double */
  screenY?: number;
  /** @format double */
  pageX?: number;
  /** @format double */
  pageY?: number;
}

export interface CoinsCollectedPageModel {
  /** @minLength 1 */
  startDate: string;
  /** @minLength 1 */
  endDate: string;
}

export interface CompactJsonOffer {
  id?: string | null;
  logoUrl?: string | null;
  profileName?: string | null;
  /** @format int32 */
  cost?: number;
  tags?: string | null;
  preferredBackgroundColor?: RgbColor;
  coverUrl?: string | null;
  /** @format double */
  cryptoCurrencyAmount?: number | null;
}

export interface ConcurrentPlayersDataPoint {
  /** @format date-time */
  timestamp?: string;
  /** @format int32 */
  playerCount?: number;
}

export interface ConcurrentPlayersStatsResult {
  dataPoints?: ConcurrentPlayersDataPoint[] | null;
  /** @format int32 */
  maxConcurrentPlayers?: number;
  /** @format int32 */
  minConcurrentPlayers?: number;
  /** @format double */
  averageConcurrentPlayers?: number;
}

export interface ConsolidateCryptoCurrencyModel {
  cryptoCurrency?: string | null;
  /** @format double */
  amount?: number;
  excludeOfferIds?: string[] | null;
}

export interface CreateAuctionListingModel {
  acquiredOfferId?: string | null;
  consolidatedTransactionFeeAcquiredOfferId?: string | null;
  /** @format double */
  startingBid?: number;
  /** @format double */
  bidIncrement?: number;
  /** @format date-time */
  auctionEndDate?: string;
  /** @format double */
  reservePrice?: number | null;
  isFeatured?: boolean;
}

export interface CreateGroupFromEventIdsRequest {
  groupName?: string | null;
  groupColor?: RgbColor;
  eventIds?: string[] | null;
}

export interface CreateItemDto {
  itemOwnerType: ItemOwnerType;
  type: ItemType;
  compatibility: ItemCompatibility;
  /**
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description: string;
  rarity: ItemRarity;
  /**
   * @format int32
   * @min 0
   * @max 2147483647
   */
  index: number;
  onlyOrExcept: ItemCompactDto[];
}

export interface CreateMarketListingModel {
  acquiredOfferId?: string | null;
  consolidatedTransactionFeeAcquiredOfferId?: string | null;
  /** @format int32 */
  coinPrice?: number;
  /** @format date-time */
  expiryDate?: string;
  isFeatured?: boolean;
}

export interface DailyMarketListingStats {
  /** @format int32 */
  created?: number;
  /** @format int32 */
  purchased?: number;
  /** @format int32 */
  removed?: number;
}

export interface Data {
  custom?: string | null;
}

export type Decimal128 = object;

export interface EndpointPerformance {
  path?: string | null;
  method?: string | null;
  /** @format double */
  averageResponseTime?: number;
  /** @format int32 */
  totalRequests?: number;
  /** @format double */
  errorRate?: number;
}

export interface ErrorModel {
  message?: string | null;
}

export interface Event {
  id?: string | null;
  identifier?: string | null;
  profileId?: string | null;
  title?: string | null;
  summary?: string | null;
  description?: string | null;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  position?: PositionModel;
  /** @format double */
  range?: number;
  /** @format double */
  relevanceRange?: number;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  cloudStorageFolderPath?: string | null;
  tags?: string | null;
  color?: RgbColor;
  aboutToStartAnnounced?: boolean;
  startedAnnounced?: boolean;
  endedAnnounced?: boolean;
  aboutToEndAnnounced?: boolean;
  feePaidUsers?: string[] | null;
}

export interface EventGroup {
  id?: string | null;
  name?: string | null;
  color?: RgbColor;
  eventIds?: string[] | null;
  rules?: Record<string, string | null>;
}

export interface FirstFactor {
  strategy?: string | null;
  channel?: string | null;
}

export interface GeoJson2DGeographicCoordinates {
  values?: number[] | null;
  /** @format double */
  longitude?: number;
  /** @format double */
  latitude?: number;
}

export interface GeoJson2DGeographicCoordinatesGeoJsonBoundingBox {
  max?: GeoJson2DGeographicCoordinates;
  min?: GeoJson2DGeographicCoordinates;
}

export interface GeoJson2DGeographicCoordinatesGeoJsonPoint {
  boundingBox?: GeoJson2DGeographicCoordinatesGeoJsonBoundingBox;
  coordinateReferenceSystem?: GeoJsonCoordinateReferenceSystem;
  extraMembers?: BsonElement[] | null;
  coordinates?: GeoJson2DGeographicCoordinates;
  type?: GeoJsonObjectType;
}

export interface GeoJsonCoordinateReferenceSystem {
  type?: string | null;
}

/** @format int32 */
export enum GeoJsonObjectType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
}

export interface GeocodeResponse {
  /** @format int32 */
  placeID?: number;
  license?: string | null;
  osmType?: string | null;
  /** @format int64 */
  osmid?: number;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  displayName?: string | null;
  extraTags?: Record<string, string | null>;
  alternateNames?: Record<string, string | null>;
  address?: AddressResult;
  class?: string | null;
  /** @format double */
  importance?: number;
  iconURL?: string | null;
  category?: string | null;
  classType?: string | null;
  addresstype?: string | null;
  placeRank?: string | null;
  boundingBox?: BoundingBox;
  geoJSON?: any;
  geoKML?: string | null;
  geoSVG?: string | null;
  geoText?: string | null;
}

/** @format int32 */
export enum GuidRepresentation {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface HourlyMetric {
  /** @format int32 */
  hour?: number;
  /** @format int32 */
  requestCount?: number;
  /** @format double */
  averageResponseTime?: number;
}

export type IContractResolver = object;

export interface InAppCodeRequest {
  phoneNumber?: string | null;
}

export interface InAppCodeVerificationRequest {
  phoneNumber?: string | null;
  code?: string | null;
}

export interface IpLookup {
  ip?: string | null;
  success?: boolean;
  message?: string | null;
  /** @format int32 */
  fraud_score?: number;
  country_code?: string | null;
  region?: string | null;
  city?: string | null;
  ISP?: string | null;
  /** @format int32 */
  ASN?: number;
  organization?: string | null;
  is_crawler?: boolean;
  timezone?: string | null;
  mobile?: boolean;
  host?: string | null;
  proxy?: boolean;
  vpn?: boolean;
  tor?: boolean;
  active_vpn?: boolean;
  active_tor?: boolean;
  recent_abuse?: boolean;
  bot_status?: boolean;
  connection_type?: string | null;
  abuse_velocity?: string | null;
  zip_code?: string | null;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  request_id?: string | null;
}

export interface Item {
  id?: string | null;
  imagePath?: string | null;
  itemOwnerType?: ItemOwnerType;
  type?: ItemType;
  compatibility?: ItemCompatibility;
  name?: string | null;
  description?: string | null;
  rarity?: ItemRarity;
  /** @format int32 */
  index?: number;
  onlyOrExcept?: ItemCompact[] | null;
}

export interface ItemCompact {
  type?: ItemType;
  /** @format int32 */
  index?: number;
}

export interface ItemCompactDto {
  type: ItemType;
  /** @format int32 */
  index: number;
}

/** @format int32 */
export enum ItemCompatibility {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface ItemJsonPatchDocument {
  operations?: ItemOperation[] | null;
  contractResolver?: IContractResolver;
}

export interface ItemOperation {
  operationType?: OperationType;
  path?: string | null;
  op?: string | null;
  from?: string | null;
  value?: any;
}

/** @format int32 */
export enum ItemOwnerType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export enum ItemRarity {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

/** @format int32 */
export enum ItemType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
  Value19 = 19,
  Value20 = 20,
  Value21 = 21,
  Value22 = 22,
  Value23 = 23,
  Value24 = 24,
}

export interface LocalMarketListingSearchOptions {
  includeRemoved?: boolean;
  includeExpired?: boolean;
  includePurchased?: boolean;
}

export interface MakeAcquiredOffer {
  offerId?: string | null;
  acquiredOfferId?: string | null;
  offerType?: string | null;
  offerTitle?: string | null;
  offerDescription?: string | null;
  /** @format int32 */
  offerCost?: number;
  offerRarity?: string | null;
  /** @format date-time */
  acquiredAtDateUtc?: string;
  /** @format date-time */
  expiryDateUtc?: string;
  coverUrl?: string | null;
  isSeen?: boolean;
  isUsed?: boolean;
  companyProfile?: MakeProfile;
  categories?: string[] | null;
  marketListingId?: string | null;
  cryptoCurrency?: string | null;
  /** @format double */
  cryptoCurrencyAmount?: number | null;
  vaultOfferType?: VaultOfferType;
  activationCost?: OfferActivationCost;
}

export interface MakeAcquiredOfferPaginatedResponse {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalItems?: number;
  items?: MakeAcquiredOffer[] | null;
}

export interface MakeBid {
  id?: string | null;
  userId?: string | null;
  username?: string | null;
  /** @format double */
  amount?: number;
  /** @format date-time */
  bidTime?: string;
}

export interface MakeMarketListing {
  id?: string | null;
  userId?: string | null;
  username?: string | null;
  acquiredOffer?: MakeAcquiredOffer;
  consolidatedTransactionFeeAcquiredOffer?: MakeAcquiredOffer;
  offerId?: string | null;
  categories?: string[] | null;
  /** @format int32 */
  priceStepcoins?: number | null;
  /** @format int32 */
  priceGems?: number | null;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  listingExpiresAt?: string;
  offerType?: string | null;
  profileType?: string | null;
  title?: string | null;
  description?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  isExpired?: boolean;
  isRemoved?: boolean;
  isPurchased?: boolean;
  isFeatured?: boolean;
  purchasedByUserId?: string | null;
  purchasedByUserUsername?: string | null;
  /** @format date-time */
  purchasedAt?: string | null;
  /** @format date-time */
  removedAt?: string | null;
  /** @format double */
  quotePriceUsd?: number | null;
  cryptoCurrency?: string | null;
  /** @format double */
  cryptoCurrencyAmount?: number | null;
  listingType?: MarketListingType;
  /** @format double */
  startingBid?: number | null;
  /** @format double */
  currentBid?: number | null;
  currentBidderUserId?: string | null;
  currentBidderUsername?: string | null;
  hasReservePrice?: boolean;
  /** @format double */
  reservePrice?: number | null;
  /** @format double */
  bidIncrement?: number | null;
  bidHistory?: MakeBid[] | null;
}

export interface MakeProfile {
  profileId?: string | null;
  profileType?: string | null;
  profileParentId?: string | null;
  profileName?: string | null;
  profileDescription?: string | null;
  /** @format double */
  profileLatitude?: number | null;
  /** @format double */
  profileLongitude?: number | null;
  profileLogoUrl?: string | null;
  profilePreferredBackgroundColor?: RgbColor;
  profileOffers?: CompactJsonOffer[] | null;
  profileWebsite?: string | null;
}

/** @format int32 */
export enum MarketListingSearchIn {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface MarketListingSearchModel {
  search?: string | null;
  searchIn?: MarketListingSearchIn;
  profileType?: MarketProfileType;
  type?: MarketListingTypeFilter;
  sort?: MarketListingSort;
  /** @format int32 */
  page?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  localMarketListingSearchOptions?: LocalMarketListingSearchOptions;
}

/** @format int32 */
export enum MarketListingSort {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
}

export interface MarketListingStatsResult {
  dailyStats?: Record<string, DailyMarketListingStats>;
}

/** @format int32 */
export enum MarketListingType {
  Value0 = 0,
  Value1 = 1,
}

/** @format int32 */
export enum MarketListingTypeFilter {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface MarketListingsResponse {
  listings?: MakeMarketListing[] | null;
  hasNextPage?: boolean;
}

/** @format int32 */
export enum MarketProfileType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface Meta {
  /** @format int32 */
  count?: number;
  /** @format int32 */
  totalCount?: number;
  /** @format int32 */
  totalPages?: number;
}

export interface MetricsVisualizationData {
  timeSeriesData?: TimeSeriesMetric[] | null;
  endpointPerformance?: EndpointPerformance[] | null;
  requestTypeDistribution?: RequestTypeMetric[] | null;
  responseCodeDistribution?: ResponseCodeMetric[] | null;
  hourlyDistribution?: HourlyMetric[] | null;
}

export interface NamePetModel {
  name?: string | null;
}

export interface ObjectId {
  /** @format int32 */
  timestamp?: number;
  /**
   * @deprecated
   * @format int32
   */
  machine?: number;
  /**
   * @deprecated
   * @format int32
   */
  pid?: number;
  /**
   * @deprecated
   * @format int32
   */
  increment?: number;
  /** @format date-time */
  creationTime?: string;
}

export interface Offer {
  id?: string | null;
  profileId?: string | null;
  name?: string | null;
  description?: string | null;
  accepted?: boolean;
  vaultOfferType?: VaultOfferType;
  offerType?: string | null;
  /** @format date-time */
  validFromDate?: string;
  /** @format date-time */
  validUntilDate?: string;
  /** @format int32 */
  cost?: number;
  /** @format int32 */
  amount?: number;
  /** @format int32 */
  originalAmount?: number;
  /** @format int32 */
  individualTimeSpan?: number;
  individualTimeSpanUnit?: string | null;
  /** @format int32 */
  individualAmount?: number;
  cloudStorageFolderPath?: string | null;
  redeemables?: Record<string, boolean>;
  tags?: string | null;
  /** @format double */
  cryptoCurrencyAmount?: number | null;
  cryptoCurrency?: string | null;
  attachedItemIds?: string[] | null;
  attachedOfferIds?: string[] | null;
  activationCost?: OfferActivationCost;
  weightTable?: OfferWeightTable;
}

export interface OfferActivationCost {
  cryptoCurrency?: string | null;
  /** @format double */
  cryptoCurrencyAmount?: number;
}

export interface OfferWeightTable {
  entries?: OfferWeightTableEntry[] | null;
}

export interface OfferWeightTableEntry {
  rarity?: ItemRarity;
  /** @format double */
  weight?: number;
}

/** @format int32 */
export enum OperationType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface PhoneNumberModel {
  phoneNumber?: string | null;
}

export interface PhoneNumbersModel {
  phoneNumbers?: string[] | null;
}

export interface PlaceBidModel {
  marketListingId?: string | null;
  /** @format double */
  bidAmount?: number;
}

export interface PositionModel {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  position?: GeoJson2DGeographicCoordinatesGeoJsonPoint;
  /** @format date-time */
  timeStamp?: string;
}

export interface Profile {
  id?: string | null;
  parentProfileId?: string | null;
  type?: string | null;
  name?: string | null;
  description?: string | null;
  address?: string | null;
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
  position?: PositionModel;
  /** @format double */
  range?: number | null;
  countryCode?: string | null;
  geocodeResponse?: GeocodeResponse;
  cloudStorageFolderPath?: string | null;
  colorProfile?: RgbColor;
  preferredBackgroundColor?: RgbColor;
  tags?: string | null;
  website?: string | null;
}

export interface ProfileResponse {
  profile?: Profile;
  children?: Profile[] | null;
  parent?: Profile;
  tree?: Record<string, Profile[] | null>;
  patchRequests?: ProfileUpdatePatchRequest[] | null;
}

export interface ProfileUpdatePatchRequest {
  profileId?: string | null;
  profileName?: string | null;
  profileAddress?: string | null;
  countryCode?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  range?: string | null;
  description?: string | null;
  colorProfile?: string | null;
  preferredBackgroundColor?: string | null;
  tags?: string | null;
  website?: string | null;
  id?: string | null;
  createdBy?: string | null;
  handledBy?: string | null;
  /** @format binary */
  profileLogo?: File | null;
  profileLogoCloudStorageFilePath?: string | null;
  profileLogoUrl?: string | null;
  /** @format date-time */
  createdAtUtc?: string;
  accepted?: boolean;
  rejected?: boolean;
  rejectionReason?: string | null;
}

export interface ProfileWithChildrenIdsResponse {
  profile?: Profile;
  childrenIds?: string[] | null;
}

export interface PurchaseOfferModel {
  offerId?: string | null;
  userAgent?: string | null;
  /** @format int32 */
  innerScreenHeight?: number;
  /** @format int32 */
  innerScreenWidth?: number;
}

export interface ReferralNotValidatedReason {
  minimumLevelNotReached?: boolean;
  minimumMinesNotReached?: boolean;
  maximumIdenticalIpAddressesReached?: boolean;
  /** @format int32 */
  currentLevel?: number;
  /** @format int64 */
  currentMines?: number;
  /** @format int32 */
  identicalIpAddressesCount?: number | null;
  /** @format int32 */
  requiredLevel?: number;
  /** @format int32 */
  requiredMines?: number;
  /** @format int32 */
  maximumIdenticalIpAddresses?: number;
}

export interface Regex {
  options?: RegexOptions;
  rightToLeft?: boolean;
  /** @format date-span */
  matchTimeout?: string;
}

/** @format int32 */
export enum RegexOptions {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value4 = 4,
  Value8 = 8,
  Value16 = 16,
  Value32 = 32,
  Value64 = 64,
  Value256 = 256,
  Value512 = 512,
  Value1024 = 1024,
}

export interface RegisterMineClicksModel {
  acquiredOfferId?: string | null;
  clicks?: ClickData[] | null;
}

export interface RegisterTokenRequest {
  token?: string | null;
}

export interface RemoveEventFromGroupRequest {
  groupId?: string | null;
  eventId?: string | null;
}

export interface RequestTypeMetric {
  requestType?: string | null;
  /** @format int32 */
  count?: number;
  /** @format double */
  averageResponseTime?: number;
}

export interface ResponseCodeMetric {
  statusCodeCategory?: string | null;
  /** @format int32 */
  count?: number;
  /** @format double */
  percentage?: number;
}

export interface RevalidateReferralRequest {
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
}

export interface RgbColor {
  /** @format int32 */
  r?: number;
  /** @format int32 */
  g?: number;
  /** @format int32 */
  b?: number;
}

export interface SearchUserResult {
  results?: UserResult[] | null;
  meta?: Meta;
  /** @format int32 */
  totalCount?: number;
}

export interface SecondFactor {
  strategy?: string | null;
  channel?: string | null;
}

export interface SetGroupRulesRequest {
  groupId?: string | null;
  rules?: Record<string, string | null>;
}

export interface Skin {
  id?: string | null;
  imagePath?: string | null;
  isEditable?: boolean;
  bodyItemId?: string | null;
  axeItemId?: string | null;
  bagItemId?: string | null;
  bottomItemId?: string | null;
  braceletItemId?: string | null;
  earringItemId?: string | null;
  eyeItemId?: string | null;
  eyebrowItemId?: string | null;
  eyewearItemId?: string | null;
  gloveItemId?: string | null;
  hairItemId?: string | null;
  hairAccItemId?: string | null;
  handAccItemId?: string | null;
  headItemId?: string | null;
  headgearItemId?: string | null;
  lipsItemId?: string | null;
  maskItemId?: string | null;
  mustacheItemId?: string | null;
  shieldItemId?: string | null;
  shoesItemId?: string | null;
  spearItemId?: string | null;
  swordItemId?: string | null;
  topItemId?: string | null;
  watchItemId?: string | null;
}

export interface StatisticsModel {
  /** @format int64 */
  acquiredOffersPerMinute?: number;
}

export interface StatisticsQueryModel {
  queries?: string[] | null;
}

export interface StepcoinsValueDataPoint {
  date?: string | null;
  /** @format double */
  exchangeRate?: number;
  /** @format int32 */
  transactionCount?: number;
  /** @format double */
  totalStepcoinsVolume?: number;
  /** @format double */
  totalUsdVolume?: number;
}

export interface StepcoinsValueStatsResult {
  dataPoints?: StepcoinsValueDataPoint[] | null;
  /** @format double */
  averageExchangeRate?: number;
  /** @format double */
  highestExchangeRate?: number;
  /** @format double */
  lowestExchangeRate?: number;
  /** @format double */
  totalStepcoinsVolume?: number;
  /** @format double */
  totalUsdVolume?: number;
}

export interface StepsVerificationModel {
  /** @format int32 */
  stepsSinceBoot: number;
  /** @format date-time */
  lastBootUTC: string;
}

export interface SubscribeToMarketListingsModel {
  marketListingIds?: string[] | null;
}

export interface TelegramValidateDto {
  initData?: string | null;
  referrerTelegramId?: string | null;
}

export interface Tenant {
  tenantId?: string | null;
  name?: string | null;
}

export interface TimeSeriesMetric {
  /** @format date-time */
  date?: string;
  /** @format int32 */
  count?: number;
  /** @format double */
  averageResponseTime?: number;
  /** @format int32 */
  errorCount?: number;
}

export interface TransactionFeeDataPoint {
  currency?: string | null;
  /** @format double */
  amount?: number;
  /** @format double */
  usdValue?: number;
}

export interface TransactionFeesResult {
  dataPoints?: TransactionFeeDataPoint[] | null;
  /** @format double */
  totalFeesInUsd?: number;
}

export interface UpdateOfferActivationCostDto {
  activationCost?: OfferActivationCost;
}

export interface UpdateOfferWeightTabletDto {
  weightTable?: OfferWeightTable;
}

export interface UpdateSkinItemModel {
  itemId?: string | null;
}

export interface UserAgentInfo {
  browserName?: string | null;
  browserMajorVersion?: string | null;
  browserMinorVersion?: string | null;
  browserPatchVersion?: string | null;
  browserFullVersion?: string | null;
  operatingSystem?: string | null;
  osMajorVersion?: string | null;
  osMinorVersion?: string | null;
  osFullVersion?: string | null;
  deviceFamily?: string | null;
  deviceBrand?: string | null;
  deviceModel?: string | null;
  isSpider?: boolean;
  rawUserAgent?: string | null;
}

export interface UserResult {
  mode?: string | null;
  /** @format int32 */
  userId?: number;
  tenantId?: string | null;
  userUuid?: string | null;
  username?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  name?: string | null;
  image?: string | null;
  locked?: boolean;
  data?: Data;
  isConfirmed?: boolean;
  isMfaRequired?: boolean;
  /** @format date-time */
  lastActiveAt?: string | null;
  /** @format date-time */
  lastMessagedAt?: string | null;
  /** @format date-time */
  confirmedAt?: string | null;
  /** @format date-time */
  createdAt?: string | null;
  /** @format date-time */
  updatedAt?: string | null;
  tenant?: Tenant;
  authentication?: Authentication;
  authorization?: Record<string, Authorization>;
}

export interface UserSlim {
  id?: string | null;
  username?: string | null;
  phoneNumber?: string | null;
  /** @format int32 */
  coins?: number;
  /** @format int32 */
  gems?: number;
  acquiredOffers?: MakeAcquiredOffer[] | null;
  /** @format int32 */
  acquiredOffersCount?: number | null;
  banned?: boolean;
  banReason?: string | null;
  /** @format date-time */
  banUntil?: string | null;
  ipAddress?: string | null;
  referrerId?: string | null;
  /** @format date-time */
  referralDate?: string | null;
  referralNotValidatedReason?: ReferralNotValidatedReason;
}

export interface UserSlimPaginatedResponse {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int64 */
  totalItems?: number;
  items?: UserSlim[] | null;
}

/** @format int32 */
export enum VaultOfferType {
  Value0 = 0,
  Value1 = 1,
}

export interface WalletModel {
  walletType?: string | null;
  walletAddress?: string | null;
}
