import { useEffect, useRef, useState } from "react";
import ContainerHelper from "../../component_system/component_container/utilities/container_helper";
import useFetchComponent from "../../component_system/component_container/utilities/use_fetch_hook";
import Images from "../../component_system/components/preload/images";
import { Countdown } from "../components/countdown";
import UserAvatar from "../components/user_avatar";
import styles from './leaderboard_page.module.css';
import QuestComponent from "../../component_system/components/quest/quest_component";
import ToplistReferrals from "../../apis/models/task/toplist_referrals";
import formatNumber from "../../utils/currency_formatter";
import LeaderboardItem from "../components/leaderboard/leaderboard_item";

const userLocale = navigator.language || 'en-US';

// Specify formatting options
const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const LeaderboardPage = () => {

    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );
    const questComponentRef = useRef<QuestComponent | null>(null);
    const [toplistReferrals, setToplistReferrals] = useState<ToplistReferrals|null>(null);


    const printDates = (from: Date, to: Date) => {
        const formattedFromDate = new Intl.DateTimeFormat(userLocale, options).format(from);
        const formattedToDate = new Intl.DateTimeFormat(userLocale, options).format(to);
        return `Current weekly leaderboard From: ${formattedFromDate} To: ${formattedToDate}`;

    }
    
    useEffect(() => {
        const subscriber = () => {
            // Update tasks when notified
            if (questComponentRef.current) {
                setToplistReferrals( questComponentRef.current.toplistReferrals );
            
            }
        };

        const setup = async () => {
            questComponentRef.current =
                await ContainerHelper.getQuestComponent();
            // Subscribe to updates
            questComponentRef.current.addSubscriber(subscriber);
            // Set initial tasks
            setToplistReferrals(questComponentRef.current.toplistReferrals );
         
        };

        setup();

        // Cleanup on unmount
        return () => {
            if (questComponentRef.current) {
                questComponentRef.current.removeSubscriber(subscriber);
            }
        };
    }, []);
    
    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>

            <div className={styles.pageContent}>
                <div
                    className={styles.titleController}
                    onClick={() => navigationComponent?.navigateToIndex(3)}
                >
                    <div className={styles.backBtn}>
                        <img src={Images.Profile.BackButton} alt="" />
                    </div>
                    {/* <h2 className={styles.pageTitle}>Leaderboard</h2> */}
                </div>
                <div className={styles.profilePanel}>
                    <h3 className={styles.profileName}>
                        <img src={Images.Icons.Leaderboard} className={styles.leaderboardHeroImg} alt=""/>
                        <p>Winners selected in:</p>
                        {toplistReferrals && toplistReferrals.endDate &&
                            <Countdown date={toplistReferrals.endDate.getTime()} />
                        }                        
                    </h3>
                </div>
                <div className={styles.divider}></div>
                <p className={styles.label}>{`Current weekly leaderboard`}</p>  {/* {toplistReferrals && printDates(toplistReferrals?.startDate,toplistReferrals?.endDate)} */}

                {toplistReferrals?.topReferrers?.filter(player=>!player.notOnLeaderboard).map((player, index) => (
                    <LeaderboardItem key={index} placement={index+1} player={player} />
                ))}
                
                {toplistReferrals && toplistReferrals.topReferrers?.filter(player=>player.notOnLeaderboard).length > 0 &&
                    <>
                        <div className={styles.divider} style={{opacity:0.2}}></div>
                        <p className={styles.label}>{`You`}</p>
                        {toplistReferrals?.topReferrers?.filter(player=>player.notOnLeaderboard).map((player, index) => (
                                <LeaderboardItem key={index} placement={0} player={player} />
                        ))}
                    </>
                }                 

                {toplistReferrals && toplistReferrals.previousPeriodHistory && toplistReferrals.previousPeriodHistory.topPlayers.length > 0 &&
                    <>
                        <div className={styles.divider}></div>
                        <p className={styles.label}>{`Last Week's Winners`}</p>                        
                        
                        {toplistReferrals?.previousPeriodHistory?.topPlayers.map((player, index) => (
                             <LeaderboardItem key={index} placement={index+1} player={player} />
                        ))
                        }
                    </>
                }

                <div style={{ height: 120 }} />
            </div>
        </>
    );
};

export default LeaderboardPage;