import React from 'react';
import styles from '../../pages/profile_page.module.css';
import { openLink } from '@telegram-apps/sdk';
import { constants } from '../../../utils/constants';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

interface WalletConnectionDialogProps {
    session: {
        urlCode: string;
        randomVerificationCode: string;
    };
}

const MySwal = withReactContent(Swal);

const WalletConnectionDialog: React.FC<WalletConnectionDialogProps> = ({ session }) => {
    return (
        <div>
            <p className={styles.text}>Copy this verification code:</p>
            <div className={styles.verificationCode}>
                <input 
                    type="text" 
                    readOnly 
                    value={session.randomVerificationCode}
                />
                <button 
                    className={styles.copyButton}
                    onClick={() => {
                        navigator.clipboard.writeText(session.randomVerificationCode)
                            .then(() => {
                                MySwal.showValidationMessage('Code copied!');
                            })
                            .catch(() => {
                                MySwal.showValidationMessage('Failed to copy code');
                            });
                    }}
                >
                    Copy
                </button>
            </div>
            <button 
                className={styles.confirmButton}
                onClick={() => {
                    const link = constants.CONNECT_WALLET_URL_STRUCTURE.replace('%URL_CODE%', session.urlCode)
                    openLink(link, {
                        tryBrowser: 'chrome',
                        tryInstantView: false,
                    });
                }}
            >
                Open Externally
            </button>
        </div>
    );
};

export const showWalletConnectionDialog = async (session: {
    urlCode: string;
    randomVerificationCode: string;
}) => {
    return MySwal.fire({
        title: 'Connect Wallets',
        html: <WalletConnectionDialog session={session} />,
        showCancelButton: true,
        showConfirmButton: false,
        customClass: {
            popup: styles.container,
            title: styles.title,
            cancelButton: styles.cancelButton,
            container: styles.swalBackdrop,
        },
    });
};
