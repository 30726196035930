enum MarketListingSort {
    Value0 = 0, // Newest
    Value1 = 1, // Oldest
    Value2 = 2, // Price (Low to High)
    Value3 = 3, // Price (High to Low)
    Value4 = 4, // Expiring Soon
    Value5 = 5, // Recently Added
    Value6 = 6, // Distance (Near to Far)
    Value7 = 7, // Distance (Far to Near)
    Value8 = 8, // Relevance
    Value9 = 9  // Popularity
}

export default MarketListingSort;
