import React, { useRef, useEffect } from 'react';
import styles from './market_auction_item.module.css';
import { formatTimeAgo } from '../../../utils/time_formatter';
import formatNumber from '../../../utils/currency_formatter';
import { MakeBid } from '../../../generated/data-contracts';

interface BidHistoryPopupProps {
    isOpen: boolean;
    onClose: () => void;
    bidHistory: MakeBid[] | null | undefined;
}

const BidHistoryPopup: React.FC<BidHistoryPopupProps> = ({
    isOpen,
    onClose,
    bidHistory,
}) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                contentRef.current &&
                !contentRef.current.contains(event.target as Node)
            ) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className={styles.bidHistoryPopup}>
            <div className={styles.bidHistoryContent} ref={contentRef}>
                <div className={styles.bidHistoryHeader}>
                    <div className={styles.bidHistoryTitle}>Bid History</div>
                    <button
                        className={styles.bidHistoryCloseButton}
                        onClick={onClose}
                    >
                        ×
                    </button>
                </div>
                <div className={styles.bidHistoryList}>
                    {bidHistory && bidHistory.length > 0 ? (
                        bidHistory
                            .concat()
                            .sort(
                                (a, b) =>
                                    new Date(b.bidTime!).getTime() -
                                    new Date(a.bidTime!).getTime()
                            )
                            .map((bid, index) => (
                                <div
                                    key={index}
                                    className={styles.bidHistoryItem}
                                >
                                    <div className={styles.bidHistoryUserInfo}>
                                        <span
                                            className={
                                                styles.bidHistoryUsername
                                            }
                                        >
                                            {bid.username || 'Unknown'}
                                        </span>
                                        <span
                                            className={styles.bidHistoryAmount}
                                        >
                                            {formatNumber(bid.amount || 0)} DIG
                                        </span>
                                    </div>
                                    <div className={styles.bidHistoryTime}>
                                        {formatTimeAgo(bid.bidTime)}
                                    </div>
                                </div>
                            ))
                    ) : (
                        <div className={styles.noBids}>No bids yet</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BidHistoryPopup;
