import React, { memo } from 'react';
import styles from '../../pages/profile_page.module.css';
import Images from '../../../component_system/components/preload/images';

const AchievementsSection = memo(() => {
    return (
        <div className={styles.achievements}>
            <p className={styles.label}>Achievements</p>
            <div
                className={styles.achievement}
                style={{
                    backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                }}
            ></div>
            <div
                className={`${styles.achievement} ${styles.middle}`}
                style={{
                    backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                }}
            ></div>
            <div
                className={styles.achievement}
                style={{
                    backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                }}
            ></div>
            <div
                className={styles.achievement}
                style={{
                    backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                }}
            ></div>
            <div
                className={`${styles.achievement} ${styles.middle}`}
                style={{
                    backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                }}
            ></div>
            <div
                className={styles.achievement}
                style={{
                    backgroundImage: `url(${Images.Profile.AchievementBanner})`,
                }}
            ></div>
        </div>
    );
});

AchievementsSection.displayName = 'AchievementsSection';

export default AchievementsSection;
