import React, { useCallback, useEffect, useState, useMemo, memo } from 'react';
import styles from './market_auction_item.module.css';
import { MakeMarketListing } from '../../../generated/data-contracts';
import formatNumber, {
    formatTokenValue,
} from '../../../utils/currency_formatter';
import { formatTimeAgo } from '../../../utils/time_formatter';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import Decimal from 'decimal.js';
import useObservable from '../../../utils/observable/use_observable_hook';
import ValueContainer from '../../../utils/value_container';

interface MarketAuctionItemProps {
    listing: MakeMarketListing;
    isOwner: boolean;
    onPurchase: () => void; // Used for placing a bid in this context
    onRemove: () => void;
    onShowBidHistory?: (listing: MakeMarketListing) => void;
}

// Extracted countdown renderer component with proper typing
const CountdownRenderer = memo(
    ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
        if (completed) {
            return <span>Auction Ended</span>;
        }

        // Determine the countdown style based on remaining time
        let countdownClass = styles.countdownNormal;

        // Less than 12 hours but more than 1 hour = warning
        if (days === 0 && hours < 12 && hours > 1) {
            countdownClass = styles.countdownWarning;
        }
        // Less than 1 hour = critical
        else if (days === 0 && hours < 1) {
            countdownClass = styles.countdownCritical;
        }

        return (
            <span className={countdownClass}>
                {days > 0 ? `${days}d ` : ''}
                {hours > 0 ? `${hours}h ` : ''}
                {minutes > 0 ? `${minutes}m ` : ''}
                {seconds}s
            </span>
        );
    }
);

// Extracted bid history button component
const BidHistoryButton = memo(
    ({
        bidHistoryLength,
        onShowBidHistory,
        listing,
    }: {
        bidHistoryLength: number;
        onShowBidHistory?: (listing: MakeMarketListing) => void;
        listing: MakeMarketListing;
    }) => {
        if (!bidHistoryLength) return null;

        return (
            <button
                className={styles.bidHistoryButton}
                onClick={() => onShowBidHistory && onShowBidHistory(listing)}
            >
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12 8V12L15 15"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <circle
                        cx="12"
                        cy="12"
                        r="9"
                        stroke="white"
                        strokeWidth="2"
                    />
                </svg>
                Bid History ({bidHistoryLength})
            </button>
        );
    }
);

const MarketAuctionItem: React.FC<MarketAuctionItemProps> = ({
    listing,
    isOwner,
    onPurchase,
    onRemove,
    onShowBidHistory,
}) => {
    const username = useObservable(ValueContainer.usernameObservable);

    // Initialize bid amount with the minimum valid bid
    const initialBidAmount = useMemo(
        () =>
            listing.currentBid
                ? listing.currentBid + (listing.bidIncrement || 1)
                : listing.startingBid || 0,
        [listing.currentBid, listing.bidIncrement, listing.startingBid]
    );

    const [bidAmount, setBidAmount] = useState<number>(initialBidAmount);

    // Memoize values that don't change with every render
    const isAvailable = useMemo(
        () => !listing.isExpired && !listing.isRemoved && !listing.isPurchased,
        [listing.isExpired, listing.isRemoved, listing.isPurchased]
    );

    const formatDate = useCallback((dateString?: string | null) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return (
            date.toLocaleDateString() +
            ' ' +
            date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        );
    }, []);

    const statusInfo = useMemo(() => {
        let text = 'Auction';
        let className = styles.available;

        if (listing.isPurchased) {
            text = 'Ended';
            className = styles.purchased;
        } else if (listing.isRemoved) {
            text = 'Removed';
            className = styles.removed;
        } else if (listing.isExpired) {
            text = 'Expired';
            className = styles.expired;
        }

        return { text, className };
    }, [listing.isPurchased, listing.isRemoved, listing.isExpired]);

    // Check if this is a crypto currency listing
    const isCryptoListing = useMemo(
        () => listing.acquiredOffer?.vaultOfferType === 0, // VaultOfferType.Value0
        [listing.acquiredOffer?.vaultOfferType]
    );

    // Memoize content values
    const title = useMemo(() => {
        if (isCryptoListing) {
            return listing.cryptoCurrency || 'Crypto';
        }
        return (
            listing.title ||
            listing.acquiredOffer?.offerTitle ||
            'Unnamed Auction'
        );
    }, [isCryptoListing, listing.title, listing.acquiredOffer]);

    const description = useMemo(() => {
        if (isCryptoListing) {
            return 'Cryptocurrency Auction';
        }
        return (
            listing.description ||
            listing.acquiredOffer?.offerDescription ||
            'No description available.'
        );
    }, [isCryptoListing, listing.description, listing.acquiredOffer]);

    const cryptoAmount = useMemo(() => {
        const amount = listing.cryptoCurrencyAmount;
        return amount ? `${formatNumber(amount)}` : '0';
    }, [listing.cryptoCurrencyAmount]);

    const usdValue = useMemo(() => {
        if (listing.quotePriceUsd && listing.cryptoCurrencyAmount) {
            return formatTokenValue(
                new Decimal(listing.quotePriceUsd),
                listing.cryptoCurrencyAmount
            );
        }
        return '';
    }, [listing.quotePriceUsd, listing.cryptoCurrencyAmount]);

    const handleBidAmountChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(e.target.value);
            if (!isNaN(value)) {
                setBidAmount(value);
            }
        },
        []
    );

    const getMinimumBid = useCallback(() => {
        if (listing.currentBid) {
            return listing.currentBid + (listing.bidIncrement || 1);
        }
        return listing.startingBid || 0;
    }, [listing.bidIncrement, listing.currentBid, listing.startingBid]);

    const isValidBid = useMemo(
        () =>
            bidAmount >= getMinimumBid() &&
            listing.username !== username &&
            listing.currentBidderUsername !== username,
        [
            bidAmount,
            getMinimumBid,
            listing.username,
            listing.currentBidderUsername,
            username,
        ]
    );

    // Handle bid increment/decrement
    const incrementBid = useCallback(
        () => setBidAmount((prev) => prev + (listing.bidIncrement || 1)),
        [listing.bidIncrement]
    );

    const decrementBid = useCallback(() => {
        const minBid = getMinimumBid();
        const newValue = bidAmount - (listing.bidIncrement || 1);
        setBidAmount(newValue < minBid ? minBid : newValue);
    }, [bidAmount, getMinimumBid, listing.bidIncrement]);

    useEffect(() => {
        // If a new bid is placed, reset the bid amount to the minimum
        const minBid = getMinimumBid();
        if (bidAmount < minBid) {
            setBidAmount(minBid);
        }
    }, [bidAmount, getMinimumBid, listing]);

    return (
        <div
            className={`${styles.auctionCard} ${listing.isFeatured ? styles.featured : ''}`}
        >
            {listing.isFeatured && (
                <div className={styles.featuredTag}>Featured</div>
            )}
            <div className={styles.auctionHeader}>
                <div className={styles.auctionTitle}>{title}</div>
                <div
                    className={`${styles.auctionStatus} ${statusInfo.className}`}
                >
                    {statusInfo.text}
                </div>
            </div>

            <div className={styles.auctionContent}>
                <div className={styles.auctionImageSection}>
                    <div className={styles.auctionImage}>
                        <img
                            src={
                                listing.acquiredOffer?.coverUrl ||
                                'https://via.placeholder.com/100'
                            }
                            alt={title}
                            loading="lazy"
                        />
                    </div>
                    <BidHistoryButton
                        bidHistoryLength={listing.bidHistory?.length || 0}
                        onShowBidHistory={onShowBidHistory}
                        listing={listing}
                    />
                </div>

                <div className={styles.auctionDetails}>
                    {isCryptoListing ? (
                        <div className={styles.tokenDetails}>
                            <div className={styles.tokenTitle}>{title}</div>
                            <div className={styles.valueDisplay}>
                                <p>
                                    {cryptoAmount}
                                    {listing.quotePriceUsd &&
                                        listing.cryptoCurrencyAmount && (
                                            <span> {usdValue}</span>
                                        )}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <p className={styles.auctionDescription}>
                            {description}
                        </p>
                    )}

                    <div className={styles.auctionInfo}>
                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>Seller:</span>
                            <span className={styles.infoValue}>
                                {listing.username || 'Unknown'}
                            </span>
                        </div>

                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>
                                Starting Bid:
                            </span>
                            <span className={styles.infoValue}>
                                {formatNumber(listing.startingBid || 0)} DIG
                            </span>
                        </div>

                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>
                                Current Bid:
                            </span>
                            <span className={styles.infoValue}>
                                {listing.currentBid
                                    ? `${formatNumber(listing.currentBid)} DIG by ${
                                          listing.currentBidderUsername ||
                                          'Unknown'
                                      }`
                                    : 'No bids yet'}
                            </span>
                        </div>

                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>
                                Bid Increment:
                            </span>
                            <span className={styles.infoValue}>
                                {formatNumber(listing.bidIncrement || 1)} DIG
                            </span>
                        </div>

                        {listing.hasReservePrice && (
                            <div className={styles.infoItem}>
                                <span className={styles.infoLabel}>
                                    Reserve Price:
                                </span>
                                <span className={styles.infoValue}>
                                    {isOwner
                                        ? `${formatNumber(
                                              listing.reservePrice || 0
                                          )} DIG`
                                        : 'Has reserve price'}
                                </span>
                            </div>
                        )}

                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>Listed:</span>
                            <span className={styles.infoValue}>
                                {isOwner
                                    ? formatDate(listing.createdAt)
                                    : formatTimeAgo(listing.createdAt)}
                            </span>
                        </div>

                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>
                                Auction Ends:
                            </span>
                            <span className={styles.infoValue}>
                                {isOwner ? (
                                    formatDate(listing.listingExpiresAt)
                                ) : listing.listingExpiresAt ? (
                                    <Countdown
                                        date={
                                            new Date(listing.listingExpiresAt)
                                        }
                                        renderer={(props) => (
                                            <CountdownRenderer {...props} />
                                        )}
                                    />
                                ) : (
                                    'N/A'
                                )}
                            </span>
                        </div>

                        {listing.isPurchased && (
                            <div className={styles.infoItem}>
                                <span className={styles.infoLabel}>
                                    Auction Ended:
                                </span>
                                <span className={styles.infoValue}>
                                    {formatDate(listing.purchasedAt)}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className={styles.auctionActions}>
                {isOwner ? (
                    <button
                        className={styles.removeButton}
                        onClick={onRemove}
                        disabled={!isAvailable}
                    >
                        Remove Auction
                    </button>
                ) : (
                    isAvailable && (
                        <>
                            <div className={styles.bidInputContainer}>
                                <div className={styles.bidInput}>
                                    <input
                                        type="number"
                                        value={bidAmount}
                                        onChange={handleBidAmountChange}
                                        min={getMinimumBid()}
                                        step={listing.bidIncrement || 1}
                                        className={styles.bidInputField}
                                    />
                                    <div className={styles.bidControls}>
                                        <button
                                            onClick={incrementBid}
                                            className={styles.bidIncrement}
                                        >
                                            +
                                        </button>
                                        <button
                                            onClick={decrementBid}
                                            className={styles.bidDecrement}
                                        >
                                            -
                                        </button>
                                    </div>
                                </div>
                                <button
                                    className={styles.bidButton}
                                    onClick={onPurchase}
                                    disabled={!isValidBid}
                                >
                                    Place Bid {formatNumber(bidAmount)} DIG
                                </button>
                            </div>
                        </>
                    )
                )}
            </div>
        </div>
    );
};

export default memo(MarketAuctionItem);
