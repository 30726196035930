import React, { useCallback } from 'react';
import StatsSection from '../components/profile/StatsSection';
import AchievementsSection from '../components/profile/AchievementsSection';
import ProfileHeader from '../components/profile/ProfileHeader';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import WalletSection from '../components/profile/WalletSection';
import ValueContainer from '../../utils/value_container';
import styles from './profile_page.module.css';
import Images from '../../component_system/components/preload/images';
import { showWalletConnectionDialog } from '../components/wallet/wallet_connection_dialog';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import useObservable from '../../utils/observable/use_observable_hook';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import WalletType from '../../apis/models/profile/wallet_type';
import StandardHttpResponse from '../../apis/models/standard_http_response';
import ProfileApi from '../../apis/profile_api/profile_api';
import { init } from '@telegram-apps/sdk';

const ProfilePage = () => {
    init();
    const currentLevel = useObservable(ValueContainer.currentLevelObservable);
    const currentLevelExperience = useObservable(
        ValueContainer.currentLevelExperienceObservable
    );
    const experienceUntilNextLevel = useObservable(
        ValueContainer.experienceUntilNextLevelObservable
    );
    const characterImageBase64 = useObservable(
        ValueContainer.characterImageBase64Observable
    );
    const totalSteps = useObservable(ValueContainer.totalStepsObservable);
    const totalPurchasedOffers = useObservable(
        ValueContainer.totalPurchasedOffersObservable
    );
    const username = useObservable(ValueContainer.usernameObservable);
    const totalFriendsCount = useObservable(
        ValueContainer.totalFriendsCountObservable
    );

    const evmWalletAddress = useObservable(ValueContainer.evmWalletObservable);
    const solanaWalletAddress = useObservable(
        ValueContainer.solanaWalletObservable
    );
    const tonWalletAddress = useObservable(ValueContainer.tonWalletObservable);

    const navigationComponent = useFetchComponent(
        ContainerHelper.getNavigationComponent
    );

    const authComponent = useFetchComponent(
        ContainerHelper.getAuthenticationComponent
    );

    const experienceGained =
        (currentLevelExperience ?? 1) - (experienceUntilNextLevel ?? 1);

    const MySwal = withReactContent(Swal);

    const handleConnectWalletsClick = useCallback(async () => {
        try {
            const session = await ProfileApi.createConnectWalletSession();
            await showWalletConnectionDialog(session);
        } catch (error) {
            void MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to create wallet connection session',
                customClass: {
                    popup: styles.container,
                    title: styles.title,
                    confirmButton: styles.confirmButton,
                    container: styles.swalBackdrop,
                },
            });
        }
    }, [MySwal]);

    const openWalletPopup = useCallback(
        (walletType: WalletType, walletAddress: string | undefined): void => {
            const walletRegex = {
                Solana: /^[1-9A-HJ-NP-Za-km-z]{32,44}$/, // Solana public key format
                EVM: /^0x[a-fA-F0-9]{40}$/, // Ethereum-style addresses
                TON: /^[a-zA-Z0-9_-]{48,64}$/, // TON wallet format (base64/URL-safe)
            };

            // Define getRegex inside the callback
            const getRegex = (walletType: WalletType) => {
                switch (walletType) {
                    case WalletType.Solana:
                        return walletRegex.Solana;
                    case WalletType.EVM:
                        return walletRegex.EVM;
                    case WalletType.TON:
                        return walletRegex.TON;
                    default:
                        return null;
                }
            };

            // Define saveWalletAddress inside the callback
            const saveWalletAddress = async (
                walletType: WalletType,
                walletAddress: string
            ) => {
                try {
                    await authComponent?.updateWallet(
                        walletType,
                        walletAddress
                    );
                    return { success: true, message: 'Wallet saved' };
                } catch (error) {
                    const errorData = error as StandardHttpResponse;
                    if (errorData && errorData.hasErrorMessage) {
                        return {
                            success: false,
                            message: errorData.errorMessage,
                        };
                    }
                    return {
                        success: false,
                        message: (error as Error).message,
                    };
                }
            };

            MySwal.fire({
                title: `Enter your ${walletType} wallet`,
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off',
                },
                inputValue: walletAddress,
                showCancelButton: true,
                confirmButtonText: 'Save wallet',
                showLoaderOnConfirm: true,
                customClass: {
                    popup: styles.container,
                    input: styles.input,
                    title: styles.title,
                    confirmButton: styles.confirmButton,
                    cancelButton: styles.cancelButton,
                    container: styles.swalBackdrop,
                },
                preConfirm: (inputValue) => {
                    // Validate the input based on wallet type
                    if (!getRegex(walletType)?.test(inputValue)) {
                        MySwal.showValidationMessage(
                            `<p class=${styles.errorText}>Invalid ${walletType} wallet address. Please enter a valid one.</p>`
                        );
                        return false;
                    }

                    // Return the input value to handle in `.then()`
                    return inputValue;
                },
                allowOutsideClick: () => !MySwal.isLoading(),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // Save the wallet address;
                    try {
                        const response = await saveWalletAddress(
                            walletType,
                            result.value
                        );
                        if (response.success) {
                            // Show success message
                            void MySwal.fire({
                                icon: 'success',
                                title: 'Wallet saved successfully!',
                                html: `<p class=${styles.text}>Your ${walletType} wallet address has been saved.</p>`,
                                customClass: {
                                    popup: styles.container,
                                    input: styles.input,
                                    title: styles.title,
                                    confirmButton: styles.confirmButton,
                                    cancelButton: styles.cancelButton,
                                    container: styles.swalBackdrop,
                                },
                            });
                        } else {
                            // noinspection ExceptionCaughtLocallyJS
                            throw new Error(
                                response.message || 'Failed to save wallet.'
                            );
                        }
                    } catch (error) {
                        void MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            html: `<p class=${styles.text}>${(error as Error).message}</p>`,
                            customClass: {
                                popup: styles.container,
                                input: styles.input,
                                title: styles.title,
                                confirmButton: styles.confirmButton,
                                cancelButton: styles.cancelButton,
                                container: styles.swalBackdrop,
                            },
                        });
                    }
                }
                // else if (result.dismiss === Swal.DismissReason.cancel) {
                //    MySwal.fire("Cancelled", "Wallet address not saved", "info");
                // }
            });
        },
        [MySwal, authComponent]
    );

    const handleWalletClick = useCallback(
        (walletType: WalletType, walletAddress?: string) => {
            openWalletPopup(walletType, walletAddress);
        },
        [openWalletPopup]
    );

    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>

            <div className={styles.pageContent}>
                <div
                    className={styles.titleController}
                    onClick={() => navigationComponent?.goBack()}
                >
                    <div className={styles.backBtn}>
                        <img src={Images.Profile.BackButton} alt="" />
                    </div>
                    <h2 className={styles.pageTitle}>Profile</h2>
                </div>
                <ProfileHeader
                    username={username}
                    characterImageBase64={characterImageBase64}
                    currentLevel={currentLevel}
                    currentLevelExperience={currentLevelExperience}
                    experienceGained={experienceGained}
                    onCustomize={async () => {
                        const navigationComponent =
                            await ContainerHelper.getNavigationComponent();
                        navigationComponent.navigateToIndex(6);
                    }}
                />
                <div className={styles.divider}></div>
                <WalletSection
                    evmWalletAddress={evmWalletAddress}
                    solanaWalletAddress={solanaWalletAddress}
                    tonWalletAddress={tonWalletAddress}
                    onWalletClick={handleWalletClick}
                    onConnectWalletsClick={handleConnectWalletsClick}
                />
                <StatsSection
                    totalSteps={totalSteps ?? 0}
                    totalPurchasedOffers={totalPurchasedOffers ?? 0}
                    totalFriendsCount={totalFriendsCount ?? 0}
                />
                <AchievementsSection />
            </div>
        </>
    );
};

export default ProfilePage;
