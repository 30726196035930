import React from 'react';
import styles from '../../create_listing_flow.module.css';
import CryptoCurrencyInfo from '../../../../../apis/offer_api/crypto_currency_info';
import formatNumber, { formatTokenValue } from '../../../../../utils/currency_formatter';
import Decimal from 'decimal.js';

interface Step5Props {
    listingType: 'fixed' | 'auction';
    expiryDays: number;
    setExpiryDays: (days: number) => void;
    expiryHours: number;
    setExpiryHours: (hours: number) => void;
    expiryMinutes: number;
    setExpiryMinutes: (minutes: number) => void;
    isCryptoSelected: boolean;
    cryptoAmount: number;
    selectedCrypto: CryptoCurrencyInfo | null;
    cryptoUsdValue: Decimal | null;
    price: number;
    startingBid: number;
    errors: { expiry?: string };
    getSelectedItemDetails: () => {
        title: string;
        description: string;
        imageUrl: string;
    };
}

const Step5Duration: React.FC<Step5Props> = ({
    listingType,
    expiryDays,
    setExpiryDays,
    expiryHours,
    setExpiryHours,
    expiryMinutes,
    setExpiryMinutes,
    isCryptoSelected,
    cryptoAmount,
    selectedCrypto,
    cryptoUsdValue,
    price,
    startingBid,
    errors,
    getSelectedItemDetails,
}) => {
    return (
        <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>
                Set Listing Duration
            </h3>

            <div className={styles.selectedItemPreview}>
                <div className={styles.itemImage}>
                    <img
                        src={getSelectedItemDetails().imageUrl}
                        alt="Selected item"
                    />
                </div>
                <div className={styles.itemDetails}>
                    <h4>{getSelectedItemDetails().title}</h4>
                    {isCryptoSelected && (
                        <div className={styles.valueDisplay}>
                            <p>
                                {formatNumber(cryptoAmount)}{' '}
                                {selectedCrypto?.cryptoCurrency}
                                {cryptoUsdValue !== null && (
                                    <span>
                                        {' '}
                                        {formatTokenValue(
                                            cryptoUsdValue,
                                            cryptoAmount
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>
                    )}
                    {listingType === 'fixed' ? (
                        <p className={styles.pricePreview}>
                            Price: {formatNumber(price)} DIG
                        </p>
                    ) : (
                        <p className={styles.pricePreview}>
                            Starting Bid: {formatNumber(startingBid)} DIG
                        </p>
                    )}
                </div>
            </div>

            <div className={styles.formGroup}>
                <label>Listing Duration</label>
                <div className={styles.durationControls}>
                    <div className={styles.durationControl}>
                        <span className={styles.durationLabel}>
                            Days:
                        </span>
                        <span className={styles.durationValue}>
                            {expiryDays}
                        </span>
                        <div className={styles.durationButtons}>
                            <button
                                type="button"
                                className={styles.durationButton}
                                onClick={() =>
                                    setExpiryDays(
                                        Math.max(0, expiryDays - 1)
                                    )
                                }
                                disabled={expiryDays <= 0}
                            >
                                -
                            </button>
                            <button
                                type="button"
                                className={styles.durationButton}
                                onClick={() =>
                                    setExpiryDays(
                                        Math.min(30, expiryDays + 1)
                                    )
                                }
                                disabled={expiryDays >= 30}
                            >
                                +
                            </button>
                        </div>
                    </div>

                    <div className={styles.durationControl}>
                        <span className={styles.durationLabel}>
                            Hours:
                        </span>
                        <span className={styles.durationValue}>
                            {expiryHours}
                        </span>
                        <div className={styles.durationButtons}>
                            <button
                                type="button"
                                className={styles.durationButton}
                                onClick={() =>
                                    setExpiryHours(
                                        Math.max(0, expiryHours - 1)
                                    )
                                }
                                disabled={expiryHours <= 0}
                            >
                                -
                            </button>
                            <button
                                type="button"
                                className={styles.durationButton}
                                onClick={() =>
                                    setExpiryHours(
                                        Math.min(
                                            23,
                                            expiryHours + 1
                                        )
                                    )
                                }
                                disabled={expiryHours >= 23}
                            >
                                +
                            </button>
                        </div>
                    </div>

                    <div className={styles.durationControl}>
                        <span className={styles.durationLabel}>
                            Minutes:
                        </span>
                        <span className={styles.durationValue}>
                            {expiryMinutes}
                        </span>
                        <div className={styles.durationButtons}>
                            <button
                                type="button"
                                className={styles.durationButton}
                                onClick={() =>
                                    setExpiryMinutes(
                                        Math.max(
                                            0,
                                            expiryMinutes - 5
                                        )
                                    )
                                }
                                disabled={expiryMinutes <= 0}
                            >
                                -
                            </button>
                            <button
                                type="button"
                                className={styles.durationButton}
                                onClick={() =>
                                    setExpiryMinutes(
                                        Math.min(
                                            55,
                                            expiryMinutes + 5
                                        )
                                    )
                                }
                                disabled={expiryMinutes >= 55}
                            >
                                +
                            </button>
                        </div>
                    </div>
                </div>
                {errors.expiry && (
                    <p className={styles.errorText}>
                        {errors.expiry}
                    </p>
                )}
                <p className={styles.infoText}>
                    {listingType === 'fixed' 
                        ? 'Listings can be active for up to 30 days'
                        : 'Auctions can run for up to 30 days'}
                </p>
            </div>
        </div>
    );
};

export default Step5Duration;
