interface ISkinInit {
    imagePath: string;
    isEditable: boolean;
    bodyItemId: string;
    axeItemId: string;
    bagItemId: string;
    bottomItemId: string;
    braceletItemId: string;
    earringItemId: string;
    eyeItemId: string;
    eyebrowItemId: string;
    eyewearItemId: string;
    gloveItemId: string;
    hairItemId: string;
    hairAccItemId: string;
    handAccItemId: string;
    headItemId: string;
    headgearItemId: string;
    lipsItemId: string;
    maskItemId: string;
    mustacheItemId: string;
    shieldItemId: string;
    shoesItemId: string;
    spearItemId: string;
    swordItemId: string;
    topItemId: string;
    watchItemId: string;
}

export class Skin {
    id?: string | null;
    imagePath: string;
    isEditable: boolean;

    bodyItemId: string;
    axeItemId: string;
    bagItemId: string;
    bottomItemId: string;
    braceletItemId: string;
    earringItemId: string;
    eyeItemId: string;
    eyebrowItemId: string;
    eyewearItemId: string;
    gloveItemId: string;
    hairItemId: string;
    hairAccItemId: string;
    handAccItemId: string;
    headItemId: string;
    headgearItemId: string;
    lipsItemId: string;
    maskItemId: string;
    mustacheItemId: string;
    shieldItemId: string;
    shoesItemId: string;
    spearItemId: string;
    swordItemId: string;
    topItemId: string;
    watchItemId: string;

    constructor(init: Partial<ISkinInit> & Pick<ISkinInit, 'imagePath' | 'isEditable'>) {
        this.imagePath = init.imagePath;
        this.isEditable = init.isEditable;
        this.bodyItemId = init.bodyItemId || '';
        this.axeItemId = init.axeItemId || '';
        this.bagItemId = init.bagItemId || '';
        this.bottomItemId = init.bottomItemId || '';
        this.braceletItemId = init.braceletItemId || '';
        this.earringItemId = init.earringItemId || '';
        this.eyeItemId = init.eyeItemId || '';
        this.eyebrowItemId = init.eyebrowItemId || '';
        this.eyewearItemId = init.eyewearItemId || '';
        this.gloveItemId = init.gloveItemId || '';
        this.hairItemId = init.hairItemId || '';
        this.hairAccItemId = init.hairAccItemId || '';
        this.handAccItemId = init.handAccItemId || '';
        this.headItemId = init.headItemId || '';
        this.headgearItemId = init.headgearItemId || '';
        this.lipsItemId = init.lipsItemId || '';
        this.maskItemId = init.maskItemId || '';
        this.mustacheItemId = init.mustacheItemId || '';
        this.shieldItemId = init.shieldItemId || '';
        this.shoesItemId = init.shoesItemId || '';
        this.spearItemId = init.spearItemId || '';
        this.swordItemId = init.swordItemId || '';
        this.topItemId = init.topItemId || '';
        this.watchItemId = init.watchItemId || '';
    }

    getItemIds(): string[] {
        return [
            this.bodyItemId,
            this.axeItemId,
            this.bagItemId,
            this.bottomItemId,
            this.braceletItemId,
            this.earringItemId,
            this.eyeItemId,
            this.eyebrowItemId,
            this.eyewearItemId,
            this.gloveItemId,
            this.hairItemId,
            this.hairAccItemId,
            this.handAccItemId,
            this.headItemId,
            this.headgearItemId,
            this.lipsItemId,
            this.maskItemId,
            this.mustacheItemId,
            this.shieldItemId,
            this.shoesItemId,
            this.spearItemId,
            this.swordItemId,
            this.topItemId,
            this.watchItemId,
        ].filter(id => id !== '');
    }

    static fromJsonLowerCamelCase(json: any): Skin {
        const skin = new Skin({
            imagePath: json['imagePath'],
            isEditable: json['isEditable'],
            bodyItemId: json['bodyItemId'],
            axeItemId: json['axeItemId'],
            bagItemId: json['bagItemId'],
            bottomItemId: json['bottomItemId'],
            braceletItemId: json['braceletItemId'],
            earringItemId: json['earringItemId'],
            eyeItemId: json['eyeItemId'],
            eyebrowItemId: json['eyebrowItemId'],
            eyewearItemId: json['eyewearItemId'],
            gloveItemId: json['gloveItemId'],
            hairItemId: json['hairItemId'],
            hairAccItemId: json['hairAccItemId'],
            handAccItemId: json['handAccItemId'],
            headItemId: json['headItemId'],
            headgearItemId: json['headgearItemId'],
            lipsItemId: json['lipsItemId'],
            maskItemId: json['maskItemId'],
            mustacheItemId: json['mustacheItemId'],
            shieldItemId: json['shieldItemId'],
            shoesItemId: json['shoesItemId'],
            spearItemId: json['spearItemId'],
            swordItemId: json['swordItemId'],
            topItemId: json['topItemId'],
            watchItemId: json['watchItemId']
        });
        skin.id = json['id'];
        return skin;
    }

    static fromJsonUpperCamelCase(json: any): Skin {
        const skin = new Skin({
            imagePath: json['ImagePath'],
            isEditable: json['IsEditable'],
            bodyItemId: json['BodyItemId'],
            axeItemId: json['AxeItemId'],
            bagItemId: json['BagItemId'],
            bottomItemId: json['BottomItemId'],
            braceletItemId: json['BraceletItemId'],
            earringItemId: json['EarringItemId'],
            eyeItemId: json['EyeItemId'],
            eyebrowItemId: json['EyebrowItemId'],
            eyewearItemId: json['EyewearItemId'],
            gloveItemId: json['GloveItemId'],
            hairItemId: json['HairItemId'],
            hairAccItemId: json['HairAccItemId'],
            handAccItemId: json['HandAccItemId'],
            headItemId: json['HeadItemId'],
            headgearItemId: json['HeadgearItemId'],
            lipsItemId: json['LipsItemId'],
            maskItemId: json['MaskItemId'],
            mustacheItemId: json['MustacheItemId'],
            shieldItemId: json['ShieldItemId'],
            shoesItemId: json['ShoesItemId'],
            spearItemId: json['SpearItemId'],
            swordItemId: json['SwordItemId'],
            topItemId: json['TopItemId'],
            watchItemId: json['WatchItemId']
        });
        skin.id = json['Id'];
        return skin;
    }
}
