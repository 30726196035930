import React, {
    useEffect,
    useRef,
    useState,
    useMemo,
    useCallback,
} from 'react';
import styles from './vault_page.module.css';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import OfferComponent from '../../component_system/components/offer/offer_component';
import ValueContainer from '../../utils/value_container';
import useObservable from '../../utils/observable/use_observable_hook';
import Images from '../../component_system/components/preload/images';
import CryptoCurrencyInfo from '../../apis/offer_api/crypto_currency_info';
import { showSweetAlert } from '../components/popup/sweet_alert';
import formatNumber from '../../utils/currency_formatter';
import ClaimedOffer from '../../apis/offer_api/claimed_offers';
import Decimal from 'decimal.js';
import VaultOfferType from '../../apis/offer_api/vault_offer_type';
import LootBoxModal from '../components/loot_box/loot_box_modal';
import AcquiredOffer from '../../apis/offer_api/acquired_offer';
import { constants } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import VaultHeader from '../components/vault/vault_header';
import VaultList from '../components/vault/vault_list';
import { useClaimEligibility } from '../../hooks/useClaimEligibility';

const VaultPage = () => {
    const { state } = useLocation();
    const coins = useObservable(ValueContainer.coinsObservable);
    const currentLevel = useObservable(ValueContainer.currentLevelObservable);
    const evmWallet = useObservable(ValueContainer.evmWalletObservable);
    const tonWallet = useObservable(ValueContainer.tonWalletObservable);
    const solanaWallet = useObservable(ValueContainer.solanaWalletObservable);

    const [aquiredOffers, setAquiredOffers] = useState<CryptoCurrencyInfo[]>(
        []
    );
    const [otherAquiredOffers, setOtherAquiredOffers] = useState<
        AcquiredOffer[]
    >([]);
    const [claimedOffers, setClaimedOffers] = useState<ClaimedOffer[]>([]);
    const [totalWorth, setTotalWorth] = useState<Decimal>(new Decimal(0));
    const [selectedLootBox, setSelectedLootBox] =
        useState<AcquiredOffer | null>(null);
    const [showDollarWorth, setShowDollarWorth] = useState<boolean>(false);
    const vaultListRef = useRef<HTMLDivElement[]>([]);
    const digItemRef = useRef<HTMLDivElement>(null);

    // Use our custom hook for claim eligibility
    const { canClaim } = useClaimEligibility(
        currentLevel,
        evmWallet,
        tonWallet,
        solanaWallet
    );

    const offerComponentRef = useRef<OfferComponent | null>(null);
    const showDollarWorthRef = useRef(showDollarWorth);

    // Update ref when state changes
    useEffect(() => {
        showDollarWorthRef.current = showDollarWorth;
    }, [showDollarWorth]);
    
    // Scroll to new item when data changes
    const scrollToNew = useCallback(() => {
        // Check if DIG is new
        if (offerComponentRef.current?.newAcquisition?.cryptoCurrency === 'DIG' && digItemRef.current) {
            const y = digItemRef.current.getBoundingClientRect().top + window.scrollY - 100;
            document.getElementById('scrollable-container')?.scroll({
                top: y,
                behavior: 'smooth'
            });
            return;
        }
        
        // Find the first item with `isNew` from the list
        const newItemIndex = vaultListRef.current.findIndex(
            (ref) => ref?.dataset?.isNew === 'true'
        );
        
        if (newItemIndex !== -1 && vaultListRef.current[newItemIndex]) {
            const element = vaultListRef.current[newItemIndex];
            const y = element.getBoundingClientRect().top + window.scrollY - 100;
            document.getElementById('scrollable-container')?.scroll({
                top: y,
                behavior: 'smooth'
            });
        }
    }, []);
    
    useEffect(() => {
        scrollToNew();
    }, [aquiredOffers, otherAquiredOffers, claimedOffers, scrollToNew]);

    // Initialize component and set up subscriptions
    useEffect(() => {
        if (state?.acquiredOffer) {
            const acquiredOffer = state.acquiredOffer as AcquiredOffer;
            if (acquiredOffer.vaultOfferType === VaultOfferType.LootBox) {
                setSelectedLootBox(acquiredOffer);
            }
        }

        const subscriber = () => {
            if (offerComponentRef.current) {
                setAquiredOffers([...offerComponentRef.current.acquiredOffers]);
                setOtherAquiredOffers([
                    ...offerComponentRef.current.otherAcquiredOffers,
                ]);
                setClaimedOffers([...offerComponentRef.current.claims]);
                setTotalWorth(offerComponentRef.current.totalWorth);
                setShowDollarWorth(offerComponentRef.current.showDollarWorth);
            }
        };

        const setup = async () => {
            offerComponentRef.current =
                await ContainerHelper.getOffersComponent();
            offerComponentRef.current.registerSubscriber(subscriber);
            setAquiredOffers([...offerComponentRef.current.acquiredOffers]);
            setOtherAquiredOffers([
                ...offerComponentRef.current.otherAcquiredOffers,
            ]);
            setClaimedOffers([...offerComponentRef.current.claims]);
            setTotalWorth(offerComponentRef.current.totalWorth);
            setShowDollarWorth(offerComponentRef.current.showDollarWorth);
        };

        void setup();

        return () => {
            if (offerComponentRef.current) {
                offerComponentRef.current.showDollarWorth =
                    showDollarWorthRef.current;
                offerComponentRef.current.unregisterSubscriber(subscriber);
            }
        };
    }, [state]);

    // Handle claim action
    const claimAction = (offer: CryptoCurrencyInfo) => {
        const result = canClaim(offer, true);
        if (!result.canClaim) {
            showSweetAlert('info', 'Claim', result.errorMessage ?? 'Error');
            return;
        }
        offerComponentRef.current?.claim(offer.cryptoCurrency);
    };

    // Open claim URL
    const openClaimUrl = useCallback(() => {
        window.open(constants.CLAIM_URL, '_blank');
    }, []);

    // Handle loot box close
    const handleLootBoxClose = useCallback(() => {
        setSelectedLootBox(null);
        ContainerHelper.getOffersComponent().then((offersComponent) => {
            offersComponent.markNewAcquisitionAsSeen();
        });
    }, []);

    // Navigate to market
    const navigateToMarket = useCallback(() => {
        ContainerHelper.getNavigationComponent().then((navigationComponent) => {
            navigationComponent.navigateToIndex(7);
        });
    }, []);

    // Create DIG asset
    const digAsset = useMemo(
        () => ({
            cryptoCurrency: 'DIG',
            name: '',
            description: '',
            coverUrl: Images.Icons.DiggersGoCoin,
            blockchainType: '',
            network: '',
            quotePriceUSD: new Decimal(0),
            cryptoCurrencyAmount: coins,
            minClaimableUSDAmount: new Decimal(0),
        }),
        [coins]
    );

    // Filter crypto assets to exclude DIG
    const filteredCryptoAssets = useMemo(
        () => aquiredOffers.filter((o) => o.cryptoCurrency !== 'DIG'),
        [aquiredOffers]
    );

    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                    loading="lazy"
                />
            </div>
            <div className={styles.pageContent} id="scrollable-container">
                <VaultHeader
                    showDollarWorth={showDollarWorth}
                    onToggleWorth={() => setShowDollarWorth(!showDollarWorth)}
                    onNavigateToMarket={navigateToMarket}
                />

                {/* DIG Asset */}
                <div 
                    key={-1} 
                    ref={digItemRef}
                    className={`${styles.questPanel}`}
                    data-is-new={offerComponentRef.current?.newAcquisition?.cryptoCurrency === 'DIG' ? 'true' : 'false'}
                >
                    <img
                        src={Images.Icons.DiggersGoCoin}
                        className={styles.icon}
                        alt=""
                    />
                    <h5 className={styles.tokenTitle}>{'DIG'}</h5>
                    <div className={styles.actions}>
                        <p>{formatNumber(coins)}</p>
                        <button
                            type="button"
                            className={`${styles.claim} ${styles.noCoins}`}
                            onClick={() => claimAction(digAsset)}
                        >
                            Claim
                        </button>
                    </div>

                    {offerComponentRef.current?.newAcquisition
                        ?.cryptoCurrency === 'DIG' && (
                        <span className={styles.questPanelBadge}>!</span>
                    )}
                </div>

                {/* Crypto Assets and Other Offers */}
                <VaultList
                    ref={vaultListRef}
                    cryptoAssets={filteredCryptoAssets}
                    otherOffers={otherAquiredOffers}
                    claimedOffers={claimedOffers}
                    onClaim={claimAction}
                    onOpenLootBox={setSelectedLootBox}
                    onOpenClaimUrl={openClaimUrl}
                    showDollarWorth={showDollarWorth}
                    newAcquisition={offerComponentRef.current?.newAcquisition}
                    canClaimAsset={(asset) => canClaim(asset, false).canClaim}
                />

                <div style={{ height: 120 }} />
            </div>

            {selectedLootBox && (
                <LootBoxModal
                    show={true}
                    onClose={handleLootBoxClose}
                    offer={selectedLootBox}
                />
            )}
        </>
    );
};

export default VaultPage;
