import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import { Skin } from '../../apis/customization_api/skin';
import {
    Item,
    ItemRarity,
    ItemType,
    ItemOwnerType,
    ItemCompatibility,
} from '../../apis/customization_api/item';
import { hasNoneOption } from '../components/body_part_selector';
import { toast } from 'react-toastify';
import Images from '../../component_system/components/preload/images';
import styles from './customization_page.module.css';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import BodyPartSelector from '../components/body_part_selector';
import ValueContainer from '../../utils/value_container';
import useObservable from '../../utils/observable/use_observable_hook';
import ItemSelectionPopup from '../components/item_selection_popup';

const CustomizationPage: React.FC = () => {
    const [skins, setSkins] = useState<Skin[]>([]);
    const [items, setItems] = useState<Item[]>([]);
    const [selectedBodyPart, setSelectedBodyPart] = useState<ItemType | null>(
        null
    );
    const [loadingSkin, setLoadingSkin] = useState<string | null>(null);
    const [loadingItem, setLoadingItem] = useState<string | null | undefined>(
        null
    );
    const [showItemPopup, setShowItemPopup] = useState<boolean>(false);

    const getSelectedItemId = (
        skin: Skin,
        bodyPart: ItemType | null
    ): string | undefined => {
        if (!bodyPart) return undefined;

        switch (bodyPart as ItemType) {
            case ItemType.Body:
                return skin.bodyItemId;
            case ItemType.Axe:
                return skin.axeItemId;
            case ItemType.Bag:
                return skin.bagItemId;
            case ItemType.Bottom:
                return skin.bottomItemId;
            case ItemType.Bracelet:
                return skin.braceletItemId;
            case ItemType.Earring:
                return skin.earringItemId;
            case ItemType.Eye:
                return skin.eyeItemId;
            case ItemType.Eyebrow:
                return skin.eyebrowItemId;
            case ItemType.Eyewear:
                return skin.eyewearItemId;
            case ItemType.Glove:
                return skin.gloveItemId;
            case ItemType.Hair:
                return skin.hairItemId;
            case ItemType.HairAcc:
                return skin.hairAccItemId;
            case ItemType.HandAcc:
                return skin.handAccItemId;
            case ItemType.Head:
                return skin.headItemId;
            case ItemType.Headgear:
                return skin.headgearItemId;
            case ItemType.Lips:
                return skin.lipsItemId;
            case ItemType.Mask:
                return skin.maskItemId;
            case ItemType.Mustache:
                return skin.mustacheItemId;
            case ItemType.Shield:
                return skin.shieldItemId;
            case ItemType.Shoes:
                return skin.shoesItemId;
            case ItemType.Spear:
                return skin.spearItemId;
            case ItemType.Sword:
                return skin.swordItemId;
            case ItemType.Top:
                return skin.topItemId;
            case ItemType.Watch:
                return skin.watchItemId;
            default:
                return undefined;
        }
    };

    const filteredItems = React.useMemo(() => {
        if (!selectedBodyPart) return [];

        const itemsForType = items.filter(
            (item) => item.type === selectedBodyPart
        );

        // If this body part type can have "none" selected, add it as first option
        if (hasNoneOption(selectedBodyPart)) {
            const noneItem: Item = new Item({
                id: 'none',
                name: 'None',
                description: 'Remove this item',
                type: selectedBodyPart,
                rarity: ItemRarity.Common,
                itemOwnerType: ItemOwnerType.Default,
                compatibility: ItemCompatibility.All,
                index: -1,
                onlyOrExcept: [],
            });
            return [noneItem, ...itemsForType];
        }

        return itemsForType;
    }, [items, selectedBodyPart]);
    const customizationComponent = useFetchComponent(
        ContainerHelper.getCustomizationComponent
    );

    // Memoize the skin selection handler
    const handleSkinClick = React.useCallback(
        (skin: Skin) => {
            if (customizationComponent && skin.id && loadingSkin !== skin.id) {
                setLoadingSkin(skin.id);
                customizationComponent
                    .setCharacter(skin.id)
                    .then((success) => {
                        if (success) {
                            toast.success('Skin changed successfully!', {
                                icon: () => (
                                    <img
                                        src={Images.Icons.Checkmark}
                                        alt="success"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                ),
                            });
                        } else {
                            toast.error('Failed to change skin', {
                                icon: () => (
                                    <img
                                        src={Images.Icons.Close}
                                        alt="error"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                ),
                            });
                        }
                    })
                    .finally(() => {
                        setLoadingSkin(null);
                    });
            }
        },
        [customizationComponent, loadingSkin]
    );

    useEffect(() => {
        const loadData = async () => {
            try {
                const customizationComponent =
                    await ContainerHelper.getCustomizationComponent();
                setSkins(customizationComponent.unlockedSkins);
                setItems(customizationComponent.unlockedItems);

                // Add subscriber to update UI when customization changes
                const subscriber = () => {
                    setSkins([...customizationComponent.unlockedSkins]);
                    setItems([...customizationComponent.unlockedItems]);
                };

                customizationComponent.addSubscriber(subscriber);

                // Clean up subscriber when component unmounts
                return () => {
                    customizationComponent.removeSubscriber(subscriber);
                };
            } catch (error) {
                toast.error('Failed to load customization data', {
                    icon: () => (
                        <img
                            src={Images.Icons.Close}
                            alt="error"
                            style={{ width: '24px', height: '24px' }}
                        />
                    ),
                });
            }
        };

        void loadData();
    }, []);

    // Memoize the item click handler
    const handleItemClick = React.useCallback(
        (item: Item) => {
            if (
                customizationComponent &&
                selectedBodyPart !== null &&
                customizationComponent.selectedSkin &&
                loadingItem !== item.id
            ) {
                // For "none" option, pass null as itemId
                const itemIdToSet = item.id === 'none' ? null : item.id;

                setLoadingItem(item.id);
                customizationComponent
                    .setItemForBodyPart(
                        customizationComponent.selectedSkin,
                        selectedBodyPart,
                        itemIdToSet
                    )
                    .then((success) => {
                        if (success) {
                            toast.success(
                                `${item.name} applied successfully!`,
                                {
                                    icon: () => (
                                        <img
                                            src={Images.Icons.Checkmark}
                                            alt="success"
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                            }}
                                        />
                                    ),
                                }
                            );
                        } else {
                            toast.error(`Failed to apply ${item.name}`, {
                                icon: () => (
                                    <img
                                        src={Images.Icons.Close}
                                        alt="error"
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                ),
                            });
                        }
                    })
                    .finally(() => {
                        setLoadingItem(null);
                    });
            }
        },
        [customizationComponent, selectedBodyPart, loadingItem]
    );

    // Handle body part selection - now opens the popup
    const handleBodyPartSelect = React.useCallback((part: ItemType) => {
        setSelectedBodyPart(part);
        setShowItemPopup(true);
    }, []);

    // Memoize the BodyPartSelector component at the top level
    const memoizedBodyPartSelector = React.useMemo(
        () => (
            <BodyPartSelector
                selectedPart={selectedBodyPart}
                onPartSelect={handleBodyPartSelect}
                selectedSkin={customizationComponent?.selectedSkin}
                items={items}
                skins={skins}
            />
        ),
        [
            selectedBodyPart,
            handleBodyPartSelect,
            customizationComponent?.selectedSkin,
            items,
            skins,
        ]
    );

    const characterImageBase64 = useObservable(
        ValueContainer.characterImageBase64Observable
    );

    const username = useObservable(ValueContainer.usernameObservable);

    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>

            <div
                className={styles.pageContent}
                style={{ overflow: showItemPopup ? 'hidden' : 'auto' }}
            >
                <div
                    className={styles.titleController}
                    onClick={() =>
                        ContainerHelper.getNavigationComponent().then((nav) =>
                            nav.goBack()
                        )
                    }
                >
                    <div className={styles.backBtn}>
                        <img src={Images.Profile.BackButton} alt="" />
                    </div>
                    <h2 className={styles.pageTitle}>Customize</h2>
                </div>

                <div className={styles.customizationPanel}>
                    <h3 className={styles.sectionTitle}>Skins</h3>
                    <div className={styles.grid}>
                        {skins.map((skin) => (
                            <div
                                key={skin.id}
                                className={`${styles.itemCard} ${skin.id === customizationComponent?.selectedSkin ? styles.selectedCard : ''}`}
                                onClick={() =>
                                    skin.id !==
                                        customizationComponent?.selectedSkin &&
                                    handleSkinClick(skin)
                                }
                                style={{
                                    cursor:
                                        skin.id ===
                                        customizationComponent?.selectedSkin
                                            ? 'default'
                                            : 'pointer',
                                }}
                            >
                                {skin.id ===
                                    customizationComponent?.selectedSkin && (
                                    <div className={styles.selectedBadge}>
                                        Selected
                                    </div>
                                )}
                                {loadingSkin === skin.id && (
                                    <div className={styles.loadingOverlay}>
                                        <div className={styles.spinner}></div>
                                    </div>
                                )}
                                <div className={styles.itemImage}>
                                    {(skin.isEditable &&
                                        skin.id !==
                                            customizationComponent?.selectedSkin) ||
                                    !characterImageBase64 ? (
                                        <div className={styles.defaultIcon}>
                                            <i
                                                className="fas fa-brush"
                                                style={{ fontSize: '32px' }}
                                            />
                                        </div>
                                    ) : (
                                        <img
                                            src={
                                                skin.isEditable &&
                                                characterImageBase64
                                                    ? `data:image/png;base64,${characterImageBase64}`
                                                    : require(
                                                          `../../assets/images/avatars/${
                                                              skin.isEditable
                                                                  ? 'editable'
                                                                  : skin.id
                                                          }.png`
                                                      )
                                            }
                                            alt={skin.id || 'skin'}
                                        />
                                    )}
                                </div>
                                <div className={styles.itemInfo}>
                                    <p className={styles.itemName}>
                                        {skin.isEditable && username
                                            ? skin.id ===
                                              customizationComponent?.selectedSkin
                                                ? username
                                                : 'Customize'
                                            : ''}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.divider}></div>

                    <h3 className={styles.sectionTitle}>Character</h3>
                    {/* Only show body part selector if selected skin is editable */}
                    {skins.find(
                        (skin) =>
                            skin.id === customizationComponent?.selectedSkin
                    )?.isEditable ? (
                        <>
                            {memoizedBodyPartSelector}

                            <h3 className={styles.sectionTitle}>
                                Select a body part to customize
                            </h3>
                        </>
                    ) : (
                        <div className={styles.disabledSection}>
                            <p>
                                This skin is not customizable. Please select
                                'CUSTOMIZE' to customize character parts.
                            </p>
                        </div>
                    )}

                    {/* Item selection popup */}
                    {showItemPopup && selectedBodyPart !== null && (
                        <ItemSelectionPopup
                            items={filteredItems}
                            selectedItemId={getSelectedItemId(
                                skins.find(
                                    (skin) =>
                                        skin.id ===
                                        customizationComponent?.selectedSkin
                                )!,
                                selectedBodyPart
                            )}
                            onItemSelect={handleItemClick}
                            onClose={() => setShowItemPopup(false)}
                            bodyPartType={selectedBodyPart}
                            loadingItem={loadingItem}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default CustomizationPage;
