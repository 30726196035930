import { useCallback } from 'react';
import CryptoCurrencyInfo from '../apis/offer_api/crypto_currency_info';
import Decimal from 'decimal.js';
import WalletType from '../apis/models/profile/wallet_type';

export function useClaimEligibility(
  currentLevel: number | undefined,
  evmWallet: string | undefined,
  tonWallet: string | undefined,
  solanaWallet: string | undefined
) {
  const MIN_CLAIM_LEVEL = 50;

  // Check if wallet is set for the given blockchain type
  const isWalletSet = useCallback(
    (offer: CryptoCurrencyInfo): boolean => {
      switch (offer.blockchainType.toLocaleLowerCase()) {
        case WalletType.EVM.toLowerCase():
          return evmWallet !== undefined && evmWallet !== null && evmWallet !== '';
        case WalletType.TON.toLowerCase():
          return tonWallet !== undefined && tonWallet !== null && tonWallet !== '';
        case WalletType.Solana.toLowerCase():
          return solanaWallet !== undefined && solanaWallet !== null && solanaWallet !== '';
        default:
          return false;
      }
    },
    [evmWallet, solanaWallet, tonWallet]
  );

  // Check if user has enough coins to claim
  const hasEnoughCoins = useCallback((offer: CryptoCurrencyInfo): boolean => {
    switch (offer.cryptoCurrency) {
      case 'DIG':
        return false;
      default:
        return new Decimal(offer.cryptoCurrencyAmount ?? 0)
          .mul(offer.quotePriceUSD)
          .gte(offer.minClaimableUSDAmount);
    }
  }, []);

  // Main function to check if user can claim an asset
  const canClaim = useCallback(
    (
      offer: CryptoCurrencyInfo,
      checkWallet: boolean
    ): { canClaim: boolean; errorMessage?: string } => {
      if ((offer.cryptoCurrencyAmount ?? 0) <= 0)
        return { canClaim: false, errorMessage: 'No coins to claim' };
        
      if (offer.cryptoCurrency === 'DIG') {
        return {
          canClaim: false,
          errorMessage: 'DIG is not claimable yet',
        };
      }
      
      if ((currentLevel ?? 0) < MIN_CLAIM_LEVEL) {
        return {
          canClaim: false,
          errorMessage: `You can claim at level ${MIN_CLAIM_LEVEL} with ${offer.minClaimableUSDAmount} USD worth of ${offer.cryptoCurrency}.`,
        };
      }
      
      if (!hasEnoughCoins(offer)) {
        return {
          canClaim: false,
          errorMessage: `To claim you need ${offer.minClaimableUSDAmount} USD worth of ${offer.cryptoCurrency}.`,
        };
      }
      
      if (checkWallet && !isWalletSet(offer)) {
        return {
          canClaim: false,
          errorMessage: `Please set your wallet for ${offer.blockchainType} to claim ${offer.cryptoCurrency}.`,
        };
      }
      
      return { canClaim: true };
    },
    [currentLevel, hasEnoughCoins, isWalletSet]
  );

  return { canClaim, isWalletSet, hasEnoughCoins };
}
