import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import Pet from '../../../apis/models/friend/pet';
import ValueContainer from '../../../utils/value_container';
import AuthenticationComponent from '../authentication/authentication_component';
import CustomizationApi from '../../../apis/customization_api/customization_api';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import FriendInfo from '../../../apis/models/friend/friend_info';
import UnityComponent from '../unity/unity_component';
import SeasonPassSettings from '../../../apis/models/season_pass/season_pass_settings';
import { SeasonPassRewardType } from '../../../apis/models/season_pass/season_pass_reward';
import { MethodHandler } from '../signalr/signalr_helper';
import { toast } from 'react-toastify';
import Images from '../preload/images';
import ContainerHelper from '../../component_container/utilities/container_helper';
import { rarity_gradient } from '../../../utils/constants';
import React from 'react';
import UserAvatar from '../../../ui/components/user_avatar';
import Sounds from '../preload/sounds';
import { Skin } from '../../../apis/customization_api/skin';
import { Item } from '../../../apis/customization_api/item';

type CustomizationComponentSubscriber = () => void;

type CharacterSkin = {
    characterBuildString: string;
    requiredLevel: number;
};

class CustomizationComponent extends Component {
    private _subscribers: CustomizationComponentSubscriber[] = [];

    addSubscriber(subscriber: CustomizationComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: CustomizationComponentSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
    }

    private _seasonPassSettings: SeasonPassSettings | undefined;
    get seasonPassSettings(): SeasonPassSettings | undefined {
        return this._seasonPassSettings;
    }

    private _selectableCharacterBuilds: CharacterSkin[] | undefined;
    get selectableCharacterBuilds(): CharacterSkin[] {
        return this._selectableCharacterBuilds!;
    }

    private _unlockedSkins: Skin[] | undefined;
    get unlockedSkins(): Skin[] {
        return this._unlockedSkins!;
    }

    private _unlockedItems: Item[] | undefined;
    get unlockedItems(): Item[] {
        return this._unlockedItems!;
    }

    private _selectedSkin: string | undefined;
    get selectedSkin(): string | undefined {
        return this._selectedSkin;
    }

    private _characterBuildString: string | undefined;
    get characterBuildString(): string | undefined {
        return this._characterBuildString;
    }

    private _unityComponent: UnityComponent | undefined;

    private _pets: Pet[] | undefined;
    get pets(): Pet[] {
        return this._pets!;
    }

    get activePets(): Pet[] {
        return this._pets!.filter((pet) => pet.isActivated);
    }

    get userActivePets(): Pet[] {
        return this.activePets.filter(
            (pet) => pet.ownerTelegramId === ValueContainer.telegramId
        );
    }

    get userPets(): Pet[] {
        return this._pets!.filter(
            (pet) => pet.ownerTelegramId === ValueContainer.telegramId
        );
    }

    get unseenPetCount(): number {
        return this.userPets.filter((pet) => !pet.isSeen).length;
    }

    removePetsBelongingTo(telegramId: string): void {
        this._pets = this._pets!.filter(
            (pet) => pet.ownerTelegramId !== telegramId
        );
        this._notifySubscribers();
    }

    removePetsBelongingToWithoutNotify(telegramId: string): void {
        this._pets = this._pets!.filter(
            (pet) => pet.ownerTelegramId !== telegramId
        );
    }

    removePet(petId: string): void {
        this._pets = this._pets!.filter((pet) => pet.petId !== petId);
        this._notifySubscribers();
    }

    removePetWithoutNotify(petId: string): void {
        this._pets = this._pets!.filter((pet) => pet.petId !== petId);
    }

    get type(): Function {
        return CustomizationComponent;
    }

    get name(): string {
        return 'Customization Component';
    }

    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);
        try {
            this._pets = await CustomizationApi.getPets();
            const response = await CustomizationApi.getSelectedCharacter();
            this._characterBuildString = response.response.characterBuildString;
            this._selectedSkin = response.response.selectedCharacter;
        } catch (error) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'customizationComponentFailedToLoad'.tr()
                ),
            ];
        }

        // await this.setDependencyLocked([SettingsComponent]);
        // const settingsComponent = (await this.getComponent(
        //     SettingsComponent
        // )) as SettingsComponent;

        // this._selectableCharacterBuilds = settingsComponent.characterPrices!
        //     .filter((price: any) => price.characterId.split('||')[1].split(':').length > 2)
        //     .map((price: any) => {
        //         return {
        //             characterBuildString: price.characterId.split('||')[1].trim(),
        //             requiredLevel: price.coinPrice,
        //         };
        //     });

        this._seasonPassSettings =
            await CustomizationApi.getSeasonPassSettings();

        try {
            // Load unlocked skins and items
            this._unlockedSkins = await CustomizationApi.getUnlockedSkins();
            this._unlockedItems = await CustomizationApi.getUnlockedItems();
        } catch (error) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'customizationComponentFailedToLoadUnlocks'.tr()
                ),
            ];
        }

        this._selectableCharacterBuilds = this._seasonPassSettings.rewards
            .filter((reward) => reward.type === SeasonPassRewardType.Skin)
            .map((reward) => {
                return {
                    characterBuildString: reward.reward,
                    requiredLevel: reward.level,
                };
            });
        const unlockedCharacters =
            await CustomizationApi.getUnlockedCharacters();

        for (const buildString of unlockedCharacters) {
            if (
                this._selectableCharacterBuilds.find(
                    (build) => build.characterBuildString === buildString
                )
            )
                continue;
            this._selectableCharacterBuilds.push({
                characterBuildString: buildString,
                requiredLevel: 0,
            });
        }

        await this.setDependencyLocked([UnityComponent]);
        this._unityComponent = (await this.getComponent(
            UnityComponent
        )) as UnityComponent;

        this._unityComponent.setCharacter(this._characterBuildString!);

        await new Promise((resolve) => setTimeout(resolve, 50));

        ValueContainer.characterImageBase64 =
            await this._unityComponent.generateCharacterPreview();

        try {
            const shouldUpdateAvatarImage =
                await CustomizationApi.shouldUpdateUserAvatar();

            if (shouldUpdateAvatarImage) {
                // convert from base64 to png
                await this._uploadBase64Image(
                    ValueContainer.characterImageBase64
                );
            }
        } catch (error) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'customizationComponentFailedToLoad'.tr()
                ),
            ];
        }

        ContainerHelper.getSignalRComponent().then((signalRComponent) => {
            signalRComponent.registerMethodHandler(
                'UserLevelUp',
                this._userLevelUpHandler.bind(this)
            );
        });

        return [];
    }

    async setItemForBodyPart(skinId: string, itemType: number, itemId: string | null| undefined): Promise<boolean> {
        const response = await CustomizationApi.setItemForBodyPart(skinId, itemType, itemId);

        if (!response.isSuccess) {
            return false;
        }

        // Update the skin in the local state with the returned updated skin
        if (this._unlockedSkins && response.response.updatedSkin) {
            const updatedSkin = Skin.fromJsonLowerCamelCase(response.response.updatedSkin);
            const skinIndex = this._unlockedSkins.findIndex(skin => skin.id === skinId);
            
            if (skinIndex >= 0) {
                // Replace the skin with the updated one from the server
                this._unlockedSkins[skinIndex] = updatedSkin;
            }
        }

        // Update the character build string if it was returned
        if (response.response.characterBuildString) {
            this._characterBuildString = response.response.characterBuildString;
        }

        // If this is the currently selected skin, update the character
        if (skinId === this._selectedSkin) {
            this._unityComponent!.setCharacter(this._characterBuildString!);
            
            await new Promise(resolve => setTimeout(resolve, 50));
            
            ValueContainer.characterImageBase64 = 
                await this._unityComponent!.generateCharacterPreview();
                
            try {
                const shouldUpdateAvatarImage = 
                    await CustomizationApi.shouldUpdateUserAvatar();
                    
                if (shouldUpdateAvatarImage) {
                    await this._uploadBase64Image(
                        ValueContainer.characterImageBase64
                    );
                }
            } catch (error) {
                return false;
            }
        }
        
        this._notifySubscribers();
        return true;
    }

    async setCharacter(skinId: string): Promise<boolean> {
        const response = await CustomizationApi.selectCharacter(skinId);

        if (!response.isSuccess) {
            return false;
        }

        this._characterBuildString = response.response.characterBuildString;
        this._selectedSkin = skinId;

        this._unityComponent!.setCharacter(this._characterBuildString!);

        await new Promise((resolve) => setTimeout(resolve, 50));

        ValueContainer.characterImageBase64 =
            await this._unityComponent!.generateCharacterPreview();

        try {
            const shouldUpdateAvatarImage =
                await CustomizationApi.shouldUpdateUserAvatar();

            if (shouldUpdateAvatarImage) {
                // convert from base64 to png
                await this._uploadBase64Image(
                    ValueContainer.characterImageBase64
                );
            }
        } catch (error) {
            return false;
        }

        return true;
    }

    async onUnload(): Promise<void> {}

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    update(sinceLastUpdate: TimeDuration): void {}

    async deactivatePet(petId: string): Promise<boolean> {
        const response = await CustomizationApi.deactivatePet(petId);
        if (!response.isSuccess) {
            return false;
        }
        this._pets = this._pets!.map((pet) =>
            pet.petId === petId ? { ...pet, isActivated: false } : pet
        );
        this._notifySubscribers();
        return true;
    }

    async activatePet(petId: string): Promise<boolean> {
        const response = await CustomizationApi.activatePet(petId).catch(
            (e) => undefined
        );
        if (!response) {
            return false;
        }
        this._updateOrAddPet(response!);
        return true;
    }

    private _updateOrAddPet(pet: Pet): void {
        let existingPet: Pet | undefined;
        existingPet = this._pets!.find((p) => p.petId === pet.petId);
        if (existingPet) {
            existingPet.isActivated = pet.isActivated;
            existingPet.position = pet.position;
            existingPet.targetPosition = pet.targetPosition;
        } else {
            this._pets!.push(pet);
        }
        this._notifySubscribers();
    }

    private _updateOrAddWithoutNotify(pet: Pet): void {
        let existingPet: Pet | undefined;
        existingPet = this._pets!.find((p) => p.petId === pet.petId);
        if (existingPet) {
            existingPet.isActivated = pet.isActivated;
            existingPet.position = pet.position;
            existingPet.targetPosition = pet.targetPosition;
        } else {
            this._pets!.push(pet);
        }
    }

    updatePets(friendTelegramIds: string[], onlineFriendInfos: FriendInfo[]) {
        const offlineFriendTelegramIds = friendTelegramIds.filter(
            (telegramId) =>
                !onlineFriendInfos.find(
                    (info) => info.telegramId === telegramId
                )
        );

        offlineFriendTelegramIds.forEach((telegramId) => {
            this.removePetsBelongingToWithoutNotify(telegramId);
        });

        const activePets = onlineFriendInfos
            .map((info) => info.activePets)
            .flat();

        activePets.forEach((pet) => {
            this._updateOrAddWithoutNotify(pet);
        });

        for (const friendInfo of onlineFriendInfos) {
            const currentActivePets = this._activePetsOf(friendInfo.telegramId);
            for (const pet of currentActivePets) {
                if (!friendInfo.activePets.find((p) => p.petId === pet.petId)) {
                    this.removePetWithoutNotify(pet.petId);
                }
            }
        }

        this._notifySubscribers();
    }

    _activePetsOf(telegramId: string): Pet[] {
        return this.activePets.filter(
            (pet) => pet.ownerTelegramId === telegramId
        );
    }

    async namePet(petId: string, name: string): Promise<boolean> {
        const response = await CustomizationApi.namePet(petId, name);
        if (!response.isSuccess) {
            return false;
        }
        this._pets = this._pets!.map((pet) =>
            pet.petId === petId ? { ...pet, name } : pet
        );
        this._notifySubscribers();
        return true;
    }

    private async _uploadBase64Image(base64: string) {
        // Check if a prefix exists and strip it if present
        const base64Data = base64.includes(',') ? base64.split(',')[1] : base64;

        // Convert base64 string to Blob
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i += 512) {
            const slice = byteCharacters.slice(i, i + 512);
            const byteNumbers = new Array(slice.length);
            for (let j = 0; j < slice.length; j++) {
                byteNumbers[j] = slice.charCodeAt(j);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: 'image/png' });

        // Create a File object from the Blob
        const file = new File([blob], 'avatar.png', { type: 'image/png' });

        // Upload the file using CustomizationApi
        await CustomizationApi.uploadUserAvatar(file);
    }

    private _userLevelUpHandler: MethodHandler = async (...args: any[]) => {
        const jsonStr = args[0];
        const json = JSON.parse(jsonStr);

        const level = json['Level'];

        ContainerHelper.getPreloadComponent().then((preloadComponent) => {
            void preloadComponent.getCachedSound(Sounds.levelUp).play();
        });

        toast.info(`You have reached level ${level}!`, {
            icon: () => (
                <img
                    src={Images.Icons.Star}
                    alt={'star'}
                    style={{ width: '24px', height: '24px' }}
                />
            ),
            autoClose: 5000,
            onClick: () => {
                ContainerHelper.getNavigationComponent().then(
                    (navigationComponent) => {
                        navigationComponent.navigateToIndex(4);
                    }
                );
            },
            style: {
                background: rarity_gradient.Legendary,
            },
        });
    };
}

export default CustomizationComponent;
