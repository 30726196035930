/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ConsolidateCryptoCurrencyModel,
  CreateAuctionListingModel,
  CreateMarketListingModel,
  MakeAcquiredOffer,
  MakeMarketListing,
  MarketListingSearchModel,
  MarketListingsResponse,
  PlaceBidModel,
  SubscribeToMarketListingsModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Market<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Market
   * @name RemoveMarketListingCreate
   * @request POST:/Market/RemoveMarketListing/{marketListingId}
   */
  removeMarketListingCreate = (marketListingId: string, params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/Market/RemoveMarketListing/${marketListingId}`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Market
   * @name PurchaseMarketListingCreate
   * @request POST:/Market/PurchaseMarketListing/{marketListingId}
   */
  purchaseMarketListingCreate = (marketListingId: string, params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/Market/PurchaseMarketListing/${marketListingId}`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Market
   * @name CreateMarketListingCreate
   * @request POST:/Market/CreateMarketListing
   */
  createMarketListingCreate = (data: CreateMarketListingModel, params: RequestParams = {}) =>
    this.request<MakeMarketListing, any>({
      path: `/Market/CreateMarketListing`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Market
   * @name GetMarketListingsCreate
   * @request POST:/Market/GetMarketListings
   */
  getMarketListingsCreate = (data: MarketListingSearchModel, params: RequestParams = {}) =>
    this.request<MarketListingsResponse, any>({
      path: `/Market/GetMarketListings`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Market
   * @name SubscribeToMarketListingsCreate
   * @request POST:/Market/SubscribeToMarketListings
   */
  subscribeToMarketListingsCreate = (data: SubscribeToMarketListingsModel, params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/Market/SubscribeToMarketListings`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Market
   * @name UnsubscribeFromAllMarketListingsCreate
   * @request POST:/Market/UnsubscribeFromAllMarketListings
   */
  unsubscribeFromAllMarketListingsCreate = (params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/Market/UnsubscribeFromAllMarketListings`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Market
   * @name ConsolidateCryptoCurrencyCreate
   * @request POST:/Market/ConsolidateCryptoCurrency
   */
  consolidateCryptoCurrencyCreate = (data: ConsolidateCryptoCurrencyModel, params: RequestParams = {}) =>
    this.request<MakeAcquiredOffer, any>({
      path: `/Market/ConsolidateCryptoCurrency`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Market
   * @name CreateAuctionListingCreate
   * @request POST:/Market/CreateAuctionListing
   */
  createAuctionListingCreate = (data: CreateAuctionListingModel, params: RequestParams = {}) =>
    this.request<MakeMarketListing, any>({
      path: `/Market/CreateAuctionListing`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Market
   * @name PlaceBidCreate
   * @request POST:/Market/PlaceBid
   */
  placeBidCreate = (data: PlaceBidModel, params: RequestParams = {}) =>
    this.request<any, any>({
      path: `/Market/PlaceBid`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
