import React from 'react';
import styles from '../../create_listing_flow.module.css';
import CryptoCurrencyInfo from '../../../../../apis/offer_api/crypto_currency_info';
import formatNumber, {
    formatTokenValue,
} from '../../../../../utils/currency_formatter';
import Decimal from 'decimal.js';

interface Step6Props {
    listingType: 'fixed' | 'auction';
    isCryptoSelected: boolean;
    cryptoAmount: number;
    selectedCrypto: CryptoCurrencyInfo | null;
    cryptoUsdValue: Decimal | null;
    price: number;
    startingBid: number;
    bidIncrement: number;
    hasReservePrice: boolean;
    reservePrice?: number;
    expiryDays: number;
    expiryHours: number;
    expiryMinutes: number;
    isFeatured?: boolean;
    transactionFee?: number;
    getSelectedItemDetails: () => {
        title: string;
        description: string;
        imageUrl: string;
    };
}

const Step6Review: React.FC<Step6Props> = ({
    listingType,
    isCryptoSelected,
    cryptoAmount,
    selectedCrypto,
    cryptoUsdValue,
    price,
    startingBid,
    bidIncrement,
    hasReservePrice,
    reservePrice,
    expiryDays,
    expiryHours,
    expiryMinutes,
    isFeatured,
    transactionFee,
    getSelectedItemDetails,
}) => {
    return (
        <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>Review & Confirm</h3>

            <div className={styles.reviewCard}>
                <div className={styles.reviewHeader}>
                    <div className={styles.itemImage}>
                        <img
                            src={getSelectedItemDetails().imageUrl}
                            alt="Selected item"
                        />
                    </div>
                    <div className={styles.itemDetails}>
                        <h4>{getSelectedItemDetails().title}</h4>
                    </div>
                </div>

                <div className={styles.reviewDetails}>
                    <div className={styles.reviewItem}>
                        <span className={styles.reviewLabel}>
                            Listing Type:
                        </span>
                        <span className={styles.reviewValue}>
                            {listingType === 'fixed'
                                ? 'Fixed Price'
                                : 'Auction'}
                        </span>
                    </div>

                    {isCryptoSelected && (
                        <>
                            <div className={styles.reviewItem}>
                                <span className={styles.reviewLabel}>
                                    Amount:
                                </span>
                                <span className={styles.reviewValue}>
                                    {formatNumber(cryptoAmount)}{' '}
                                    {selectedCrypto?.cryptoCurrency}
                                    {cryptoUsdValue !== null && (
                                        <span className={styles.usdValueInline}>
                                            {' '}
                                            {formatTokenValue(
                                                cryptoUsdValue,
                                                cryptoAmount
                                            )}
                                        </span>
                                    )}
                                </span>
                            </div>
                            {typeof transactionFee === 'number' && (
                                <div className={styles.reviewItem}>
                                    <span className={styles.reviewLabel}>
                                        Transaction Fee:
                                    </span>
                                    <span className={styles.reviewValue}>
                                        {formatNumber(transactionFee)}{' '}
                                        {selectedCrypto?.cryptoCurrency}
                                        {cryptoUsdValue !== null && (
                                            <span
                                                className={
                                                    styles.usdValueInline
                                                }
                                            >
                                                {' '}
                                                {formatTokenValue(
                                                    cryptoUsdValue,
                                                    transactionFee
                                                )}
                                            </span>
                                        )}
                                    </span>
                                </div>
                            )}
                        </>
                    )}

                    {listingType === 'fixed' ? (
                        <div className={styles.reviewItem}>
                            <span className={styles.reviewLabel}>Price:</span>
                            <span className={styles.reviewValue}>
                                {formatNumber(price)} DIG
                            </span>
                        </div>
                    ) : (
                        <>
                            <div className={styles.reviewItem}>
                                <span className={styles.reviewLabel}>
                                    Starting Bid:
                                </span>
                                <span className={styles.reviewValue}>
                                    {formatNumber(startingBid)} DIG
                                </span>
                            </div>
                            <div className={styles.reviewItem}>
                                <span className={styles.reviewLabel}>
                                    Bid Increment:
                                </span>
                                <span className={styles.reviewValue}>
                                    {formatNumber(bidIncrement)} DIG
                                </span>
                            </div>
                            {hasReservePrice && reservePrice && (
                                <div className={styles.reviewItem}>
                                    <span className={styles.reviewLabel}>
                                        Reserve Price:
                                    </span>
                                    <span className={styles.reviewValue}>
                                        {formatNumber(reservePrice)} DIG
                                    </span>
                                </div>
                            )}
                        </>
                    )}

                    <div className={styles.reviewItem}>
                        <span className={styles.reviewLabel}>Duration:</span>
                        <span className={styles.reviewValue}>
                            {expiryDays} days{' '}
                            {expiryHours > 0 ? `${expiryHours} hours` : ''}{' '}
                            {expiryMinutes > 0
                                ? `${expiryMinutes} minutes`
                                : ''}
                        </span>
                    </div>

                    <div className={styles.reviewItem}>
                        <span className={styles.reviewLabel}>Expires on:</span>
                        <span className={styles.reviewValue}>
                            {new Date(
                                new Date().getTime() +
                                    expiryDays * 24 * 60 * 60 * 1000 +
                                    expiryHours * 60 * 60 * 1000 +
                                    expiryMinutes * 60 * 1000
                            ).toLocaleDateString()}
                        </span>
                    </div>

                    {isFeatured && (
                        <div className={styles.reviewItem}>
                            <span className={styles.reviewLabel}>
                                Featured:
                            </span>
                            <span className={styles.reviewValue}>Yes</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Step6Review;
