import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import { TimeDuration } from 'typed-duration';
import ElementData from './element_data';
import ControlledPositionElement from '../../../ui/components/overlay/controlled_position_element';
import UnityComponent from '../unity/unity_component';
import React, { ReactNode } from 'react';
import { CurrencyType } from '../../../ui/components/currency_icon';
import AmountGainedFadeOut from '../../../ui/components/overlay/amount_gained_fade_out';
import ValueContainer from '../../../utils/value_container';
import { toast } from 'react-toastify';
import ContainerHelper from '../../component_container/utilities/container_helper';
import PopulationComponent from '../population/population_component';
import ComponentContainer from '../../component_container/component_container';
import LocationComponent from '../location/location_component';
import SettingsComponent from '../settings/settings_component';
import TooFarAwayFadeOut from '../../../ui/components/overlay/too_far_away_fade_out';
import UnityCameraMode from '../unity/unity_camera_mode';
import MapBubble from '../../../apis/models/map_bubble';
import MineLockFadeOut from '../../../ui/components/overlay/mine_lock_fade_out';
import QuestComponent from '../quest/quest_component';
import Images from '../preload/images';
import { rarity_gradient } from '../../../utils/constants';
import BubbleApi from '../../../apis/bubble_api/bubble_api';
import { confirmTutorialDialog } from '../../../ui/components/popup/tutorial_popup';
import IconFadeOut from '../../../ui/components/overlay/icon_fade_out';

type OverlayComponentSubscriber = (elements: React.JSX.Element[]) => void;
type OnBubbleTouchListener = (bubble: MapBubble) => void;

const TIME_BETWEEN_TURBO_ICON_FADEOUT = 5000;

class OverlayComponent extends Component {
    private _focusedBubble: MapBubble | undefined;
    get focusedBubble(): MapBubble | undefined {
        return this._focusedBubble;
    }

    private _unityComponent: UnityComponent | undefined;
    private _populationComponent: PopulationComponent | undefined;
    private _locationComponent: LocationComponent | undefined;
    private _settingsComponent: SettingsComponent | undefined;
    private _questComponent: QuestComponent | undefined;

    private _subscribers: OverlayComponentSubscriber[] = [];
    private _onBubbleTouchListeners: OnBubbleTouchListener[] = [];

    private _millisecondsSinceLastTurboIconFadeOut: number = 0;

    addSubscriber(subscriber: OverlayComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: OverlayComponentSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    addOnBubbleTouchListener(listener: OnBubbleTouchListener): void {
        this._onBubbleTouchListeners.push(listener);
    }

    removeOnBubbleTouchListener(listener: OnBubbleTouchListener): void {
        this._onBubbleTouchListeners = this._onBubbleTouchListeners.filter(
            (l) => l !== listener
        );
    }

    private _notifyOnBubbleTouchListeners(bubble: MapBubble): void {
        this._onBubbleTouchListeners.forEach((listener) => listener(bubble));
    }

    private _notifySubscribers(): void {
        this._elements = this._mapElements();
        this._subscribers.forEach((subscriber) => subscriber(this._elements));
    }

    private _elementData: ElementData[] = [];
    private _elements: React.JSX.Element[] = [];

    get elements(): React.JSX.Element[] {
        return this._elements;
    }

    async addElement(
        gameObject: string,
        children: ReactNode,
        lifetime: number | undefined,
        bottomOffset: number | undefined,
        idOverride?: string
    ): Promise<void> {
        const id = idOverride || Math.random().toString(36).substring(7);
        const ref = React.createRef<{
            setPosition: (x: number, y: number) => void;
            triggerFadeOut: () => void;
        }>();

        const screenPosition = await this._getScreenPosition(
            gameObject,
            'top',
            0
        );

        const element = new ElementData(
            id,
            children,
            ref,
            Date.now(),
            lifetime,
            gameObject,
            { x: screenPosition[0], y: screenPosition[1] },
            bottomOffset
        );
        this._elementData.push(element);
        this._notifySubscribers();
    }

    private _mapElements() {
        return this._elementData.map((element) => (
            <ControlledPositionElement
                key={element.id}
                ref={element.ref}
                children={element.children}
                initialPosition={element.initialPosition}
                bottomOffset={element.bottomOffset || 50}
            />
        ));
    }

    private _coinsObservableListener(
        oldValue: number | undefined,
        newValue: number
    ) {
        if (oldValue === undefined) {
            return;
        }
        const amount = newValue - oldValue;
        if (amount > 0) {
            this.addElement(
                'Character',
                <AmountGainedFadeOut
                    amount={amount}
                    currencyType={CurrencyType.Coin}
                />,
                2000,
                1
            );
        }
    }

    async load(): Promise<Array<ComponentError>> {
        await this.setDependencyLocked([UnityComponent]);
        this._unityComponent = await this.getComponent(UnityComponent).then(
            (component) => component as UnityComponent
        );

        ValueContainer.coinsObservable.addListener(
            this._coinsObservableListener.bind(this)
        );

        ContainerHelper.getSignalRComponent().then((signalRComponent) => {
            signalRComponent.registerMethodHandler(
                'ReceiveNotification',
                this._receiveNotificationMethodHandler.bind(this)
            );
        });

        ComponentContainer.instance!.makeSureLoaded.then(async () => {
            this._populationComponent = await this.getComponent(
                PopulationComponent
            ).then((component) => component as PopulationComponent);
            this._locationComponent = await this.getComponent(
                LocationComponent
            ).then((component) => component as LocationComponent);
            this._settingsComponent = await this.getComponent(
                SettingsComponent
            ).then((component) => component as SettingsComponent);
            this._questComponent = await this.getComponent(QuestComponent).then(
                (component) => component as QuestComponent
            );

            this._unityComponent!.addEventListener(
                this._unityTouchEventListener.bind(this)
            );

            this._populationComponent!.addComponentObserver(
                this._populationComponentObserver.bind(this)
            );

            this._locationComponent!.addPositionListener(
                this._locationListener.bind(this)
            );

            this._questComponent!.addComponentObserver(
                this._questComponentObserver.bind(this)
            );

            const telegramComponent =
                await ContainerHelper.getTelegramComponent();

            if (telegramComponent.isNewUser) {
                await confirmTutorialDialog({
                    options: {
                        onConfirm: () => {
                            return true;
                        },
                    },
                });
            }
        });

        return [];
    }

    private _locationListener(
        previousPosition: GeolocationPosition,
        position: GeolocationPosition,
        sinceLastPositionTime: TimeDuration
    ) {
        const bubbleRange =
            this._settingsComponent!.getDoubleFromClientSettings(
                'BubbleRange',
                99
            );
        const bubblesInRange = this._populationComponent!.getBubblesInRange(
            position.coords.latitude,
            position.coords.longitude,
            bubbleRange
        );

        for (const element of this._elementData) {
            if (!element.id.includes('_lock')) {
                continue;
            }

            const bubbleId = Number(element.id.split('_')[0]);

            if (!bubblesInRange.find((b) => b.id === bubbleId)) {
                element.lifetime = 1; // Trigger fade out and make sure getPosition is not called
            }
        }

        const rarityReferralAmount =
            this._settingsComponent!.rarityRequiredReferralCount;

        for (const bubble of bubblesInRange) {
            if (bubble.metadata?.Type === 'Turbo') {
                continue;
            }

            const element = this._elementData.find(
                (e) => e.id === `${bubble.id}_lock`
            );

            if (element) {
                continue;
            }

            const rarity = bubble.metadata?.Rarity;

            const referralsRequired = rarityReferralAmount.get(rarity)!;

            if (referralsRequired === 0) {
                continue;
            }

            if (this._questComponent!.allReferralsCount >= referralsRequired) {
                continue;
            }

            const referralsLeft =
                referralsRequired - this._questComponent!.allReferralsCount;

            this.addElement(
                bubble.id.toString(),
                <MineLockFadeOut
                    backgroundColor={'transparent'}
                    referralsRequired={referralsLeft}
                    rarity={rarity!}
                />,
                undefined,
                0,
                `${bubble.id}_lock`
            );
        }
    }

    private _populationComponentObserver() {
        for (const element of this._elementData) {
            if (!element.id.includes('_lock')) {
                continue;
            }

            const bubbleId = Number(element.id.split('_')[0]);
            const bubble = this._populationComponent!.getBubble(bubbleId);

            if (!bubble) {
                element.lifetime = 1; // Trigger fade out and make sure getPosition is not called
            }
        }

        if (!this._focusedBubble) {
            return;
        }

        const bubble = this._populationComponent!.getBubble(
            this._focusedBubble.id
        );

        if (bubble) {
            return;
        }

        this._focusedBubble = undefined;
        this._notifyObservers(undefined);
        this._notifySubscribers();
    }

    private _questComponentObserver() {
        for (const element of this._elementData) {
            if (!element.id.includes('_lock')) {
                continue;
            }

            const bubbleId = Number(element.id.split('_')[0]);
            const bubble = this._populationComponent!.getBubble(bubbleId);

            const rarity = bubble?.metadata?.Rarity;
            const referralsRequired =
                this._settingsComponent!.rarityRequiredReferralCount.get(
                    rarity!
                )!;
            const referralsLeft =
                referralsRequired - this._questComponent!.allReferralsCount;

            if (referralsLeft <= 0) {
                element.lifetime = 1; // Trigger fade out and make sure getPosition is not called
            }
        }
    }

    private async _unityTouchEventListener(event: string, args: any[]) {
        if (event === 'gameObject:touch:event') {
            const gameObject = args[0] as string;
            const id = Number(gameObject);
            if (isNaN(id)) {
                return;
            }

            const bubble = this._populationComponent!.getBubble(id);

            if (!bubble) {
                return;
            }

            const lastPosition = this._locationComponent!.lastConfirmedPosition;

            if (!lastPosition) {
                this.addElement(gameObject, <TooFarAwayFadeOut />, 2000, 0);
                return;
            }

            if (bubble.metadata?.Type === 'Turbo') {
                return;
            }

            // check distance to bubble
            const distance = LocationComponent.getDistanceBetweenTwoPoints(
                lastPosition.coords.latitude,
                lastPosition.coords.longitude,
                bubble.latitude,
                bubble.longitude
            );
            const bubbleRange =
                this._settingsComponent!.getDoubleFromClientSettings(
                    'BubbleRange',
                    99
                );

            if (distance > bubbleRange) {
                this.addElement(gameObject, <TooFarAwayFadeOut />, 2000, 0);
                return;
            }

            const referralsRequired =
                this._settingsComponent!.rarityRequiredReferralCount.get(
                    bubble.metadata?.Rarity!
                )! - this._questComponent!.allReferralsCount;

            if (referralsRequired > 0) {
                // TODO: Show referral dialog
                toast.info(
                    `You need ${referralsRequired} more ${referralsRequired === 1 ? 'referral' : 'referrals'} to unlock ${bubble.metadata?.Rarity} Mines`,
                    {
                        icon: () => (
                            <img
                                src={Images.Icons.IconRef}
                                alt={'referral'}
                                style={{ width: '24px', height: '24px' }}
                            />
                        ),
                        autoClose: 5000,
                        onClick: () => {
                            ContainerHelper.getNavigationComponent().then(
                                (navigationComponent) => {
                                    navigationComponent.navigateToIndex(3);
                                }
                            );
                        },
                        style: {
                            background:
                                // @ts-ignore
                                rarity_gradient[bubble.metadata?.Rarity!],
                        },
                    }
                );
                return;
            }

            const canOpenResponse = await BubbleApi.canOpenBubble(bubble.id);

            if (!canOpenResponse.isSuccess) {
                if (canOpenResponse.error === 'LAST_PLAYABLE_TOO_FAR_AWAY') {
                    toast.error("YOU'RE MOVING TOO FAST"); // TODO: tr()
                } else if (canOpenResponse.error === 'INVALID_PLAYABLE_GROUP') {
                    toast.info('PLEASE WALK AROUND BEFORE MINING THIS MINE'); // TODO: tr()
                } else {
                    toast.error(canOpenResponse.error);
                }
                return;
            }

            this._notifyOnBubbleTouchListeners(bubble);

            if (this._focusedBubble !== bubble) {
                this._focusedBubble = bubble;
                this._notifyObservers(undefined);
                this._notifySubscribers();
            }

            this._unityComponent!.setCameraMode(
                UnityCameraMode.FOCUS,
                gameObject
            );
        }
    }

    private async _getScreenPosition(
        gameObject: string,
        position: string,
        offset: number
    ): Promise<[number, number]> {
        if (!this._unityComponent) {
            throw new Error('Unity component not loaded');
        }

        let screenPos = await this._unityComponent.getObjectScreenPosition(
            gameObject,
            position,
            offset
        );

        screenPos[0] = screenPos[0] / window.devicePixelRatio;
        screenPos[1] = screenPos[1] / window.devicePixelRatio;

        return screenPos;
    }

    get name(): string {
        return 'Overlay Component';
    }

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    async onUnload(): Promise<void> {
        ValueContainer.coinsObservable.removeListener(
            this._coinsObservableListener.bind(this)
        );

        ContainerHelper.getSignalRComponent().then((signalRComponent) => {
            signalRComponent.unregisterMethodHandler(
                'ReceiveNotification',
                this._receiveNotificationMethodHandler.bind(this)
            );
        });

        this._unityComponent!.removeEventListener(
            this._unityTouchEventListener.bind(this)
        );

        this._populationComponent!.removeComponentObserver(
            this._populationComponentObserver.bind(this)
        );

        this._questComponent!.removeComponentObserver(
            this._questComponentObserver.bind(this)
        );

        this._locationComponent!.removePositionListener(
            this._locationListener.bind(this)
        );
    }

    get type(): Function {
        return OverlayComponent;
    }

    private _receiveNotificationMethodHandler(...args: any[]) {
        const text = args[0] as string;
        toast(text);
    }

    update(sinceLastUpdate: TimeDuration): void {
        this._millisecondsSinceLastTurboIconFadeOut += sinceLastUpdate.value;

        if (
            this._millisecondsSinceLastTurboIconFadeOut >=
            TIME_BETWEEN_TURBO_ICON_FADEOUT
        ) {
            this._millisecondsSinceLastTurboIconFadeOut = 0;

            // check if there are any bubbles in range
            const bubbles = this._populationComponent!.getBubbles();

            // loop through bubbles
            for (const bubble of bubbles) {
                if (bubble.metadata?.Type !== 'Turbo') {
                    continue;
                }

                this.addElement(
                    bubble.id.toString(),
                    <IconFadeOut
                        icon={Images.Icons.SpeedBoost}
                        iconSize={25}
                    />,
                    2000,
                    1
                );
            }
        }

        this._elementData.forEach((element) => {
            if (element.gameObject) {
                // check if lifetime is set and if it has expired
                // use date.now - created date
                // if expired, trigger fade out and remove from array
                if (
                    element.lifetime &&
                    Date.now() - element.created > element.lifetime
                ) {
                    if (element.ref.current) {
                        element.ref.current.triggerFadeOut();
                    }

                    // check if more than 5000ms has passed since lifetime expired
                    // if so, remove element from array
                    if (
                        Date.now() - element.created >
                        element.lifetime + 5000
                    ) {
                        this._elementData = this._elementData.filter(
                            (e) => e.id !== element.id
                        );
                        this._notifySubscribers();
                    }
                    return;
                }

                this._getScreenPosition(element.gameObject, 'top', 0).then(
                    (position) => {
                        if (element.ref.current) {
                            element.ref.current.setPosition(
                                position[0],
                                position[1]
                            );
                        }
                    }
                );
            }
        });
    }
}

export default OverlayComponent;
