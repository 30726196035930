import cratePickup from '../../../assets/sounds/crate-pickup.mp3';
import dig from '../../../assets/sounds/dig.mp3';
import multiCoinPayout5 from '../../../assets/sounds/multi-coin-payout-5.mp3';
import newItem from '../../../assets/sounds/new-item.mp3';
import purchaseSuccessful from '../../../assets/sounds/purchase-successful.mp3';
import spinTick from '../../../assets/sounds/spin-longer.mp3';
import treasure from '../../../assets/sounds/treasure.mp3';
import levelUp from '../../../assets/sounds/level-up.mp3';

const Sounds = {
    cratePickup,
    dig,
    multiCoinPayout5,
    newItem,
    purchaseSuccessful,
    spinTick,
    treasure,
    levelUp,
};

export default Sounds;
