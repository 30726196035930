import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import AuthenticationComponent from '../authentication/authentication_component';
import Task from '../../../apis/models/task/task';
import QuestApi from '../../../apis/quest_api/quest_api';
import SignalRComponent from '../signalr/signalr_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import SignalRConnected from '../signalr/signalr_connected';
import TaskCompletion from '../../../apis/models/task/task_completed';
import Referral from '../../../apis/models/task/referral';
import StandardHttpResponse from '../../../apis/models/standard_http_response';
import { toast } from 'react-toastify';
import TaskStatus from '../../../apis/models/task/task_status';
import GroupedTasks from '../../../apis/models/task/task_group';
import ToplistReferrals from '../../../apis/models/task/toplist_referrals';
import ReferralSummary from '../../../apis/models/task/referralSummary';
import CryptoSummary from '../../../apis/models/task/cryptoSummary';

type QuestComponentSubscriber = () => void;

class QuestComponent extends Component {
    private _tasks: Task[] = [];
    private _referrals: Referral[] = [];
    private _referralsSummary: ReferralSummary | null = null;
    private _toplistReferrals: ToplistReferrals | null = null;

    private _subscribers: QuestComponentSubscriber[] = [];
    private _reFetching: boolean = false;

    get tasks(): Task[] {
        return this._tasks;
    }

    get referrals(): Referral[] {
        return this._referrals;
    }

    get referralsSummary(): ReferralSummary | null {
        return this._referralsSummary;
    }

    get referralsCount(): number {
        return this._referralsSummary?.validatedCount || 0;
    }

    get referralsNotValidatedCount(): number {
        return this._referralsSummary?.notValidatedCount || 0;
    }

    get allReferralsCount(): number {
        return this.referralsCount + this.referralsNotValidatedCount;
    }

    get referralDIGTokensEarned(): number {
        
        //Get the total tokens earned from the crypto summaries whereCytptoCurrency is DIG
        const digTokens =
            this._referralsSummary?.cryptoSummaries.find(
                (cryptoSummary) => cryptoSummary.cryptoCurrency === 'DIG'
            )?.totalTokensEarned || 0;
        return digTokens;
    }

    get referralTokensEarned(): CryptoSummary[] {
        return this._referralsSummary?.cryptoSummaries || [];
    }

    get toplistReferrals(): ToplistReferrals | null {
        return this._toplistReferrals;
    }

    get groupedTasks(): GroupedTasks {
        // Sort the data by partnerTask (false first) and then by partnerName
        this._tasks.sort((a, b) => {
            if (a.partnerTask === b.partnerTask) {
                //Sort by partnerName in descending order
                return (b.partnerName || "").localeCompare(a.partnerName || "");
            }
            return a.partnerTask ? 1 : -1; // false first, true second
        });
    
        // Group the data
        const grouped: GroupedTasks = {};
    
        this._tasks.forEach((task) => {
            const partnerTaskKey = task.partnerTask.toString();
            const partnerNameKey = task.partnerName || "None";
    
            if (!grouped[partnerTaskKey]) {
                grouped[partnerTaskKey] = {};
            }
    
            if (!grouped[partnerTaskKey][partnerNameKey]) {
                grouped[partnerTaskKey][partnerNameKey] = [];
            }
    
            grouped[partnerTaskKey][partnerNameKey].push(task);
        });
    
        return grouped;
    }

    addSubscriber(subscriber: QuestComponentSubscriber): void {
        this._subscribers.push(subscriber);
    }

    removeSubscriber(subscriber: QuestComponentSubscriber): void {
        this._subscribers = this._subscribers.filter(
            (existingSubscriber) => existingSubscriber !== subscriber
        );
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
        this._notifyObservers(undefined);
    }

    private async _initValues(): Promise<boolean> {
        try {
            this._tasks = await QuestApi.getTasks();
            //this._referrals = await QuestApi.getReferrals();
            this._referralsSummary = await QuestApi.getReferralSummary();
            this._toplistReferrals = await QuestApi.getToplistReferrals();

            this._notifySubscribers();
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    get type(): Function {
        return QuestComponent;
    }

    get name(): string {
        return 'Quest Component';
    }
    private _taskCompletedMethodHandler(...args: any[]) {
        const taskCompleted = TaskCompletion.fromJsonUpperCase(
            JSON.parse(args[0])
        );
        this._tasks = this._tasks.map((task) => {
            if (task.taskId === taskCompleted.taskId) {
                // Update the matching task with new data
                return new Task({
                    ...task,
                    completedAt: taskCompleted.completedAt,
                    pointsEarned: taskCompleted.pointsEarned,
                    tokensEarned: taskCompleted.tokensEarned,
                    status: taskCompleted.status,
                    verified: true,
                });
            }
            return task; // Leave other tasks unchanged
        });
        this._notifySubscribers();
        if (taskCompleted.tokensEarned > 0) {
            toast.success(
                `Task completed! You earned ${taskCompleted.tokensEarned} DIG tokens`
            );
        }
    }

    private _referralCompletedMethodHandler(...args: any[]) {
        const referral = Referral.fromJsonUpperCase(JSON.parse(args[0]));

        // Subtract 1 from notValidatedCount if greater than 0
        const updatedNotValidatedCount = Math.max(
            (this._referralsSummary?.notValidatedCount || 0) - 1,
            0
        );

        // Update crypto summaries and add a new one if not present
        const existingCryptoSummaries = this._referralsSummary?.cryptoSummaries || [];
        let cryptoExists = false;

        const updatedCryptoSummaries = existingCryptoSummaries.map((cryptoSummary) => {
            if (cryptoSummary.cryptoCurrency === referral.cryptoCurrency) {
                cryptoExists = true;
                return new CryptoSummary({
                    ...cryptoSummary,
                    totalTokensEarned: cryptoSummary.totalTokensEarned + referral.tokensEarned,
                });
            }
            return cryptoSummary;
        });

        // Add new CryptoSummary if it doesn't exist
        if (!cryptoExists) {
            updatedCryptoSummaries.push(
                new CryptoSummary({
                    cryptoCurrency: referral.cryptoCurrency,
                    coverUrl: referral.coverUrl,
                    totalTokensEarned: referral.tokensEarned,
                })
            );
        }

        this._referralsSummary = new ReferralSummary({
            validatedCount: (this._referralsSummary?.validatedCount || 0) + 1,
            notValidatedCount: updatedNotValidatedCount,
            cryptoSummaries: updatedCryptoSummaries,
        });    
        
        this._notifySubscribers();

        toast.success('referralCompleted'.tr());
    }

    private async _connectionStateChangedEventHandler(state: SignalRConnected) {
        if (state === SignalRConnected.Connected) {
            if (this._reFetching) {
                return;
            }
            let fetched = false;
            this._reFetching = true;
            while (!fetched) {
                fetched = await this._initValues();
                if (!fetched) {
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                } else {
                    this._notifySubscribers();
                }
            }
            this._reFetching = false;
        }
    }

    async load(): Promise<Array<ComponentError>> {
        await this.setDependencyLocked([AuthenticationComponent]);
        const initiated = await this._initValues();
        if (!initiated) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'questComponentFailedToLoad'
                ),
            ]; // TODO: tr()
        }
        await this.setDependencyLocked([SignalRComponent]);
        const signalRComponent = await ContainerHelper.getSignalRComponent();

        signalRComponent.registerMethodHandler(
            'TaskCompleted',
            this._taskCompletedMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'ReferralCompleted',
            this._referralCompletedMethodHandler.bind(this)
        );

        signalRComponent.addConnectionStateChangedEventHandler(
            this._connectionStateChangedEventHandler.bind(this)
        );

        return [];
    }

    async onUnload(): Promise<void> {}

    async onPause(): Promise<void> {}

    async onResume(): Promise<void> {}

    update(sinceLastUpdate: TimeDuration): void {}

    async startTask(taskId: string): Promise<boolean> {
        this._tasks = this._tasks.map((task) => {
            if (task.taskId === taskId) {
                return new Task({
                    ...task,
                    status: TaskStatus.Started,
                });
            }
            return task;
        });
        this._notifySubscribers();
        return true;
    }

    async verifyTask(taskId: string): Promise<boolean> {
        try {
            const completion = await QuestApi.verifyTask(taskId);
            this._tasks = this._tasks.map((task) => {
                if (task.taskId === completion.taskId) {
                    return new Task({
                        ...task,
                        completedAt: completion.completedAt,
                        pointsEarned: completion.pointsEarned,
                        tokensEarned: completion.tokensEarned,
                        status: completion.status,
                        estCompletionAt: completion.estCompletionAt,
                        verified: completion.status === TaskStatus.Completed,
                    });
                }
                return task;
            });
            this._notifySubscribers();
            if (completion.tokensEarned > 0) {
                toast.success(
                    `You earned ${completion.tokensEarned} DIG tokens!`
                );
            }
            return true;
        } catch (error: any) {
            var errorData = error as StandardHttpResponse;
            if (errorData && errorData.hasErrorMessage) {
                toast.error(errorData.errorMessage);
            }
            return false;
        }
    }
}

export default QuestComponent;
