import { TimeDuration } from 'typed-duration';
import Component from '../../component_container/models/component';
import ComponentError from '../../component_container/models/component_error';
import AcquiredOffer from '../../../apis/offer_api/acquired_offer';
import OfferApi from '../../../apis/offer_api/offer_api';
import AuthenticationComponent from '../authentication/authentication_component';
import ComponentErrorType from '../../component_container/enums/component_error_type';
import SignalRComponent from '../signalr/signalr_component';
import ContainerHelper from '../../component_container/utilities/container_helper';
import SignalRConnected from '../signalr/signalr_connected';
import CompactJsonOffer from '../../../apis/offer_api/compact_json_offer';
import BubbleApi from '../../../apis/bubble_api/bubble_api';
import StandardHttpResponse from '../../../apis/models/standard_http_response';
import MapBubble from '../../../apis/models/map_bubble';
import OverlayComponent from '../overlay/overlay_component';
import { toast } from 'react-toastify';
import CryptoCurrencyInfo from '../../../apis/offer_api/crypto_currency_info';
import ClaimedOffer from '../../../apis/offer_api/claimed_offers';
import formatNumber from '../../../utils/currency_formatter';
import Decimal from 'decimal.js';
import VaultOfferType from '../../../apis/offer_api/vault_offer_type';
import { Item } from '../../../apis/customization_api/item';

type OfferSubscriber = () => void;

class OfferComponent extends Component {
    private _subscribers: OfferSubscriber[] = [];

    private _overlayComponent: OverlayComponent | undefined = undefined;
    private _focusedBubble: MapBubble | undefined = undefined;

    private _newAcquisition: AcquiredOffer | undefined = undefined;
    private _newAcquisitionSeenDate: Date | undefined = undefined;

    private _showDollarWorth: boolean = false;

    get newAcquisition(): AcquiredOffer | undefined {
        return this._newAcquisition;
    }

    markNewAcquisitionAsSeen(): void {
        if (this._newAcquisition) {
            this._newAcquisitionSeenDate = new Date();
        }
    }

    get focusedBubble(): MapBubble | undefined {
        return this._focusedBubble;
    }

    private _mineableOffers: CompactJsonOffer[] = [];
    get mineableOffers(): CompactJsonOffer[] {
        return this._mineableOffers;
    }

    private _minedOffer: AcquiredOffer | undefined = undefined;
    get minedOffer(): AcquiredOffer | undefined {
        return this._minedOffer;
    }

    registerSubscriber(subscriber: OfferSubscriber): void {
        this._subscribers.push(subscriber);
    }

    unregisterSubscriber(subscriber: OfferSubscriber): void {
        this._subscribers = this._subscribers.filter((s) => s !== subscriber);
    }

    private _notifySubscribers(): void {
        this._subscribers.forEach((subscriber) => subscriber());
        this._notifyObservers(undefined);
    }

    private _acquiredOffers: CryptoCurrencyInfo[] | undefined = undefined;
    private _otherAcquiredOffers: AcquiredOffer[] | undefined = undefined;

    get acquiredOffers(): CryptoCurrencyInfo[] {
        return this._acquiredOffers || [];
    }

    get otherAcquiredOffers(): AcquiredOffer[] {
        return this._otherAcquiredOffers || [];
    }

    public getCryptoCurrencyImageUri(
        cryptoCurrency: string
    ): string | undefined {
        return this.acquiredOffers.find(
            (offer) => offer.cryptoCurrency === cryptoCurrency
        )?.coverUrl;
    }

    private _claims: ClaimedOffer[] = [];

    get claims(): ClaimedOffer[] {
        return this._claims;
    }

    get totalWorth(): Decimal {
        return this.acquiredOffers.reduce(
            (acc, offer) =>
                acc.plus(
                    offer.quotePriceUSD.mul(offer.cryptoCurrencyAmount || 0)
                ),
            new Decimal(0)
        );
    }

    get showDollarWorth(): boolean {
        return this._showDollarWorth;
    }

    set showDollarWorth(value: boolean) {
        this._showDollarWorth = value;
    }

    // get groupedOffers():  GroupedOffer[]  {
    //     const grouped = this.acquiredOffers.reduce((acc, offer) => {
    //         const existing = acc.get(offer.cryptoCurrency??'');
    //         if (existing) {
    //           existing.totalCryptoCurrencyAmount += offer.cryptoCurrencyAmount??0;
    //           existing.offers.push(offer);
    //         } else {
    //           acc.set(offer.cryptoCurrency??'', {
    //             cryptoCurrency: offer.cryptoCurrency,
    //             offerName: offer.offerTitle,
    //             totalCryptoCurrencyAmount: offer.cryptoCurrencyAmount??0,
    //             offerDescription: offer.description,
    //             offers: [offer],
    //           });
    //         }
    //         return acc;
    //       }, new Map<string, GroupedOffer>());
    //
    //       return Array.from(grouped.values());
    // }

    private _fetching: boolean = false;
    get fetching(): boolean {
        return this._fetching;
    }

    async markOfferAsSeen(uniqueOfferId: string): Promise<void> {
        // TODO: Implement
    }

    private async _initValues(): Promise<void> {
        const vault = await OfferApi.getVault();

        if (!vault) {
            return Promise.reject();
        }

        this._acquiredOffers = vault.cryptoCurrencies;
        this._otherAcquiredOffers = vault.otherAcquiredOffers;
        this._claims = vault.claimedOffers;
    }

    get type(): Function {
        return OfferComponent;
    }

    get name(): string {
        return 'Offer Component';
    }

    async load(): Promise<ComponentError[]> {
        await this.setDependencyLocked([AuthenticationComponent]);

        const initSuccess = await this._initValues()
            .then(() => true)
            .catch(() => false);

        if (!initSuccess) {
            return [
                new ComponentError(
                    ComponentErrorType.LoadError,
                    'offerComponentFailedToLoad'.tr()
                ),
            ];
        }

        await this.setDependencyLocked([SignalRComponent]);

        const signalRComponent = await ContainerHelper.getSignalRComponent();

        // Update crypto amount after opening LootBox, sends the total amount of the crypto currency
        signalRComponent.registerMethodHandler(
            'UpdateCryptoBalance',
            this._updateCryptoBalanceMethodHandler.bind(this)
        );

        // Update crypto amount after referral reward. Only added amount is sent
        signalRComponent.registerMethodHandler(
            'UpdateCryptoAmount',
            this._updateCryptoAmountMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'OfferAcquired',
            this._offerAcquiredMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'RemoveAcquiredOffer',
            this._removeAcquiredOfferMethodHandler.bind(this)
        );

        signalRComponent.registerMethodHandler(
            'OfferRedeemed',
            this._offerRedeemedMethodHandler.bind(this)
        );

        signalRComponent.addConnectionStateChangedEventHandler(
            this._connectionStateChangedEventHandler.bind(this)
        );

        ContainerHelper.getOverlayComponent().then((overlayComponent) => {
            this._overlayComponent = overlayComponent;
            overlayComponent.addSubscriber(
                this._overlayComponentSubscriber.bind(this)
            );
        });

        return [];
    }

    private _updateCryptoBalanceMethodHandler(...args: any[]): void {
        const jsonStr = args[0];
        const json = JSON.parse(jsonStr);
        const cryptoCurrency = json.CryptoCurrency;
        const amount = json.CryptoCurrencyAmount;

        const cryptoCurrencyInfo = this._acquiredOffers?.find(
            (offer) => offer.cryptoCurrency === cryptoCurrency
        );

        if (!cryptoCurrencyInfo) {
            return;
        }

        if (isNaN(amount)) {
            return;
        }

        cryptoCurrencyInfo.cryptoCurrencyAmount = amount;
        this._notifySubscribers();
    }

    private _updateCryptoAmountMethodHandler(...args: any[]): void {
        const jsonStr = args[0];
        const json = JSON.parse(jsonStr);
        const cryptoCurrency = json.CryptoCurrency;
        const amount = json.CryptoCurrencyAmount;

        const cryptoCurrencyInfo = this._acquiredOffers?.find(
            (offer) => offer.cryptoCurrency === cryptoCurrency
        );

        if (!cryptoCurrencyInfo) {
            return;
        }

        if (isNaN(amount)) {
            return;
        }

        if (cryptoCurrencyInfo.cryptoCurrencyAmount) {
            cryptoCurrencyInfo.cryptoCurrencyAmount += amount;
        } else {
            cryptoCurrencyInfo.cryptoCurrencyAmount = amount;
        }

        this._notifySubscribers();
    }

    private _offerAcquiredMethodHandler(...args: any[]): void {
        const jsonStr = args[0];
        const json = JSON.parse(jsonStr);
        const acquiredOffer = AcquiredOffer.fromJson(json);

        // this._acquiredOffers!.push(acquiredOffer);

        const cryptoCurrencyInfo =
            acquiredOffer.vaultOfferType === VaultOfferType.Crypto
                ? this._acquiredOffers?.find(
                      (offer) =>
                          offer.cryptoCurrency === acquiredOffer.cryptoCurrency
                  )
                : undefined;

        if (!cryptoCurrencyInfo) {
            if (acquiredOffer.vaultOfferType === VaultOfferType.Crypto) {
                throw new Error('Crypto currency info not found');
            }

            this._otherAcquiredOffers?.push(acquiredOffer);
            this._newAcquisition = acquiredOffer;
            this._newAcquisitionSeenDate = undefined;
            this._notifySubscribers();

            return;
        }

        if (cryptoCurrencyInfo.cryptoCurrencyAmount) {
            cryptoCurrencyInfo.cryptoCurrencyAmount +=
                acquiredOffer.cryptoCurrencyAmount || 0;
        } else {
            cryptoCurrencyInfo.cryptoCurrencyAmount =
                acquiredOffer.cryptoCurrencyAmount || 0;
        }

        this._newAcquisition = acquiredOffer;
        this._newAcquisitionSeenDate = undefined;

        this._notifySubscribers();
    }

    private _removeAcquiredOfferMethodHandler(...args: any[]): void {
        const jsonStr = args[0] as string;
        const acquiredOffer = AcquiredOffer.fromJson(JSON.parse(jsonStr));
        // TODO: re-implement this but pass the amount of the offer that was removed (for claim)

        // this._acquiredOffers = this._acquiredOffers?.filter(
        //     (offer) => offer.uniqueId !== uniqueId
        // );

        // in case of not cryptocurrency offer
        this._otherAcquiredOffers = this._otherAcquiredOffers?.filter(
            (offer) => offer.uniqueId !== acquiredOffer.uniqueId
        );

        const cryptoCurrencyInfo =
            acquiredOffer.vaultOfferType === VaultOfferType.Crypto
                ? this._acquiredOffers?.find(
                      (offer) =>
                          offer.cryptoCurrency === acquiredOffer.cryptoCurrency
                  )
                : undefined;

        if (cryptoCurrencyInfo && acquiredOffer.cryptoCurrencyAmount) {
            cryptoCurrencyInfo.cryptoCurrencyAmount = Math.max(
                (cryptoCurrencyInfo.cryptoCurrencyAmount || 0) -
                    acquiredOffer.cryptoCurrencyAmount,
                0
            );
        }

        this._notifySubscribers();
    }

    private _offerRedeemedMethodHandler(...args: any[]): void {
        const uniqueId = args[0];

        // const acquiredOffer = this._acquiredOffers?.find(
        //     (offer) => offer.uniqueId === uniqueId
        // );
        //
        // if (acquiredOffer) {
        //     acquiredOffer.isUsed = true;
        // }

        this._notifySubscribers();
    }

    private async _connectionStateChangedEventHandler(state: SignalRConnected) {
        if (state === SignalRConnected.Connected) {
            this.fetch();
        }
    }

    private _overlayComponentSubscriber(): void {
        if (this._focusedBubble !== this._overlayComponent!.focusedBubble) {
            this._focusedBubble = this._overlayComponent!.focusedBubble;
            this._onFocusedBubbleChanged();
        }
    }

    private _onFocusedBubbleChanged(): void {
        console.error(this._focusedBubble);
        if (this._focusedBubble) {
            console.error(this._focusedBubble);
            this.getOffersForBubble(this._focusedBubble.id)
                .then((offers) => {
                    if (offers.length > 0) {
                        this.purchaseOffer(
                            offers[0].offerId,
                            navigator.userAgent,
                            window.innerHeight,
                            window.innerWidth
                        );
                    }
                    // this._notifySubscribers();
                    console.error(offers);
                })
                .catch((e) => {
                    console.error(e);
                });
        } else {
            this._mineableOffers = [];
            this._notifySubscribers();
        }
    }

    async fetch(): Promise<void> {
        if (this._fetching) {
            return;
        }
        let fetched = false;
        this._fetching = true;

        this._notifySubscribers();

        while (!fetched) {
            fetched = await this._initValues()
                .then(() => true)
                .catch(() => false);

            if (!fetched) {
                await new Promise((resolve) => setTimeout(resolve, 2000));
            } else {
                this._fetching = false;
                this._notifySubscribers();
            }
        }

        this._fetching = false;
    }

    async onUnload(): Promise<void> {}
    async onPause(): Promise<void> {}
    async onResume(): Promise<void> {}
    update(sinceLastUpdate: TimeDuration): void {
        if (this._newAcquisition && this._newAcquisitionSeenDate) {
            // If the new acquisition has been seen for 5 seconds, clear it
            const now = new Date();
            if (now.getTime() - this._newAcquisitionSeenDate.getTime() > 5000) {
                this._newAcquisition = undefined;
                this._newAcquisitionSeenDate = undefined;
                this._notifySubscribers();
            }
        }
    }

    async getOffersForBubble(
        bubbleId: number | string
    ): Promise<CompactJsonOffer[]> {
        return await BubbleApi.getOffersForBubble(bubbleId);
    }

    async hasEnoughCryptoCurrency(
        cryptoCurrency: string,
        amount: number
    ): Promise<boolean> {
        const cryptoCurrencyInfo = this._acquiredOffers?.find(
            (offer) => offer.cryptoCurrency === cryptoCurrency
        );

        if (!cryptoCurrencyInfo || !cryptoCurrencyInfo.cryptoCurrencyAmount) {
            return false;
        }

        return cryptoCurrencyInfo.cryptoCurrencyAmount >= amount;
    }

    async claim(cryptoCurrency: string): Promise<boolean> {
        try {
            const claimedOffer = await OfferApi.claim(cryptoCurrency);
            this._claims.push(claimedOffer as ClaimedOffer);

            this._acquiredOffers = this._acquiredOffers?.map((offer) => {
                if (offer.cryptoCurrency === claimedOffer?.cryptoCurrency) {
                    offer.cryptoCurrencyAmount = 0;
                }
                return offer;
            });
            this._notifySubscribers();
            toast.success(
                `Your claim of ${formatNumber(claimedOffer?.cryptoCurrencyAmount)} ${claimedOffer?.cryptoCurrency} has been submitted`
            );
            return true;
        } catch (error: any) {
            var errorData = error as StandardHttpResponse;
            if (errorData && errorData.hasErrorMessage) {
                toast.error(errorData.errorMessage);
            }
            return false;
        }
    }

    async purchaseOffer(
        offerId: string,
        userAgent: string,
        innerScreenHeight: number,
        innerScreenWidth: number
    ): Promise<[string?, string?, AcquiredOffer?]> {
        try {
            const acquiredOffer = await BubbleApi.purchaseOffer(
                offerId,
                userAgent,
                innerScreenHeight,
                innerScreenWidth
            );
            this._mineableOffers = [];
            this._minedOffer = acquiredOffer;

            this._notifySubscribers();
            return [undefined, undefined, acquiredOffer];
        } catch (e) {
            const response = e as StandardHttpResponse;

            if (!response) {
                throw e;
            }

            if (response.isInternetError) {
                return [
                    'noInternetConnection'.tr(),
                    'noInternetConnectionMessage'.tr(),
                    undefined,
                ];
            }

            return [response.error, response.errorMessage, undefined];
        }
    }

    async openLootBox(
        acquiredOfferId: string
    ): Promise<[string?, string?, AcquiredOffer?, Item?]> {
        try {
            const acquiredOfferOrItem =
                await BubbleApi.openLootBox(acquiredOfferId);

            const acquiredOffer = this._otherAcquiredOffers?.find(
                (offer) => offer.uniqueId === acquiredOfferId
            );

            if (acquiredOffer) {
                acquiredOffer!.isUsed = true;
            }

            this._notifySubscribers();

            return [
                undefined,
                undefined,
                acquiredOfferOrItem as AcquiredOffer,
                acquiredOfferOrItem as Item,
            ];
        } catch (e) {
            const response = e as StandardHttpResponse;

            if (!response) {
                throw e;
            }

            if (response.isInternetError) {
                return [
                    'noInternetConnection'.tr(),
                    'noInternetConnectionMessage'.tr(),
                    undefined,
                ];
            }

            return [
                response.error,
                response.errorMessage,
                undefined,
                undefined,
            ];
        }
    }
}

export default OfferComponent;
