import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './dig_window.module.css';
import ParticlesComponent from './particles';
import Images from '../../../component_system/components/preload/images';
import useFetchComponent from '../../../component_system/component_container/utilities/use_fetch_hook';
import ContainerHelper from '../../../component_system/component_container/utilities/container_helper';
import { rarity_gradient } from '../../../utils/constants';
import formatNumber from '../../../utils/currency_formatter';
import MineClick from '../../../apis/models/mine_click';
import BubbleApi from '../../../apis/bubble_api/bubble_api';
import AcquiredOffer from '../../../apis/offer_api/acquired_offer';
import VaultOfferType from '../../../apis/offer_api/vault_offer_type';
import Sounds from '../../../component_system/components/preload/sounds';

type DigWindowProps = {
    onView?: (acquiredOffer: AcquiredOffer) => void;
    onClose?: () => void;
};

// Returns a random number between min (inclusive) and max (exclusive)
const randomNumber = (min: number, max: number): number => {
    return Math.random() * (max - min) + min;
};

const DigWindow: React.FC<DigWindowProps> = ({ onView, onClose }) => {
    const offerComponent = useFetchComponent(
        ContainerHelper.getOffersComponent
    );
    const preloadComponent = useFetchComponent(
        ContainerHelper.getPreloadComponent
    );
    const minedOffer = offerComponent?.minedOffer;
    const focusedBubble = offerComponent?.focusedBubble;
    const rarity: keyof typeof Images.StoneStates =
        (focusedBubble?.metadata![
            'Rarity'
        ] as keyof typeof Images.StoneStates) || 'Common';

    const [currentState, setCurrentState] = useState<number>(0);
    const [showOre, setShowOre] = useState<boolean>(true);
    const [progress, setProgress] = useState<number>(0);
    const progressThreshold = useRef(randomNumber(1, 5));
    const progressDecayRate = 0.5;
    const digWindowRef = useRef<HTMLDivElement>(null);
    const oreHolderRef = useRef<HTMLDivElement>(null);
    const oreImageCount = 7;

    const clickDatas = useRef<Array<MineClick>>([]);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prev) => Math.max(prev - progressDecayRate, 0));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    const playTapSound = useCallback(() => {
        void preloadComponent!.getCachedSound(Sounds.dig).play();
    }, [preloadComponent]);

    const playTreasureSound = useCallback(() => {
        void preloadComponent!.getCachedSound(Sounds.treasure).play();
    }, [preloadComponent]);

    const playCratePickupSound = useCallback(() => {
        void preloadComponent!.getCachedSound(Sounds.cratePickup).play();
    }, [preloadComponent]);

    const playMultiCoinPayout5Sound = useCallback(() => {
        void preloadComponent!.getCachedSound(Sounds.multiCoinPayout5).play();
    }, [preloadComponent]);

    const handleClick = useCallback(
        (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (showOre) {
                const clickData: MineClick = new MineClick({
                    timestamp: new Date(
                        performance.timeOrigin + _event.timeStamp
                    ).toISOString(),
                    clientX: _event.clientX,
                    clientY: _event.clientY,
                    screenX: _event.screenX,
                    screenY: _event.screenY,
                    pageX: _event.pageX,
                    pageY: _event.pageY,
                });
                clickDatas.current.push(clickData);

                oreHolderRef.current?.classList.add(styles.oreHolderTapped);
                digWindowRef.current?.classList.add(styles.tapped);
                setTimeout(() => {
                    oreHolderRef.current?.classList.remove(
                        styles.oreHolderTapped
                    );
                    digWindowRef.current?.classList.remove(styles.tapped);
                }, 100);

                playTapSound();

                setProgress((prev) => prev + 1);

                if (progress + 1 >= progressThreshold.current) {
                    setProgress(0);
                    setCurrentState((prev) => {
                        if (prev >= oreImageCount - 1) {
                            // Show reward screen when stone is completely cracked
                            setShowOre(false);
                            showRewardScreen();

                            // Send click data to the server
                            BubbleApi.registerMineClicks(
                                minedOffer!.uniqueId,
                                clickDatas.current
                            );

                            return prev;
                        } else {
                            progressThreshold.current = randomNumber(1, 5);
                            return prev + 1;
                        }
                    });
                }
            }
        },
        [showOre, playTapSound, progress, minedOffer]
    );

    const showRewardScreen = () => {};

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    const handleImageLoad = useCallback(() => {
        setIsImageLoaded(true);
        playTreasureSound();
    }, [playTreasureSound]);

    const handleOnClaim = useCallback(() => {
        if (minedOffer!.vaultOfferType === VaultOfferType.LootBox) {
            playCratePickupSound();
        } else {
            playMultiCoinPayout5Sound();
        }
        onView?.(minedOffer!);
    }, [onView, minedOffer, playCratePickupSound, playMultiCoinPayout5Sound]);

    return (
        <div
            className={styles.digWindow}
            style={{
                // @ts-ignore
                background: rarity_gradient['Rare'], //rarity_gradient[rarity]  //We hardcode to the default color
            }}
            ref={digWindowRef}
            onClick={showOre && minedOffer ? handleClick : undefined}
        >
            <div className={styles.spinnerBackground}></div>
            <img
                src={Images.Backgrounds.AnimatedBackground}
                className={styles.animatedImage}
                alt=""
            />
            {!showOre && minedOffer && (
                <div id="reward" className={styles.rewardHolder}>
                    <img
                        src={minedOffer.coverUrl}
                        className={styles.rewardImg}
                        onLoad={() => handleImageLoad()}
                        onError={() => handleImageLoad()}
                        alt=""
                    />
                    <h3 className={styles.rewardText}>
                        <small className={styles.rewardTextSmall}>
                            You dug up
                        </small>
                        <br />
                        {minedOffer.cryptoCurrency
                            ? `${formatNumber(minedOffer.cryptoCurrencyAmount)} ${minedOffer.cryptoCurrency}`
                            : minedOffer.offerTitle}
                    </h3>
                    <button
                        className={styles.closeButton}
                        name="button"
                        onClick={handleOnClaim}
                        disabled={!isImageLoaded}
                        style={{
                            backgroundImage: `url(${Images.Backgrounds.ButtonOrangeBig})`,
                        }}
                    >
                        Claim
                    </button>
                </div>
            )}
            {showOre && minedOffer && (
                <>
                    <img
                        src={Images.Panels.MineBanner}
                        className={styles.banner}
                        alt=""
                    />
                    <div className={styles.oreHolder} ref={oreHolderRef}>
                        <img
                            src={
                                (
                                    Images.StoneStates[rarity] as {
                                        [key: string]: string;
                                    }
                                )['stage_' + currentState]
                            }
                            className={`${styles.ore} ${styles[`state_${currentState}`]}`}
                            style={{ display: showOre ? 'block' : 'none' }}
                            alt=""
                        />
                    </div>

                    <div className={styles.textHolder}>
                        <p className={styles.digText}>TAP TO DIG</p>
                    </div>

                    <ParticlesComponent />
                </>
            )}
        </div>
    );
};

export default DigWindow;
