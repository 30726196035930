import TopReferrer from "../../../apis/models/task/top_referrer";
import formatNumber from "../../../utils/currency_formatter";
import UserAvatar from "../user_avatar";
import styles from './leaderboard_item.module.css';

type LeaderboardItemProps = {
    placement: number;
    player: TopReferrer;
};

const LeaderboardItem = ({ placement, player }: LeaderboardItemProps) => {
    return (
        <div className={`${styles.questPanel} ${styles.vault} ${styles.leaderboard}`}>
            {placement > 0 && <div className={styles.placement}>#{placement}</div>}
            <UserAvatar
                size={60}
                showLevel={false}
                imageUri={player.avatarUrl}
            />
            <h5 className={styles.tokenTitle}>
                {player.username}
                <br />
                <small>{`${player.score} ${player.score>1 ?'Refferals':'Refferal'}`}</small>
            </h5>
            { !player.notOnLeaderboard &&
                <div className={`${styles.actions} ${styles.friends}`}>
                    <div className={styles.holder}>
                        <img src={player.coverUrl} className={styles.rewardIcon} alt=""/>
                        <p>{player.cryptoCurrencyAmount ? formatNumber(player.cryptoCurrencyAmount) : ''}</p>
                    </div>
                </div>
            }
        </div>
    );
};

export default LeaderboardItem;

