import Decimal from 'decimal.js';

export default function formatNumber(
    value: number | Decimal | undefined | null
): string {
    if (value === undefined || value === null) {
        return '0';
    }

    let decimalValue: Decimal;

    if (typeof value === 'number') {
        decimalValue = new Decimal(value);
    } else {
        decimalValue = value;
    }

    if (decimalValue.lt(1)) {
        return decimalValue.toSignificantDigits(5).toString();
    }

    const roundedValue = decimalValue.toDecimalPlaces(2);

    if (roundedValue.gte(1_000_000)) {
        const millions = roundedValue.div(1_000_000);
        return `${millions.toFixed(2).replace(/\.?0+$/, '')}M`;
    } else if (roundedValue.gte(1_000)) {
        const thousands = roundedValue.div(1_000);
        return `${thousands.toFixed(2).replace(/\.?0+$/, '')}K`;
    } else {
        return roundedValue.toFixed(2).replace(/\.?0+$/, '');
    }
}

export function formatTokenValue(
    quotePrice: Decimal,
    value: number | undefined
): string {
    if (value === undefined || value === null || value === 0) {
        return '$0.00';
    }

    let tot: Decimal;

    tot = quotePrice.mul(new Decimal(value ?? 0));

    if (tot.gte(1)) {
        // For values >= $1, show two decimal places
        return `≈ $${tot.toFixed(2)}`;
    } else if (tot.gte(0.01)) {
        // For values between $0.01 and $1, show 3 decimal places
        return `≈ $${tot.toFixed(3)}`;
    } else {
        // For values less than $0.01, show in scientific notation or more precision
        return `< $0.01`;
    }
}
