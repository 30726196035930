import { Market } from './Market';
import { ApiConfig } from './http-client';

/**
 * A client for the Market API that handles authentication
 */
export class MarketApiClient {
    private readonly _api: Market;

    /**
     * The underlying Market API instance
     */
    get api(): Market {
        return this._api;
    }

    /**
     * Creates a new MarketApiClient
     *
     * @param bearerToken The bearer token for authentication
     * @param baseURL Optional base URL for the API
     */
    constructor(bearerToken: string, baseURL?: string) {
        const config: ApiConfig = {
            baseURL: baseURL || process.env.REACT_APP_API_URL || '/',
            headers: {
                Authorization: `Bearer ${bearerToken}`,
            },
        };

        this._api = new Market(config);
    }
}
