import React, { memo, forwardRef } from 'react';
import styles from '../../pages/vault_page.module.css';
import ClaimedOffer, {
    ClaimStatus,
} from '../../../apis/offer_api/claimed_offers';
import formatNumber from '../../../utils/currency_formatter';

type ClaimedOfferItemProps = {
    offer: ClaimedOffer;
    onOpenClaimUrl?: () => void;
};

const ClaimedOfferItem = forwardRef<HTMLDivElement, ClaimedOfferItemProps>(
    ({ offer, onOpenClaimUrl }, ref) => {
        const getStatusMessage = (claim: ClaimedOffer): string => {
            switch (claim.status) {
                case ClaimStatus.Completed:
                    return 'Your request has been successfully processed and paid out!';
                case ClaimStatus.Requested:
                    return 'Your request is pending. Please allow up to 24 hours. Thank you!';
                case ClaimStatus.Processing:
                    return 'Your request is being processed. Please allow up to 24 hours. Thank you!';
                case ClaimStatus.Rejected:
                    return 'Your request was denied';
                case ClaimStatus.WaitingUserAction:
                    return 'Please visit our website to complete the claim process';
                default:
                    return 'Unknown';
            }
        };

        return (
            <div ref={ref} className={`${styles.questPanel} ${styles.vault}`}>
                <img src={offer.coverUrl} className={styles.icon} alt="" />
                <h5 className={styles.tokenTitle} style={{ fontSize: '20px' }}>
                    {`${formatNumber(offer.cryptoCurrencyAmount)} ${offer.cryptoCurrency}`}
                </h5>
                <div
                    className={styles.status}
                    style={
                        offer.status === ClaimStatus.WaitingUserAction
                            ? { height: '80px' }
                            : {}
                    }
                >
                    <div>{getStatusMessage(offer)}</div>
                    {offer.status === ClaimStatus.WaitingUserAction &&
                        onOpenClaimUrl && (
                            <div
                                className={styles.actions}
                                style={{
                                    marginTop: '8px',
                                    width: '120px',
                                }}
                            >
                                <button
                                    type="button"
                                    className={styles.claim}
                                    onClick={onOpenClaimUrl}
                                >
                                    {'Complete Claim'}
                                </button>
                            </div>
                        )}
                </div>
            </div>
        );
    }
);

export default memo(ClaimedOfferItem);
