import { constants } from "../../utils/constants";
import ValueContainer from "../../utils/value_container";
import Wallet from "../models/profile/wallet";
import StandardHttpAPI from "../standard_http_api";

interface ConnectWalletSession {
    urlCode: string;
    randomVerificationCode: string;
}

const API_URL = constants.API_URL;

class ProfileApi {
    static async createConnectWalletSession(): Promise<ConnectWalletSession> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}profiles/create-connect-wallet-session`,
            body: null,
            bearer: ValueContainer.token,
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return response.response as ConnectWalletSession;
    }

  static async updateWallet(walletType: string, walletAddress: string): Promise<Wallet> {
        const response = await StandardHttpAPI.post({
            uri: `${API_URL}profiles/wallet`,
            bearer: ValueContainer.token,
            body: {
                WalletType: walletType,
                WalletAddress: walletAddress,
            },
        });

        if (!response.isSuccess) {
            return Promise.reject(response);
        }

        return Wallet.fromJsonLowerCamelCase(response.response);
    }
}

export default ProfileApi;
