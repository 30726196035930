import React from 'react';
import styles from '../../create_listing_flow.module.css';
import CryptoCurrencyInfo from '../../../../../apis/offer_api/crypto_currency_info';
import formatNumber, {
    formatTokenValue,
} from '../../../../../utils/currency_formatter';
import Decimal from 'decimal.js';

interface Step2Props {
    cryptoAmount: number;
    setCryptoAmount: (amount: number) => void;
    selectedCrypto: CryptoCurrencyInfo | null;
    cryptoUsdValue: Decimal | null;
    transactionFee?: number;
    errors: { cryptoAmount?: string };
    getSelectedItemDetails: () => {
        title: string;
        description: string;
        imageUrl: string;
    };
}

const Step2CryptoAmount: React.FC<Step2Props> = ({
    cryptoAmount,
    setCryptoAmount,
    selectedCrypto,
    cryptoUsdValue,
    transactionFee,
    errors,
    getSelectedItemDetails,
}) => {
    return (
        <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>Set Cryptocurrency Amount</h3>

            <div className={styles.formGroup}>
                <label>Amount of {selectedCrypto?.cryptoCurrency}</label>
                <input
                    type="number"
                    min="0.000001"
                    step="0.000001"
                    max={selectedCrypto?.cryptoCurrencyAmount || 0}
                    value={cryptoAmount}
                    onChange={(e) =>
                        setCryptoAmount(parseFloat(e.target.value) || 0)
                    }
                    className={errors.cryptoAmount ? styles.inputError : ''}
                />
                {errors.cryptoAmount && (
                    <p className={styles.errorText}>{errors.cryptoAmount}</p>
                )}
                {selectedCrypto && (
                    <>
                        <p className={styles.infoText}>
                            Available: {selectedCrypto.cryptoCurrencyAmount}{' '}
                            {selectedCrypto.cryptoCurrency}
                        </p>
                        {cryptoAmount > 0 && transactionFee !== undefined && (
                            <p className={styles.infoText}>
                                Transaction Fee: {formatNumber(transactionFee)}{' '}
                                {selectedCrypto.cryptoCurrency} (will be charged
                                separately)
                            </p>
                        )}
                    </>
                )}
            </div>

            <div className={styles.selectedItemPreview}>
                <div className={styles.itemImage}>
                    <img
                        src={getSelectedItemDetails().imageUrl}
                        alt="Selected crypto"
                    />
                </div>
                <div className={styles.itemDetails}>
                    <h4>{getSelectedItemDetails().title}</h4>
                    <p>{getSelectedItemDetails().description}</p>
                    {cryptoAmount > 0 && (
                        <div className={styles.valueDisplay}>
                            <p>
                                {formatNumber(cryptoAmount)}{' '}
                                {selectedCrypto?.cryptoCurrency}
                                {cryptoUsdValue !== null && (
                                    <span>
                                        {' '}
                                        {formatTokenValue(
                                            cryptoUsdValue,
                                            cryptoAmount
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Step2CryptoAmount;
