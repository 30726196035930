import React from 'react';
import {Item, ItemType} from '../../apis/customization_api/item';
import styles from './body_part_selector.module.css';
import {Skin} from "../../apis/customization_api/skin";
import {constants} from "../../utils/constants";

const ITEM_IMAGE_URL_STRUCTURE = constants.ITEM_IMAGE_URL_STRUCTURE;

interface BodyPartSelectorProps {
    selectedPart: ItemType | null;
    onPartSelect: (part: ItemType) => void;
    selectedSkin?: string;
    items: Item[];
    skins: Skin[];
}

// Helper function to determine if a part type should have a "None" option
export const hasNoneOption = (type: ItemType): boolean => {
    return [
        ItemType.HairAcc,
        ItemType.Headgear,
        ItemType.Eyewear,
        ItemType.Mustache,
        ItemType.Mask,
        ItemType.Earring,
        ItemType.Bracelet,
        ItemType.Glove,
        ItemType.HandAcc,
        ItemType.Watch,
        ItemType.Bag,
        ItemType.Sword,
        ItemType.Shield,
        ItemType.Axe,
        ItemType.Spear,
    ].includes(type);
};

const bodyParts = [
    // Head area
    { type: ItemType.Hair, label: 'Hair' },
    { type: ItemType.HairAcc, label: 'Hair Acc', hasNone: true },
    { type: ItemType.Headgear, label: 'Headgear', hasNone: true },
    
    // Face features
    { type: ItemType.Eye, label: 'Eyes' },
    { type: ItemType.Eyebrow, label: 'Eyebrows' },
    { type: ItemType.Eyewear, label: 'Eyewear', hasNone: true },
    { type: ItemType.Lips, label: 'Lips' },
    { type: ItemType.Mustache, label: 'Mustache', hasNone: true },
    { type: ItemType.Mask, label: 'Mask', hasNone: true },
    { type: ItemType.Earring, label: 'Earrings', hasNone: true },
    
    // Body and clothing
    { type: ItemType.Top, label: 'Top' },
    { type: ItemType.Bottom, label: 'Bottom' },
    { type: ItemType.Shoes, label: 'Shoes' },
    
    // Accessories
    { type: ItemType.Bracelet, label: 'Bracelet', hasNone: true },
    { type: ItemType.Glove, label: 'Gloves', hasNone: true },
    { type: ItemType.HandAcc, label: 'Hand Acc', hasNone: true },
    { type: ItemType.Watch, label: 'Watch', hasNone: true },
    { type: ItemType.Bag, label: 'Bag', hasNone: true },
    
    // Weapons
    { type: ItemType.Sword, label: 'Sword', hasNone: true },
    { type: ItemType.Shield, label: 'Shield', hasNone: true },
    { type: ItemType.Axe, label: 'Axe', hasNone: true },
    { type: ItemType.Spear, label: 'Spear', hasNone: true },
];

const BodyPartSelector: React.FC<BodyPartSelectorProps> = React.memo(({ selectedPart, onPartSelect, selectedSkin, items, skins }) => {
    const getItemImageForPart = (partType: ItemType): string | undefined => {
        if (!selectedSkin) return undefined;
        
        const skin = skins.find(s => s.id === selectedSkin);
        if (!skin) return undefined;

        let itemId: string | undefined;
        switch (partType) {
            case ItemType.Body: itemId = skin.bodyItemId; break;
            case ItemType.Axe: itemId = skin.axeItemId; break;
            case ItemType.Bag: itemId = skin.bagItemId; break;
            case ItemType.Bottom: itemId = skin.bottomItemId; break;
            case ItemType.Bracelet: itemId = skin.braceletItemId; break;
            case ItemType.Earring: itemId = skin.earringItemId; break;
            case ItemType.Eye: itemId = skin.eyeItemId; break;
            case ItemType.Eyebrow: itemId = skin.eyebrowItemId; break;
            case ItemType.Eyewear: itemId = skin.eyewearItemId; break;
            case ItemType.Glove: itemId = skin.gloveItemId; break;
            case ItemType.Hair: itemId = skin.hairItemId; break;
            case ItemType.HairAcc: itemId = skin.hairAccItemId; break;
            case ItemType.HandAcc: itemId = skin.handAccItemId; break;
            case ItemType.Head: itemId = skin.headItemId; break;
            case ItemType.Headgear: itemId = skin.headgearItemId; break;
            case ItemType.Lips: itemId = skin.lipsItemId; break;
            case ItemType.Mask: itemId = skin.maskItemId; break;
            case ItemType.Mustache: itemId = skin.mustacheItemId; break;
            case ItemType.Shield: itemId = skin.shieldItemId; break;
            case ItemType.Shoes: itemId = skin.shoesItemId; break;
            case ItemType.Spear: itemId = skin.spearItemId; break;
            case ItemType.Sword: itemId = skin.swordItemId; break;
            case ItemType.Top: itemId = skin.topItemId; break;
            case ItemType.Watch: itemId = skin.watchItemId; break;
            default: return undefined;
        }

        if (!itemId) return undefined;

        const item = items.find(i => i.id === itemId);
        if (!item?.id) return undefined;
        
        return ITEM_IMAGE_URL_STRUCTURE.replace('%ITEM_ID%', item.id);
    };
    return (
        <div className={styles.bodyPartSelector}>
            {bodyParts.map((part) => {
                const itemImage = getItemImageForPart(part.type);
                const isNoneSelected = hasNoneOption(part.type) && !itemImage;
                
                return (
                    <div
                        key={part.type}
                        className={`${styles.partButton} ${selectedPart === part.type ? styles.selected : ''}`}
                        onClick={() => onPartSelect(part.type)}
                        title={`Select ${part.label} items`}
                    >
                        {itemImage ? (
                            <img 
                                src={itemImage} 
                                alt={part.label} 
                            />
                        ) : (
                            <div className={styles.emptyBox} />
                        )}
                        <span>{part.label}</span>
                    </div>
                );
            })}
        </div>
    );
});

export default BodyPartSelector;
