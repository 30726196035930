import { constants } from "../../../utils/constants";

const AVATAR_URL_STRUCTURE = constants.AVATAR_URL_STRUCTURE;

class TopReferrer {
    userId: string;
    username: string;
    avatarUrl: string | undefined;
    telegramId: string;
    score: number;
    tokensEarned: number;
    coverUrl: string;
    cryptoCurrency: string;
    cryptoCurrencyAmount: number | undefined;  
    notOnLeaderboard: boolean ;

    constructor({
        userId,
        username,
        telegramId,
        score,
        tokensEarned,
        coverUrl,
        cryptoCurrency,
        cryptoCurrencyAmount,
        notOnLeaderboard
       
    }: {
        userId: string;
        username: string;
        telegramId: string;
        score: number;
        tokensEarned: number;
        coverUrl: string;
        cryptoCurrency: string;
        cryptoCurrencyAmount: number | undefined;
        notOnLeaderboard: boolean;
        
    }) {
        this.userId = userId;
        this.username = username;
        this.telegramId = telegramId;
        this.score = score;
        this.tokensEarned = tokensEarned;
        this.coverUrl = coverUrl;
        this.cryptoCurrency = cryptoCurrency;
        this.cryptoCurrencyAmount = cryptoCurrencyAmount;
        this.notOnLeaderboard = notOnLeaderboard;
        this.avatarUrl = username
            ? AVATAR_URL_STRUCTURE.replace('%USERNAME%', telegramId!)
            : undefined;
    }

    static fromJsonLowerCamelCase(json: any): TopReferrer {
        return new TopReferrer({
            userId: json.userId,
            username: json.username,
            telegramId: json.telegramId,
            score: json.score,
            tokensEarned: json.tokensEarned,
            coverUrl: json.coverUrl,
            cryptoCurrency: json.cryptoCurrency,
            cryptoCurrencyAmount: json.cryptoCurrencyAmount,
            notOnLeaderboard: json.notOnLeaderboard
        });
    }

    static fromJsonUpperCase(json: any): TopReferrer {
        return new TopReferrer({
            userId: json.UserId,
            username: json.Username,
            telegramId: json.TelegramId,
            score: json.score,
            tokensEarned: json.TokensEarned,
            coverUrl: json.CoverUrl,
            cryptoCurrency: json.CryptoCurrency,
            cryptoCurrencyAmount: json.CryptoCurrencyAmount,
            notOnLeaderboard: json.NotOnLeaderboard
        });
    }
}

export default TopReferrer;