import React from 'react';
import styles from './market_listing_item.module.css';
import { MakeMarketListing } from '../../../generated/data-contracts';
import formatNumber, {
    formatTokenValue,
} from '../../../utils/currency_formatter';
import { formatTimeAgo } from '../../../utils/time_formatter';
import Countdown from 'react-countdown';
import Decimal from 'decimal.js';

interface MarketListingItemProps {
    listing: MakeMarketListing;
    isOwner: boolean;
    onPurchase: () => void;
    onRemove: () => void;
}

const MarketListingItem: React.FC<MarketListingItemProps> = ({
    listing,
    isOwner,
    onPurchase,
    onRemove,
}) => {
    const isAvailable =
        !listing.isExpired && !listing.isRemoved && !listing.isPurchased;

    const formatDate = (dateString?: string | null) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return (
            date.toLocaleDateString() +
            ' ' +
            date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        );
    };

    const getStatusText = () => {
        if (listing.isPurchased) return 'Purchased';
        if (listing.isRemoved) return 'Removed';
        if (listing.isExpired) return 'Expired';
        return 'Available';
    };

    const getStatusClass = () => {
        if (listing.isPurchased) return styles.purchased;
        if (listing.isRemoved) return styles.removed;
        if (listing.isExpired) return styles.expired;
        return styles.available;
    };

    // Renderer for the countdown component
    const countdownRenderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
        total,
    }: any) => {
        if (completed) {
            return <span>Expired</span>;
        } else {
            // Determine the countdown style based on remaining time
            let countdownClass = styles.countdownNormal;

            // Less than 12 hours but more than 1 hour = warning
            if (days === 0 && hours < 12 && hours > 1) {
                countdownClass = styles.countdownWarning;
            }
            // Less than 1 hour = critical
            else if (days === 0 && hours < 1) {
                countdownClass = styles.countdownCritical;
            }

            return (
                <span className={countdownClass}>
                    {days > 0 ? `${days}d ` : ''}
                    {hours > 0 ? `${hours}h ` : ''}
                    {minutes > 0 ? `${minutes}m ` : ''}
                    {seconds}s
                </span>
            );
        }
    };

    // Check if this is a crypto currency listing
    const isCryptoListing = listing.acquiredOffer?.vaultOfferType === 0; // VaultOfferType.Value0

    // Get title and description based on listing type
    const getTitle = () => {
        if (isCryptoListing) {
            return listing.cryptoCurrency || 'Crypto';
        }
        return (
            listing.title ||
            listing.acquiredOffer?.offerTitle ||
            'Unnamed Listing'
        );
    };

    const getDescription = () => {
        if (isCryptoListing) {
            return 'Cryptocurrency';
        }
        return (
            listing.description ||
            listing.acquiredOffer?.offerDescription ||
            'No description available.'
        );
    };

    const getCryptoAmount = () => {
        const amount = listing.cryptoCurrencyAmount;
        return amount ? `${formatNumber(amount)}` : '0';
    };

    const getUsdValue = () => {
        if (listing.quotePriceUsd && listing.cryptoCurrencyAmount) {
            return formatTokenValue(
                new Decimal(listing.quotePriceUsd),
                listing.cryptoCurrencyAmount
            );
        }
        return '';
    };

    return (
        <div
            className={`${styles.listingCard} ${listing.isFeatured ? styles.featured : ''}`}
        >
            {listing.isFeatured && (
                <div className={styles.featuredTag}>Featured</div>
            )}
            <div className={styles.listingHeader}>
                <div className={styles.listingTitle}>{getTitle()}</div>
                <div className={`${styles.listingStatus} ${getStatusClass()}`}>
                    {getStatusText()}
                </div>
            </div>

            <div className={styles.listingContent}>
                <div className={styles.listingImage}>
                    <img
                        src={
                            listing.acquiredOffer?.coverUrl ||
                            'https://via.placeholder.com/100'
                        }
                        alt={getTitle()}
                    />
                </div>

                <div className={styles.listingDetails}>
                    {isCryptoListing ? (
                        <div className={styles.tokenDetails}>
                            <div className={styles.tokenTitle}>
                                {getTitle()}
                            </div>
                            <div className={styles.valueDisplay}>
                                <p>
                                    {getCryptoAmount()}
                                    {listing.quotePriceUsd &&
                                        listing.cryptoCurrencyAmount && (
                                            <span> {getUsdValue()}</span>
                                        )}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <p className={styles.listingDescription}>
                            {getDescription()}
                        </p>
                    )}

                    <div className={styles.listingInfo}>
                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>Seller:</span>
                            <span className={styles.infoValue}>
                                {listing.username || 'Unknown'}
                            </span>
                        </div>

                        {isOwner ? (
                            <div className={styles.infoItem}>
                                <span className={styles.infoLabel}>Price:</span>
                                <span className={styles.infoValue}>
                                    {formatNumber(listing.priceStepcoins)} DIG
                                </span>
                            </div>
                        ) : null}

                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>Listed:</span>
                            <span className={styles.infoValue}>
                                {isOwner
                                    ? formatDate(listing.createdAt)
                                    : formatTimeAgo(listing.createdAt)}
                            </span>
                        </div>

                        <div className={styles.infoItem}>
                            <span className={styles.infoLabel}>Expires:</span>
                            <span className={styles.infoValue}>
                                {isOwner ? (
                                    formatDate(listing.listingExpiresAt)
                                ) : listing.listingExpiresAt ? (
                                    <Countdown
                                        date={
                                            new Date(listing.listingExpiresAt)
                                        }
                                        renderer={countdownRenderer}
                                    />
                                ) : (
                                    'N/A'
                                )}
                            </span>
                        </div>

                        {listing.isPurchased && (
                            <div className={styles.infoItem}>
                                <span className={styles.infoLabel}>
                                    Purchased:
                                </span>
                                <span className={styles.infoValue}>
                                    {formatDate(listing.purchasedAt)}
                                </span>
                            </div>
                        )}

                        {/*{isCryptoListing && (*/}
                        {/*    <div className={styles.infoItem}>*/}
                        {/*        <span className={styles.infoLabel}>Type:</span>*/}
                        {/*        <span className={styles.infoValue}>*/}
                        {/*            Cryptocurrency*/}
                        {/*        </span>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                </div>
            </div>

            <div className={styles.listingActions}>
                {isOwner ? (
                    <button
                        className={styles.removeButton}
                        onClick={onRemove}
                        disabled={!isAvailable}
                    >
                        Remove Listing
                    </button>
                ) : (
                    <button
                        className={styles.purchaseButton}
                        onClick={onPurchase}
                        disabled={!isAvailable}
                    >
                        Purchase for {formatNumber(listing.priceStepcoins)} DIG
                    </button>
                )}
            </div>
        </div>
    );
};

export default MarketListingItem;
