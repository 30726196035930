import React, { memo, forwardRef } from 'react';
import VaultListItem from './vault_list_item';
import CryptoAssetItem from './crypto_asset_item';
import ClaimedOfferItem from './claimed_offer_item';
import CryptoCurrencyInfo from '../../../apis/offer_api/crypto_currency_info';
import ClaimedOffer from '../../../apis/offer_api/claimed_offers';
import AcquiredOffer from '../../../apis/offer_api/acquired_offer';
import styles from '../../pages/vault_page.module.css';
import VaultOfferType from '../../../apis/offer_api/vault_offer_type';

type VaultListProps = {
    cryptoAssets?: CryptoCurrencyInfo[];
    claimedOffers?: ClaimedOffer[];
    otherOffers?: AcquiredOffer[];
    onClaim?: (asset: CryptoCurrencyInfo) => void;
    onOpenLootBox?: (offer: AcquiredOffer) => void;
    onOpenClaimUrl?: () => void;
    showDollarWorth: boolean;
    newAcquisition?: any;
    canClaimAsset: (asset: CryptoCurrencyInfo) => boolean;
};

const VaultList = forwardRef<HTMLDivElement[], VaultListProps>(
    (
        {
            cryptoAssets = [],
            claimedOffers = [],
            otherOffers = [],
            onClaim,
            onOpenLootBox,
            onOpenClaimUrl,
            showDollarWorth,
            newAcquisition,
            canClaimAsset,
        },
        ref
    ) => {
        // Memoize the filtered and sorted other offers
        const filteredOtherOffers = React.useMemo(
            () =>
                otherOffers
                    .filter((o) => !o.isUsed && !o.isExpired())
                    .sort(
                        (a, b) =>
                            new Date(b.acquiredAtDateUtc).getTime() -
                            new Date(a.acquiredAtDateUtc).getTime()
                    ),
            [otherOffers]
        );

        // Create refs array for all items
        const itemRefs = React.useRef<(HTMLDivElement | null)[]>([]);

        // Update refs when items change
        React.useEffect(() => {
            itemRefs.current = itemRefs.current.slice(
                0,
                cryptoAssets.length +
                    filteredOtherOffers.length +
                    claimedOffers.length
            );
        }, [cryptoAssets, filteredOtherOffers, claimedOffers]);

        // Expose refs to parent component
        React.useImperativeHandle(
            ref,
            () => itemRefs.current.filter(Boolean) as HTMLDivElement[]
        );

        return (
            <>
                {/* Crypto Assets */}
                {cryptoAssets.map((asset, index) => (
                    <CryptoAssetItem
                        key={`crypto-${index}`}
                        ref={(el) => (itemRefs.current[index] = el)}
                        asset={asset}
                        showDollarWorth={showDollarWorth}
                        onClaim={() => onClaim?.(asset)}
                        canClaim={canClaimAsset(asset)}
                        isNew={
                            newAcquisition?.cryptoCurrency ===
                            asset.cryptoCurrency
                        }
                    />
                ))}

                {/* Other Acquired Offers */}
                {filteredOtherOffers.length > 0 && (
                    <>
                        <div className={styles.divider} />
                        <p className={styles.label}>Other Rewards</p>
                        {filteredOtherOffers.map((offer, index) => (
                            <VaultListItem
                                key={`other-${index}`}
                                ref={(el) =>
                                    (itemRefs.current[
                                        cryptoAssets.length + index
                                    ] = el)
                                }
                                icon={offer.coverUrl}
                                title={offer.offerTitle}
                                canClaim={false}
                                acquiredOffer={offer}
                                isNew={
                                    newAcquisition?.uniqueId === offer.uniqueId
                                }
                                onClick={() => {
                                    if (
                                        offer.vaultOfferType ===
                                        VaultOfferType.LootBox
                                    ) {
                                        onOpenLootBox?.(offer);
                                    }
                                }}
                            />
                        ))}
                    </>
                )}

                {/* Claimed Offers */}
                {claimedOffers.length > 0 && (
                    <>
                        <div className={styles.divider} />
                        <p className={styles.label}>Claims</p>
                        {claimedOffers.map((claim, index) => (
                            <ClaimedOfferItem
                                key={`claim-${index}`}
                                ref={(el) =>
                                    (itemRefs.current[
                                        cryptoAssets.length +
                                            filteredOtherOffers.length +
                                            index
                                    ] = el)
                                }
                                offer={claim}
                                onOpenClaimUrl={onOpenClaimUrl}
                            />
                        ))}
                    </>
                )}
            </>
        );
    }
);

export default memo(VaultList);
