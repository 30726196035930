import React, { memo, useCallback } from 'react';
import WalletType from "../../../apis/models/profile/wallet_type";
import styles from '../../pages/profile_page.module.css';
import Images from '../../../component_system/components/preload/images';
import "@fortawesome/fontawesome-free/css/all.min.css";

interface WalletSectionProps {
  evmWalletAddress?: string;
  solanaWalletAddress?: string;
  tonWalletAddress?: string;
  onWalletClick: (type: WalletType, address?: string) => void;
  onConnectWalletsClick: () => void;
}

const WalletSection = memo(({
  evmWalletAddress,
  solanaWalletAddress,
  tonWalletAddress,
  onWalletClick,
  onConnectWalletsClick
}: WalletSectionProps) => {
  const renderWallet = useCallback((type: WalletType, address?: string) => {
    const getWalletImage = () => {
      switch(type) {
        case WalletType.Solana:
          return address ? Images.Profile.SolanaWalletConnect : Images.Profile.SolanaWallet;
        case WalletType.EVM:
          return address ? Images.Profile.EvmWalletConnect : Images.Profile.EvmWallet;
        case WalletType.TON:
          return address ? Images.Profile.TonWalletConnect : Images.Profile.TonWallet;
      }
    };

    const getWalletClass = () => {
      const baseClass = styles.wallet;
      const typeClass = styles[type.toLowerCase()];
      const connectedClass = address ? styles.connected : '';
      const middleClass = type === WalletType.EVM ? styles.middle : '';
      
      return [baseClass, typeClass, middleClass, connectedClass]
        .filter(Boolean)
        .join(' ');
    };

    return (
      <div
        className={getWalletClass()}
        style={{ backgroundImage: `url(${getWalletImage()})` }}
        onClick={() => onWalletClick(type, address)}
      />
    );
  }, [onWalletClick]);

  return (
    <>
      <p className={styles.label}>Wallets</p>
      <div className={styles.wallets}>
        {renderWallet(WalletType.TON, tonWalletAddress)}
      </div>
      <button 
        className={styles.connectWalletsButton}
        onClick={onConnectWalletsClick}
      >
        <i className="fas fa-wallet" style={{marginRight: '8px'}}></i>
        Manage Wallets Externally
      </button>
      <div className={styles.divider} />
    </>
  );
});

WalletSection.displayName = 'WalletSection';

export default WalletSection;
