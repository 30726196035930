import React, { useCallback, useEffect, useState } from 'react';
import BidHistoryPopup from '../components/market/bid_history_popup';
import styles from './market_page.module.css';
import Images from '../../component_system/components/preload/images';
import ContainerHelper from '../../component_system/component_container/utilities/container_helper';
import useFetchComponent from '../../component_system/component_container/utilities/use_fetch_hook';
import {
    MarketListingSearchModel,
    MakeMarketListing,
    MarketListingTypeFilter,
    MarketProfileType,
} from '../../generated/data-contracts';
import MarketComponent from '../../component_system/components/market/market_component';
import {
    showSweetAlert,
    showSweetAlertChoice,
} from '../components/popup/sweet_alert';
import MarketListingItem from '../components/market/market_listing_item';
import MarketAuctionItem from '../components/market/market_auction_item';
import MarketSearchFilters from '../components/market/market_search_filters';
import MarketListingSort from '../components/market/enums/market_listing_sort';
import MarketListingSearchIn from '../components/market/enums/market_listing_search_in';
import useObservable from '../../utils/observable/use_observable_hook';
import ValueContainer from '../../utils/value_container';
import CreateListingFlow from '../components/market/create_listing_flow/index';

const MarketPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [marketComponent, setMarketComponent] =
        useState<MarketComponent | null>(null);
    const [globalListings, setGlobalListings] = useState<MakeMarketListing[]>(
        []
    );
    const [userListings, setUserListings] = useState<MakeMarketListing[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [userHasNextPage, setUserHasNextPage] = useState<boolean>(false);
    const [showCreateFlow, setShowCreateFlow] = useState<boolean>(false);
    const [showBidHistory, setShowBidHistory] = useState<boolean>(false);
    const [selectedBidHistory, setSelectedBidHistory] =
        useState<MakeMarketListing | null>(null);

    const coins = useObservable(ValueContainer.coinsObservable);
    const offerComponent = useFetchComponent(
        ContainerHelper.getOffersComponent
    );
    const settingsComponent = useFetchComponent(
        ContainerHelper.getSettingsComponent
    );

    // Default search models
    const defaultGlobalSearchModel: MarketListingSearchModel = {
        search: '',
        searchIn: MarketListingSearchIn.Value0, // Title
        profileType: MarketProfileType.Value0, // All
        type: MarketListingTypeFilter.Value0, // All
        sort: MarketListingSort.Value0, // Newest
        page: 1,
        pageSize: 4,
    };

    const defaultUserSearchModel: MarketListingSearchModel = {
        search: '',
        searchIn: MarketListingSearchIn.Value0, // Title
        profileType: MarketProfileType.Value0, // All
        type: MarketListingTypeFilter.Value0, // All
        sort: MarketListingSort.Value0, // Newest
        page: 1,
        pageSize: 4,
        localMarketListingSearchOptions: {
            includeExpired: true,
            includePurchased: true,
            includeRemoved: false,
        },
    };

    const [globalSearchModel, setGlobalSearchModel] =
        useState<MarketListingSearchModel>(defaultGlobalSearchModel);
    const [userSearchModel, setUserSearchModel] =
        useState<MarketListingSearchModel>(defaultUserSearchModel);

    // Initialize market component
    useEffect(() => {
        const initMarketComponent = async () => {
            const component = await ContainerHelper.getMarketComponent();
            setMarketComponent(component);

            // Subscribe to market component updates
            component.registerSubscriber(() => {
                setGlobalListings([...component.globalListings]);
                setUserListings([...component.userListings]);
                setIsLoading(component.fetching);
                setHasNextPage(component.hasNextPage);
                setUserHasNextPage(component.userListingsHasNextPage);
            });

            // Initial data load
            await component.setSearchModel(0, globalSearchModel, true);
            await component.setSearchModel(1, userSearchModel, true);

            setGlobalListings([...component.globalListings]);
            setUserListings([...component.userListings]);
            setIsLoading(component.fetching);
            setHasNextPage(component.hasNextPage);
            setUserHasNextPage(component.userListingsHasNextPage);
        };

        initMarketComponent();

        // Cleanup on unmount
        return () => {
            if (marketComponent) {
                marketComponent.unregisterSubscriber(() => {});
            }
        };
    }, []);

    // Handle search filter changes
    const handleSearchChange = useCallback(
        async (tabIndex: number, searchModel: MarketListingSearchModel) => {
            if (!marketComponent) return;

            if (tabIndex === 0) {
                setGlobalSearchModel(searchModel);
            } else {
                setUserSearchModel(searchModel);
            }

            await marketComponent.setSearchModel(tabIndex, searchModel, true);
        },
        [marketComponent]
    );

    // Load more listings
    const loadMore = useCallback(async () => {
        if (!marketComponent || isLoading) return;

        if (activeTab === 0) {
            await marketComponent.loadNextPageGlobalListings();
        } else {
            await marketComponent.loadNextPageUserListings();
        }
    }, [marketComponent, isLoading, activeTab]);

    // Handle purchase or place bid
    const handlePurchase = useCallback(
        async (listing: MakeMarketListing) => {
            if (!marketComponent) return;

            try {
                // Handle auction bid
                if (listing.listingType === 1) {
                    // Get the bid amount from the input field
                    const bidInput = document.querySelector(
                        `input[type="number"][min="${listing.currentBid ? listing.currentBid + (listing.bidIncrement || 1) : listing.startingBid || 0}"]`
                    ) as HTMLInputElement;
                    if (!bidInput) {
                        console.error('Bid input not found');
                        return;
                    }

                    const bidAmount = parseFloat(bidInput.value);
                    if (isNaN(bidAmount)) {
                        showSweetAlert(
                            'error',
                            'Invalid Bid',
                            'Please enter a valid bid amount.'
                        );
                        return;
                    }

                    const minimumBid = listing.currentBid
                        ? listing.currentBid + (listing.bidIncrement || 1)
                        : listing.startingBid || 0;

                    if (bidAmount < minimumBid) {
                        showSweetAlert(
                            'error',
                            'Bid Too Low',
                            `Your bid must be at least ${minimumBid} coins.`
                        );
                        return;
                    }

                    if ((coins || 0) < bidAmount) {
                        showSweetAlert(
                            'error',
                            'Insufficient Funds',
                            "You don't have enough coins to place this bid."
                        );
                        return;
                    }

                    // Show confirmation dialog before placing bid
                    showSweetAlertChoice(
                        'info',
                        'Confirm Bid',
                        `Are you sure you want to bid ${bidAmount} coins on ${listing.title || listing.acquiredOffer?.offerTitle || 'this item'}?`,
                        'Place Bid',
                        'Cancel',
                        async () => {
                            try {
                                await marketComponent.placeBid(
                                    listing.id!,
                                    bidAmount
                                );
                                showSweetAlert(
                                    'success',
                                    'Bid Placed',
                                    'Your bid has been placed successfully!'
                                );
                            } catch (error) {
                                console.error('Bid failed:', error);
                                showSweetAlert(
                                    'error',
                                    'Bid Failed',
                                    'Failed to place your bid. Please try again later.'
                                );
                            }
                        }
                    );
                } else {
                    // Handle regular purchase
                    if ((coins || 0) < (listing.priceStepcoins || 0)) {
                        showSweetAlert(
                            'error',
                            'Insufficient Funds',
                            "You don't have enough coins to purchase this item."
                        );
                        return;
                    }

                    // Show confirmation dialog before purchase
                    showSweetAlertChoice(
                        'info',
                        'Confirm Purchase',
                        `Are you sure you want to purchase ${listing.title || listing.acquiredOffer?.offerTitle || 'this item'} for ${listing.priceStepcoins} coins?`,
                        'Purchase',
                        'Cancel',
                        async () => {
                            try {
                                await marketComponent.purchaseMarketListing(
                                    listing.id!
                                );
                                showSweetAlert(
                                    'success',
                                    'Purchase Successful',
                                    'You have successfully purchased this item!'
                                );
                            } catch (error) {
                                console.error('Purchase failed:', error);
                                showSweetAlert(
                                    'error',
                                    'Purchase Failed',
                                    'Failed to purchase this item. Please try again later.'
                                );
                            }
                        }
                    );
                }
            } catch (error) {
                console.error('Purchase/bid failed:', error);
                showSweetAlert(
                    'error',
                    'Operation Failed',
                    'Failed to complete the operation. Please try again later.'
                );
            }
        },
        [marketComponent, coins]
    );

    // Handle remove listing
    const handleRemoveListing = useCallback(
        async (listing: MakeMarketListing) => {
            if (!marketComponent) return;

            try {
                // Show confirmation dialog before removing
                showSweetAlertChoice(
                    'warning',
                    'Confirm Removal',
                    'Are you sure you want to remove this listing from the marketplace?',
                    'Remove',
                    'Cancel',
                    async () => {
                        try {
                            await marketComponent.removeMarketListing(
                                listing.id!
                            );
                            showSweetAlert(
                                'success',
                                'Listing Removed',
                                'Your listing has been removed successfully.'
                            );
                        } catch (error) {
                            console.error('Remove listing failed:', error);
                            showSweetAlert(
                                'error',
                                'Remove Failed',
                                'Failed to remove this listing. Please try again later.'
                            );
                        }
                    }
                );
            } catch (error) {
                console.error('Remove listing failed:', error);
                showSweetAlert(
                    'error',
                    'Remove Failed',
                    'Failed to remove this listing. Please try again later.'
                );
            }
        },
        [marketComponent]
    );

    const DEFAULT_TRANSACTION_FEE_RATE = 0.03;

    const getTransactionFee = useCallback(
        (cryptoAmount: number): number => {
            const feeRate =
                settingsComponent?.getDoubleFromClientSettings(
                    'MarketCryptoListingTransactionFee',
                    DEFAULT_TRANSACTION_FEE_RATE
                ) || DEFAULT_TRANSACTION_FEE_RATE;

            return cryptoAmount * feeRate;
        },
        [settingsComponent]
    );

    // Handle create listing
    const handleCreateListing = useCallback(
        async (
            acquiredOfferId: string,
            coinPrice: number,
            expiryDate: Date,
            cryptoAmount?: number,
            isFeatured?: boolean
        ) => {
            if (!marketComponent) return;

            try {
                if (acquiredOfferId.startsWith('crypto-')) {
                    // Handle crypto listing
                    const cryptoCurrency = acquiredOfferId.replace(
                        'crypto-',
                        ''
                    );
                    if (cryptoAmount) {
                        // Create a consolidated crypto offer first
                        const acquiredOffer =
                            await marketComponent.consolidateCryptoCurrency(
                                cryptoCurrency,
                                cryptoAmount
                            );

                        // Calculate fee based on the acquired crypto amount
                        const transactionFee = getTransactionFee(
                            acquiredOffer.cryptoCurrencyAmount ?? 0
                        );

                        // Create a consolidated offer for the transaction fee
                        const transactionFeeOffer =
                            await marketComponent.consolidateCryptoCurrency(
                                cryptoCurrency,
                                transactionFee,
                                [acquiredOffer.acquiredOfferId || '']
                            );

                        // Then create a market listing with that offer
                        await marketComponent.createMarketListing(
                            acquiredOffer.acquiredOfferId!,
                            coinPrice,
                            expiryDate,
                            isFeatured,
                            transactionFeeOffer.acquiredOfferId
                        );
                    }
                } else {
                    // Handle regular item listing
                    await marketComponent.createMarketListing(
                        acquiredOfferId,
                        coinPrice,
                        expiryDate,
                        isFeatured
                    );
                }

                setShowCreateFlow(false);
                showSweetAlert(
                    'success',
                    'Listing Created',
                    'Your item has been listed on the marketplace!'
                );

                // Refresh user listings
                await marketComponent.setSearchModel(1, userSearchModel, true);
            } catch (error) {
                console.error('Create listing failed:', error);
                showSweetAlert(
                    'error',
                    'Listing Failed',
                    'Failed to create your listing. Please try again later.'
                );
            }
        },
        [marketComponent, userSearchModel]
    );

    // Handle showing bid history
    const handleShowBidHistory = useCallback((listing: MakeMarketListing) => {
        setSelectedBidHistory(listing);
        setShowBidHistory(true);
    }, []);

    // Handle create auction listing
    const handleCreateAuctionListing = useCallback(
        async (
            acquiredOfferId: string,
            startingBid: number,
            bidIncrement: number,
            auctionEndDate: Date,
            reservePrice?: number,
            cryptoAmount?: number,
            isFeatured?: boolean
        ) => {
            if (!marketComponent) return;

            try {
                if (acquiredOfferId.startsWith('crypto-')) {
                    // Handle crypto auction listing
                    const cryptoCurrency = acquiredOfferId.replace(
                        'crypto-',
                        ''
                    );
                    if (cryptoAmount) {
                        // Create a consolidated crypto offer first
                        const acquiredOffer =
                            await marketComponent.consolidateCryptoCurrency(
                                cryptoCurrency,
                                cryptoAmount
                            );

                        // Calculate fee based on the acquired crypto amount
                        const transactionFee = getTransactionFee(
                            acquiredOffer.cryptoCurrencyAmount ?? 0
                        );

                        // Create a consolidated offer for the transaction fee
                        const transactionFeeOffer =
                            await marketComponent.consolidateCryptoCurrency(
                                cryptoCurrency,
                                transactionFee,
                                [acquiredOffer.acquiredOfferId || '']
                            );

                        // Then create an auction listing with that offer
                        await marketComponent.createAuctionListing(
                            acquiredOffer.acquiredOfferId!,
                            startingBid,
                            bidIncrement,
                            auctionEndDate,
                            reservePrice,
                            isFeatured,
                            transactionFeeOffer.acquiredOfferId
                        );
                    }
                } else {
                    // Handle regular item auction listing
                    await marketComponent.createAuctionListing(
                        acquiredOfferId,
                        startingBid,
                        bidIncrement,
                        auctionEndDate,
                        reservePrice,
                        isFeatured
                    );
                }

                setShowCreateFlow(false);
                showSweetAlert(
                    'success',
                    'Auction Created',
                    'Your item has been listed for auction on the marketplace!'
                );

                // Refresh user listings
                await marketComponent.setSearchModel(1, userSearchModel, true);
            } catch (error) {
                console.error('Create auction failed:', error);
                showSweetAlert(
                    'error',
                    'Auction Failed',
                    'Failed to create your auction. Please try again later.'
                );
            }
        },
        [marketComponent, userSearchModel]
    );

    return (
        <>
            <div className={styles.pageBg}>
                <img
                    src={Images.Backgrounds.AnimatedBackground}
                    className={styles.animatedImage}
                    alt=""
                />
            </div>

            <div className={styles.pageContent}>
                <div
                    className={styles.titleController}
                    onClick={() => {
                        ContainerHelper.getNavigationComponent().then(
                            (navigationComponent) => {
                                navigationComponent.navigateToIndex(1);
                            }
                        );
                    }}
                >
                    <div className={styles.backBtn}>
                        <img src={Images.Profile.BackButton} alt="" />
                    </div>
                    <h2 className={styles.pageTitle}>Market</h2>
                </div>

                <div className={styles.tabContainer}>
                    <button
                        className={`${styles.tabButton} ${activeTab === 0 ? styles.active : ''}`}
                        onClick={() => setActiveTab(0)}
                    >
                        Browse
                    </button>
                    <button
                        className={`${styles.tabButton} ${activeTab === 1 ? styles.active : ''}`}
                        onClick={() => setActiveTab(1)}
                    >
                        My Listings
                    </button>
                </div>

                {activeTab === 0 && (
                    <div className={styles.tabContent}>
                        <MarketSearchFilters
                            searchModel={globalSearchModel}
                            onSearchChange={(model) =>
                                handleSearchChange(0, model)
                            }
                            showLocalOptions={false}
                        />

                        {isLoading && globalListings.length === 0 ? (
                            <div className={styles.loadingContainer}>
                                <div className={styles.loader}></div>
                                <p>Loading marketplace listings...</p>
                            </div>
                        ) : globalListings.length === 0 ? (
                            <div className={styles.emptyState}>
                                <p>No listings found matching your criteria.</p>
                            </div>
                        ) : (
                            <>
                                <div className={styles.listingsContainer}>
                                    {globalListings.map((listing) =>
                                        listing.listingType === 1 ? (
                                            <MarketAuctionItem
                                                key={listing.id}
                                                listing={listing}
                                                isOwner={false}
                                                onPurchase={() =>
                                                    handlePurchase(listing)
                                                }
                                                onRemove={() => {}}
                                                onShowBidHistory={
                                                    handleShowBidHistory
                                                }
                                            />
                                        ) : (
                                            <MarketListingItem
                                                key={listing.id}
                                                listing={listing}
                                                isOwner={false}
                                                onPurchase={() =>
                                                    handlePurchase(listing)
                                                }
                                                onRemove={() => {}}
                                            />
                                        )
                                    )}
                                </div>

                                {hasNextPage && (
                                    <button
                                        className={styles.loadMoreButton}
                                        onClick={loadMore}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Loading...' : 'Load More'}
                                    </button>
                                )}
                                <div style={{ height: '30px' }}></div>
                            </>
                        )}
                    </div>
                )}

                {activeTab === 1 && (
                    <div className={styles.tabContent}>
                        <div className={styles.myListingsHeader}>
                            <MarketSearchFilters
                                searchModel={userSearchModel}
                                onSearchChange={(model) =>
                                    handleSearchChange(1, model)
                                }
                                showLocalOptions={true}
                            />

                            <button
                                className={styles.createListingButton}
                                onClick={() => setShowCreateFlow(true)}
                            >
                                Create Listing
                            </button>
                        </div>

                        {isLoading && userListings.length === 0 ? (
                            <div className={styles.loadingContainer}>
                                <div className={styles.loader}></div>
                                <p>Loading your listings...</p>
                            </div>
                        ) : userListings.length === 0 ? (
                            <div className={styles.emptyState}>
                                <p>You don't have any listings yet.</p>
                                <button
                                    className={styles.createListingButton}
                                    onClick={() => setShowCreateFlow(true)}
                                >
                                    Create Your First Listing
                                </button>
                            </div>
                        ) : (
                            <>
                                <div className={styles.listingsContainer}>
                                    {userListings.map((listing) =>
                                        listing.listingType === 1 ? (
                                            <MarketAuctionItem
                                                key={listing.id}
                                                listing={listing}
                                                isOwner={true}
                                                onPurchase={() => {}}
                                                onRemove={() =>
                                                    handleRemoveListing(listing)
                                                }
                                                onShowBidHistory={
                                                    handleShowBidHistory
                                                }
                                            />
                                        ) : (
                                            <MarketListingItem
                                                key={listing.id}
                                                listing={listing}
                                                isOwner={true}
                                                onPurchase={() => {}}
                                                onRemove={() =>
                                                    handleRemoveListing(listing)
                                                }
                                            />
                                        )
                                    )}
                                </div>

                                {userHasNextPage && (
                                    <button
                                        className={styles.loadMoreButton}
                                        onClick={loadMore}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Loading...' : 'Load More'}
                                    </button>
                                )}

                                <div style={{ height: '30px' }}></div>
                            </>
                        )}
                    </div>
                )}
            </div>

            {showCreateFlow && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 999,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CreateListingFlow
                        onClose={() => setShowCreateFlow(false)}
                        onCreateListing={handleCreateListing}
                        onCreateAuctionListing={handleCreateAuctionListing}
                        acquiredOffers={
                            offerComponent?.otherAcquiredOffers || []
                        }
                        cryptoCurrencies={offerComponent?.acquiredOffers || []}
                    />
                </div>
            )}

            {/* Bid History Popup */}
            <BidHistoryPopup
                isOpen={showBidHistory}
                onClose={() => setShowBidHistory(false)}
                bidHistory={selectedBidHistory?.bidHistory}
            />
        </>
    );
};

export default MarketPage;
