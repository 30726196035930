import React, { memo } from 'react';
import styles from '../../pages/profile_page.module.css';
import Images from '../../../component_system/components/preload/images';

interface StatsSectionProps {
    totalSteps: number;
    totalPurchasedOffers: number;
    totalFriendsCount: number;
}

const StatsSection = memo(({ 
    totalSteps = 0, 
    totalPurchasedOffers = 0, 
    totalFriendsCount = 0 
}: StatsSectionProps) => {
    return (
        <>
            <p className={styles.label}>Stats</p>
            <div className={styles.referralStats}>
                <div className={`${styles.referralStat} ${styles.referralStatFirst}`}>
                    <img
                        src={Images.Icons.IconRef}
                        className={styles.statIcon}
                        alt={'steps'.tr()}
                    />
                    <p>
                        <span>{'steps'.tr()}</span>
                        <br />
                        {totalSteps}
                    </p>
                </div>
                <div className={styles.referralStat}>
                    <img
                        src={Images.Icons.IconRef}
                        className={styles.statIcon}
                        alt={'diggs'.tr()}
                    />
                    <p>
                        <span>{'diggs'.tr()}</span>
                        <br />
                        {totalPurchasedOffers}
                    </p>
                </div>
                <div className={`${styles.referralStat} ${styles.referralStatFirst}`}>
                    <img
                        src={Images.Icons.IconRef}
                        className={styles.statIcon}
                        alt={'friends'.tr()}
                    />
                    <p>
                        <span>{'friends'.tr()}</span>
                        <br />
                        {totalFriendsCount}
                    </p>
                </div>
            </div>
            <div className={styles.divider}></div>
        </>
    );
});

StatsSection.displayName = 'StatsSection';

export default StatsSection;
