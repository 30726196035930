import React from 'react';
import styles from '../../create_listing_flow.module.css';
import AcquiredOffer from '../../../../../apis/offer_api/acquired_offer';
import CryptoCurrencyInfo from '../../../../../apis/offer_api/crypto_currency_info';

interface Step1Props {
    selectedOfferId: string;
    setSelectedOfferId: (id: string) => void;
    availableOffers: AcquiredOffer[];
    cryptoCurrencies: CryptoCurrencyInfo[];
    errors: { offer?: string };
    getSelectedItemDetails: () => {
        title: string;
        description: string;
        imageUrl: string;
    };
}

const Step1SelectItem: React.FC<Step1Props> = ({
    selectedOfferId,
    setSelectedOfferId,
    availableOffers,
    cryptoCurrencies,
    errors,
    getSelectedItemDetails,
}) => {
    return (
        <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>
                Select Item to List
            </h3>

            <div className={styles.formGroup}>
                <select
                    value={selectedOfferId}
                    onChange={(e) =>
                        setSelectedOfferId(e.target.value)
                    }
                    className={
                        errors.offer ? styles.inputError : ''
                    }
                >
                    <option value="">-- Select an item --</option>
                    {availableOffers.length > 0 ? (
                        availableOffers.map((offer) => (
                            <option
                                key={offer.uniqueId}
                                value={offer.uniqueId}
                            >
                                {offer.offerTitle || 'Unnamed Item'}
                            </option>
                        ))
                    ) : (
                        <option value="" disabled>
                            No items available to list
                        </option>
                    )}
                    {cryptoCurrencies.length > 0 && (
                        <>
                            <option value="" disabled>
                                --- Cryptocurrencies ---
                            </option>
                            {cryptoCurrencies.map((crypto) => (
                                <option
                                    key={`crypto-${crypto.cryptoCurrency}`}
                                    value={`crypto-${crypto.cryptoCurrency}`}
                                    disabled={
                                        (crypto.cryptoCurrencyAmount ||
                                            0) <= 0
                                    }
                                >
                                    {crypto.cryptoCurrency} (
                                    {crypto.cryptoCurrencyAmount ||
                                        0}
                                    )
                                </option>
                            ))}
                        </>
                    )}
                </select>
                {errors.offer && (
                    <p className={styles.errorText}>
                        {errors.offer}
                    </p>
                )}
            </div>

            {selectedOfferId && (
                <div className={styles.selectedItemPreview}>
                    <div className={styles.itemImage}>
                        <img
                            src={getSelectedItemDetails().imageUrl}
                            alt="Selected item"
                        />
                    </div>
                    <div className={styles.itemDetails}>
                        <h4>{getSelectedItemDetails().title}</h4>
                        <p>
                            {getSelectedItemDetails().description}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Step1SelectItem;
