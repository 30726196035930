import Color from '../../utils/color';

class CompactJsonOffer {
    offerId: string;
    cost: number;
    url: string;
    coverUrl?: string;
    name: string;
    tagString?: string;
    rarity?: string;
    isEvent: boolean = false;
    isPreviewable: boolean = false;
    isPurchasable: boolean = false;
    tags?: string[];
    preferredBackgroundColor?: Color;
    cryptoCurrencyAmount?: number;

    constructor(offer: { [key: string]: any }) {
        this.offerId = offer['id'] as string;
        this.cost = offer['cost'] as number;
        this.url = offer['logoUrl'] as string;
        this.name = offer['profileName'] as string;
        this.coverUrl = offer['coverUrl'] as string | undefined;
        this.tagString = offer['tags'] as string | undefined;

        this.tags = this.tagString?.split(',').map((tag) => tag.trim());

        if (this.tags) {
            this.rarity = this.tags
                .find((tag) => tag.startsWith('RARITY'))
                ?.split('_')[1];
            this.isEvent = this.tags.some((tag) => tag.startsWith('EVENT'));
            this.isPreviewable = this.tags.some((tag) =>
                tag.startsWith('PREVIEWABLE')
            );
            this.isPurchasable = this.tags.some((tag) =>
                tag.startsWith('PURCHASABLE')
            );

            if (this.isPurchasable) {
                this.isPreviewable = true;
            }
        }

        if (offer['preferredBackgroundColor']) {
            this.preferredBackgroundColor = Color.fromJson(
                offer['preferredBackgroundColor']
            );
        }

        this.cryptoCurrencyAmount = offer['cryptoCurrencyAmount'] as number | undefined;
    }

    static fromJsonLowerCamelCase(json: {
        [key: string]: any;
    }): CompactJsonOffer {
        return new CompactJsonOffer({
            id: json['id'],
            cost: json['cost'],
            logoUrl: json['logoUrl'],
            coverUrl: json['coverUrl'],
            profileName: json['profileName'],
            tags: json['tags'],
            preferredBackgroundColor: json['preferredBackgroundColor'],
            cryptoCurrencyAmount: json['cryptoCurrencyAmount'],
        });
    }

    static fromJson(json: { [key: string]: any }): CompactJsonOffer {
        return new CompactJsonOffer({
            id: json['Id'],
            cost: json['Cost'],
            logoUrl: json['LogoUrl'],
            coverUrl: json['CoverUrl'],
            profileName: json['ProfileName'],
            tags: json['Tags'],
            preferredBackgroundColor: json['PreferredBackgroundColor'],
            cryptoCurrencyAmount: json['CryptoCurrencyAmount'],
        });
    }
}

export default CompactJsonOffer;
