import React, { memo } from 'react';
import styles from '../../pages/vault_page.module.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

type VaultHeaderProps = {
    showDollarWorth: boolean;
    onToggleWorth: () => void;
    onNavigateToMarket: () => void;
};

const VaultHeader: React.FC<VaultHeaderProps> = ({
    showDollarWorth,
    onToggleWorth,
    onNavigateToMarket,
}) => {
    return (
        <div className={styles.titleSection}>
            <h2 className={styles.pageTitle}>Vault</h2>
            <div className={styles.headerActions}>
                <button 
                    className={styles.marketButton}
                    onClick={onNavigateToMarket}
                >
                    <i className="fas fa-store"></i> Market
                </button>
                <div className={styles.currencyToggle}>                   
                    <button
                        className={`${styles.toggleBtn} ${!showDollarWorth ? styles.active : ""}`}
                        onClick={onToggleWorth}
                        data-currency="token"
                    >
                        <i className="fas fa-coins"></i>
                    </button>
                    <button
                        className={`${styles.toggleBtn} ${showDollarWorth ? styles.active : ""}`}
                        onClick={onToggleWorth}
                        data-currency="usd"
                    >
                        <i className="fas fa-dollar-sign"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default memo(VaultHeader);
