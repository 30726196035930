import React from 'react';
import styles from '../../create_listing_flow.module.css';
import CryptoCurrencyInfo from '../../../../../apis/offer_api/crypto_currency_info';
import formatNumber, {
    formatTokenValue,
} from '../../../../../utils/currency_formatter';
import Decimal from 'decimal.js';
import useObservable from '../../../../../utils/observable/use_observable_hook';
import ValueContainer from '../../../../../utils/value_container';

interface Step4Props {
    listingType: 'fixed' | 'auction';
    price: number;
    setPrice: (price: number) => void;
    startingBid: number;
    setStartingBid: (bid: number) => void;
    bidIncrement: number;
    setBidIncrement: (increment: number) => void;
    reservePrice?: number;
    setReservePrice: (price: number | undefined) => void;
    hasReservePrice: boolean;
    setHasReservePrice: (has: boolean) => void;
    isCryptoSelected: boolean;
    cryptoAmount: number;
    selectedCrypto: CryptoCurrencyInfo | null;
    cryptoUsdValue: Decimal | null;
    errors: {
        price?: string;
        startingBid?: string;
        bidIncrement?: string;
        reservePrice?: string;
    };
    isFeatured?: boolean;
    setIsFeatured?: (featured: boolean) => void;
    getSelectedItemDetails: () => {
        title: string;
        description: string;
        imageUrl: string;
    };
}

const Step4PriceDetails: React.FC<Step4Props> = ({
    listingType,
    price,
    setPrice,
    startingBid,
    setStartingBid,
    bidIncrement,
    setBidIncrement,
    reservePrice,
    setReservePrice,
    hasReservePrice,
    setHasReservePrice,
    isCryptoSelected,
    cryptoAmount,
    selectedCrypto,
    cryptoUsdValue,
    errors,
    getSelectedItemDetails,
    isFeatured,
    setIsFeatured,
}) => {
    const userType = useObservable(ValueContainer.userTypeObservable); // number | undefined // 0 - user, 1 - admin
    const isAdmin = userType === 1;

    return (
        <div className={styles.stepContent}>
            <h3 className={styles.stepTitle}>
                {listingType === 'fixed'
                    ? 'Set Your Price'
                    : 'Set Auction Details'}
            </h3>

            {listingType === 'fixed' ? (
                <div className={styles.formGroup}>
                    <label>Price (DIG)</label>
                    <input
                        type="number"
                        min="1"
                        value={price}
                        onChange={(e) =>
                            setPrice(parseInt(e.target.value) || 0)
                        }
                        className={errors.price ? styles.inputError : ''}
                    />
                    {errors.price && (
                        <p className={styles.errorText}>{errors.price}</p>
                    )}
                </div>
            ) : (
                <>
                    <div className={styles.formGroup}>
                        <label>Starting Bid (DIG)</label>
                        <input
                            type="number"
                            min="1"
                            value={startingBid}
                            onChange={(e) =>
                                setStartingBid(parseInt(e.target.value) || 0)
                            }
                            className={
                                errors.startingBid ? styles.inputError : ''
                            }
                        />
                        {errors.startingBid && (
                            <p className={styles.errorText}>
                                {errors.startingBid}
                            </p>
                        )}
                    </div>

                    <div className={styles.formGroup}>
                        <label>Bid Increment (DIG)</label>
                        <input
                            type="number"
                            min="1"
                            value={bidIncrement}
                            onChange={(e) =>
                                setBidIncrement(parseInt(e.target.value) || 0)
                            }
                            className={
                                errors.bidIncrement ? styles.inputError : ''
                            }
                        />
                        {errors.bidIncrement && (
                            <p className={styles.errorText}>
                                {errors.bidIncrement}
                            </p>
                        )}
                        <p className={styles.infoText}>
                            Minimum amount each new bid must increase by
                        </p>
                    </div>

                    <div className={styles.formGroup}>
                        <div className={styles.checkboxContainer}>
                            <input
                                type="checkbox"
                                id="hasReservePrice"
                                checked={hasReservePrice}
                                onChange={(e) =>
                                    setHasReservePrice(e.target.checked)
                                }
                            />
                            <label htmlFor="hasReservePrice">
                                Set Reserve Price
                            </label>
                        </div>

                        {hasReservePrice && (
                            <>
                                <input
                                    type="number"
                                    min={startingBid + 1}
                                    value={reservePrice || ''}
                                    onChange={(e) =>
                                        setReservePrice(
                                            parseInt(e.target.value) ||
                                                undefined
                                        )
                                    }
                                    className={
                                        errors.reservePrice
                                            ? styles.inputError
                                            : ''
                                    }
                                    placeholder="Minimum price to accept"
                                />
                                {errors.reservePrice && (
                                    <p className={styles.errorText}>
                                        {errors.reservePrice}
                                    </p>
                                )}
                                <p className={styles.infoText}>
                                    Item won't sell unless bidding reaches this
                                    price
                                </p>
                            </>
                        )}
                    </div>
                </>
            )}

            {isAdmin && setIsFeatured && (
                <div className={styles.formGroup}>
                    <div className={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            id="isFeatured"
                            checked={isFeatured || false}
                            onChange={(e) => setIsFeatured(e.target.checked)}
                        />
                        <label htmlFor="isFeatured">
                            Featured Listing
                        </label>
                    </div>
                    <p className={styles.infoText}>
                        Featured listings appear at the top of search results
                    </p>
                </div>
            )}

            <div className={styles.selectedItemPreview}>
                <div className={styles.itemImage}>
                    <img
                        src={getSelectedItemDetails().imageUrl}
                        alt="Selected item"
                    />
                </div>
                <div className={styles.itemDetails}>
                    <h4>{getSelectedItemDetails().title}</h4>
                    {isCryptoSelected && (
                        <div className={styles.valueDisplay}>
                            <p>
                                {formatNumber(cryptoAmount)}{' '}
                                {selectedCrypto?.cryptoCurrency}
                                {cryptoUsdValue !== null && (
                                    <span>
                                        {' '}
                                        {formatTokenValue(
                                            cryptoUsdValue,
                                            cryptoAmount
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>
                    )}
                    {listingType === 'fixed' && price > 0 && (
                        <p className={styles.pricePreview}>
                            Price: {formatNumber(price)} DIG
                        </p>
                    )}
                    {listingType === 'auction' && startingBid > 0 && (
                        <p className={styles.pricePreview}>
                            Starting Bid: {formatNumber(startingBid)} DIG
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Step4PriceDetails;
